/* eslint-disable no-param-reassign */
import * as types from './types';
import api from '~/services/api';
import { clearFileUploadError } from '../errors/actions';
import {
  exportData, getTemplate, handleErrors, importData,
} from '../common';
import { convertToUrlParameters, removeEmptyValuesFromObj } from '~/utils';
import { newMomentDate, formatDateToServerFormat, formatTime } from '~/utils/moment';
import { SET_UPLOAD_SUCCESS } from '../errors/types';
import socketBuilder from '~/services/socketioService';
import i18n from '~/i18n';
import { getTenant } from '~/services/multiTenant';

const getEndpoints = {
  equipmentstatus: {
    url: '/dispatch/equipmentstatus',
    responseObject: 'equipment_status',
    typeAction: types.SET_EQUIPMENT_STATUS_LIST,
  },
  preventivetypes: {
    url: '/dispatch/preventivetype',
    responseObject: 'preventive_maintenance_type',
    typeAction: types.SET_PREVENTIVE_MAINTENANCE_TYPES_LIST,
  },
  equipmentsschedule: {
    url: '/dispatch/equipmentschedule',
    responseObject: 'equipment_schedule',
    typeAction: types.SET_EQUIPMENTS_SCHEDULES_LIST,
  },
  equipmentsschedulevalidationlist: {
    url: '/dispatch/equipmentschedule',
    responseObject: 'equipment_schedule',
    typeAction: types.SET_EQUIPMENTS_SCHEDULES_VALIDATION_LIST,
  },
  equipmentsschedulestatus: {
    url: '/dispatch/equipmentschedulestatus',
    responseObject: 'equipment_schedule_status',
    typeAction: types.SET_EQUIPMENT_SCHEDULE_STATUSES_LIST,
  },
  anomalylog: {
    url: '/dispatch/anomalylog',
    responseObject: 'anomaly_log',
    typeAction: types.SET_ANOMALY_LOGS_LIST,
  },
  anomalylogcodes: {
    url: '/dispatch/anomalylog_codes',
    responseObject: 'anomaly_log_codes',
    typeAction: types.SET_ANOMALY_LOGS_CODES_LIST,
  },
  anomalylogcloud: {
    url: '/dispatch/anomalylog_cloud',
    responseObject: 'anomaly_log_cloud',
    typeAction: types.SET_ANOMALY_LOGS_CLOUD_LIST,
  },
  anomalytype: {
    url: '/dispatch/anomalytype',
    responseObject: 'anomaly_type',
    typeAction: types.SET_ANOMALY_TYPES_LIST,
  },
  loadmaterialmappings: {
    url: '/dispatch/loadmaterialmappings',
    responseObject: 'load_material_mappings',
    typeAction: types.SET_LOAD_MATERIAL_MAPPINGS_LIST,
  },
  loadallocations: {
    url: '/dispatch/loadallocations',
    responseObject: 'load_allocations',
    typeAction: types.SET_LOAD_ALLOCATIONS_LIST,
  },
  truckallocations: {
    url: '/dispatch/truckallocations',
    responseObject: 'truck_allocations',
    typeAction: types.SET_TRUCK_ALLOCATIONS_LIST,
  },
  dispatchOriginsTrucks: {
    url: '/dispatch/truckorigins',
    responseObject: 'truck_origins',
    typeAction: types.SET_DISPATCH_ORIGINS_TRUCKS,
  },
  dispatchOriginsExcavators: {
    url: '/dispatch/excavatororigins',
    responseObject: 'excavator_origins',
    typeAction: types.SET_DISPATCH_ORIGINS_EXCAVATORS,
  },
  dispatchDestinationTrucks: {
    url: '/dispatch/truckdestinations',
    responseObject: 'truck_destinations',
    typeAction: types.SET_DISPATCH_DESTINATION_TRUCKS,
  },
  dispatchDestinationElements: {
    url: '/dispatch/elementdestinations',
    responseObject: 'element_destinations',
    typeAction: types.SET_DISPATCH_DESTINATION_ELEMENTS,
  },
  autoAllocationLogs: {
    url: '/dispatch/autoalloclogs',
    responseObject: 'auto_allocation_logs',
    typeAction: types.SET_AUTO_ALLOCATION_LOGS,
  },
  operational_control_report: {
    url: 'dispatch/operational_control_report',
    responseObject: 'dispatch_operational_control_report',
    typeAction: types.SET_DISPATCH_OPERATION_CONTROL_LIST,
  },
  syncStatus: {
    url: '/syncstatus',
    responseObject: 'sync_status',
    typeAction: types.SET_SYNC_STATUS_LIST,
  },
  truck_indicators: {
    url: 'dispatch/indicators/trucks',
    responseObject: 'equipment_indicators',
    typeAction: types.SET_DISPATCH_TRUCK_INDICATORS_LIST,
  },
  loader_indicators: {
    url: 'dispatch/indicators/loaders',
    responseObject: 'equipment_indicators',
    typeAction: types.SET_DISPATCH_LOADER_INDICATORS_LIST,
  },
  alerts: {
    url: 'dispatch/alert_emitter',
    responseObject: 'alerts',
    typeAction: types.SET_ALERTS_LIST,
    tableName: 'alerts',
  },
  equipment_schedule_subelements: {
    url: 'dispatch/equipmentschedule/subelements',
    responseObject: 'subelements',
    typeAction: types.SET_EQUIPMENT_SCHEDULE_SUBELEMENTS_LIST,
  },
  perforator_equipment_schedule: {
    url: 'dispatch/equipmentschedule/equipments',
    responseObject: 'equipment_schedule',
    typeAction: types.SET_PERFORATOR_EQUIPMENT_SCHEDULE_LIST,
  },
};

const CODE_TYPE_MAINTENANCE = 0;
const CODE_GROUP_PREVENTIVE = 5;

export const getAll = ({
  domain,
  clearUploadError = true,
  all = false,
  filters = {},
  then = () => {},
  dispatchError = true,
}) => async (dispatch) => {
  try {
    if (clearUploadError) {
      dispatch(clearFileUploadError());
    }
    const endpoint = getEndpoints[domain];
    if (all) filters.all = true;
    const queryString = convertToUrlParameters(filters);
    const endpointUrl = `${endpoint.url}?${queryString}`;

    const {
      data: {
        result: { [endpoint.responseObject]: data = [], ...rest },
      },
    } = await api.get(endpointUrl);

    dispatch({
      type: endpoint.typeAction,
      ...rest,
      payload: data,
    });

    then(data);
  } catch ({ response }) {
    if (dispatchError) {
      dispatch(handleErrors(response));
    } else {
      // eslint-disable-next-line no-console
      console.log(response);
    }
  }
};

export const updateData = (
  domain,
  data,
  refreshData = true,
  all = false,
  filters = {},
) => async (dispatch) => {
  try {
    const items = data;
    const endpoint = getEndpoints[domain];
    const { status } = await api.post(`${endpoint.url}`, items);
    if (status === 200 && refreshData) {
      dispatch(getAll({ domain, all, filters }));
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    if (err.response) {
      dispatch(handleErrors(err.response));
    }
  }
};

const setValues = (item, type) => (dispatch) => {
  const updateStatus = item.update_status === 'I' ? 'I' : 'U';
  dispatch({
    type,
    payload: {
      ...item,
      update_status: updateStatus,
    },
  });
};

export const getEquipmentStatuses = () => async (dispatch) => {
  dispatch(getAll({ domain: 'equipmentstatus' }));
};

export const updateEquipmentStatus = (equipmentStatus, domain = 'equipmentstatus') => async (dispatch) => {
  try {
    const endpoint = getEndpoints[domain];
    const currentDate = newMomentDate();
    const nextSecondDate = newMomentDate().add(1, 'second');
    delete equipmentStatus.code_type_name;
    const newEquipStatus = equipmentStatus.length ? equipmentStatus : [equipmentStatus];
    const body = {
      equipment_status:
        newEquipStatus.map(i => ({
          ...i,
          duration: '00:00:01',
          start_timestamp: currentDate.valueOf(),
          end_timestamp: nextSecondDate.valueOf(),
          start_date: formatDateToServerFormat(currentDate),
          start_time: formatTime(currentDate),
          end_date: formatDateToServerFormat(nextSecondDate),
          end_time: formatTime(nextSecondDate),
          update_status: 'I',
        })),
    };
    const { status } = await api.post(`${endpoint.url}`, body);

    if (status === 200) {
      dispatch(getEquipmentStatuses());
      if (equipmentStatus.equip_type === 2) dispatch(getAll({ domain: 'dispatchOriginsTrucks' }));
      if (equipmentStatus.equip_type === 1) dispatch(getAll({ domain: 'dispatchOriginsExcavators' }));
      dispatch({
        type: types.SET_EQUIPMENT_STATUS_SAVED,
        payload: i18n.t('validation:SavedSuccess'),
      });
      dispatch({ type: SET_UPLOAD_SUCCESS, payload: i18n.t('validation:UpdateSentSuccessfully') });
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const setCurrentEquipmentStatus = (item, key = null, value = null) => (dispatch) => {
  const newItem = { ...item };

  if (key) {
    newItem[key] = value;
    if (key === 'code_type') {
      newItem.id_code_group = 0;
      newItem.id_code = 0;
    }
    if (key === 'id_code_group') {
      newItem.id_code = 0;
    }
    if (key === 'element_id') {
      newItem.point_id = 0;
    }
    if (key === 'id_operator_group') {
      newItem.id_operator = 0;
    }
  }

  dispatch({
    type: types.SET_EQUIPMENT_STATUS_ITEM,
    payload: newItem,
  });
};

export const setOriginalCurrentEquipmentStatus = item => (dispatch) => {
  dispatch({
    type: types.SET_ORIGINAL_EQUIPMENT_STATUS_ITEM,
    payload: item,
  });
};

export const setCurrentEquipmentStatusFilter = (key = null, value = null) => (dispatch) => {
  dispatch({
    type: types.SET_EQUIPMENT_STATUS_FILTER,
    payload: { key, value },
  });
};

export const restoreEquipmentStatusFilter = () => (dispatch) => {
  dispatch({ type: types.RESTORE_EQUIPMENT_STATUS_FILTER });
};

// Equipment Schedules
export const getPreventiveTypes = () => async dispatch => dispatch(getAll({ domain: 'preventivetypes' }));

export const getEquipmentsSchedules = (filter = {}, validationList = false) => async (dispatch) => {
  const newStartDate = filter.startDate
    ? formatDateToServerFormat(filter.startDate)
    : formatDateToServerFormat(newMomentDate());
  const newEndDate = filter.endDate
    ? formatDateToServerFormat(filter.endDate)
    : formatDateToServerFormat(newMomentDate());

  const serverFormattedFilter = {
    start_date: newStartDate,
    end_date: newEndDate,
    equip: filter.equip || 0,
    equip_group: filter.equipGroup || 0,
    equip_type: filter.equipType || 0,
    element: filter.element || 0,
    sub_element: filter.subElement || 0,
    status_id: filter.status_id || 0,
  };

  dispatch(getAll({
    domain: validationList
      ? 'equipmentsschedulevalidationlist' : 'equipmentsschedule',
    filters: removeEmptyValuesFromObj(serverFormattedFilter),
  }));
};

export const getPerforatorEquipmentScheduleList = (filters = {}) => async (dispatch) => {
  dispatch(getAll({
    domain: 'perforator_equipment_schedule',
    filters,
  }));
};
export const getEquipmentsSchedulesTemplate = () => getTemplate('dispatch/equipmentschedule');
export const importEquipmentsSchedules = (file, filters) => importData(
  'dispatch/equipmentschedule',
  file,
  () => getEquipmentsSchedules(filters),
);
export const exportEquipmentsSchedules = () => (dispatch, getState) => {
  const { dispatch: { currentEquipmentSchedulesFilter } } = getState();
  dispatch(exportData('dispatch/equipmentschedule', currentEquipmentSchedulesFilter));
};

export const getEquipmentScheduleStatuses = () => async (dispatch) => {
  dispatch(getAll({
    domain: 'equipmentsschedulestatus',
  }));
};

export const getAlerts = (filters = {}) => async (dispatch) => {
  dispatch(getAll({
    domain: 'alerts',
    filters,
  }));
};

export const setAlerts = (alerts = []) => (dispatch) => {
  dispatch({
    type: types.SET_ALERTS_LIST,
    payload: alerts,
  });
};

export const updateAlerts = data => async dispatch => dispatch(updateData('alerts', data, true));

export const getAnomalyLogsCloud = (filter = {}) => async (dispatch) => {
  const newDate = filter.date
    ? formatDateToServerFormat(filter.date)
    : formatDateToServerFormat(newMomentDate());

  const serverFormattedFilter = {
    date: newDate,
  };

  dispatch(getAll({
    domain: 'anomalylogcloud',
    filters: removeEmptyValuesFromObj(serverFormattedFilter),
  }));
};

export const getAnomalyLogsCodes = (filter = {}) => async (dispatch) => {
  const newDate = filter.date
    ? formatDateToServerFormat(filter.date)
    : formatDateToServerFormat(newMomentDate());

  const serverFormattedFilter = {
    date: newDate,
  };

  dispatch(getAll({
    domain: 'anomalylogcodes',
    filters: removeEmptyValuesFromObj(serverFormattedFilter),
  }));
};

export const getAnomalyLogs = (filter = {}) => async (dispatch) => {
  const newDate = filter.date
    ? formatDateToServerFormat(filter.date)
    : formatDateToServerFormat(newMomentDate());

  const serverFormattedFilter = {
    date: newDate,
    equip: filter.equip || 0,
    equips: filter.equips || [],
    equip_group: filter.equipGroup || 0,
    equip_type: filter.equipType || 0,
    anomaly_type: filter.anomalyType || 0,
    status: filter.status || false,
    groupCloudPoints: true,
  };

  dispatch(getAll({
    domain: 'anomalylog',
    filters: removeEmptyValuesFromObj(serverFormattedFilter),
  }));
};

export const removeAnomalyLog = item => (dispatch) => {
  const newItem = {
    ...item,
    status: false,
  };
  dispatch(setValues(newItem, types.SET_ANOMALY_LOGS_VALUE));
};

export const removeAllAnomalyLogsFromList = (anomalyLogsList,
  exception_type = 15) => (dispatch) => {
  const newAnomalyLogsList = anomalyLogsList.map((item) => {
    const newItem = {
      ...item,
      status: false,
      exception_type,
      update_status: 'U',
    };
    return newItem;
  });
  dispatch({
    type: types.SET_ANOMALY_LOGS_LIST,
    payload: newAnomalyLogsList,
  });
};


export const updateAnomalyLogs = (data, filters = {}) => async (dispatch) => {
  try {
    const items = data
      .filter(d => d.update_status);

    const endpoint = getEndpoints.anomalylog;

    const body = {
      [endpoint.responseObject]: items,
    };

    const { status } = await api.post(`${endpoint.url}`, body);
    if (status === 200) {
      dispatch(getAnomalyLogs(filters));
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const getEquipmentScheduleSubelements = filters => getAll({ domain: 'equipment_schedule_subelements', filters });

export const getAnomalyTypes = () => async (dispatch) => {
  dispatch(getAll({
    domain: 'anomalytype',
  }));
};

export const updateEquipmentsSchedules = (
  data, filters = {}, perforator = false,
) => async (dispatch) => {
  try {
    const items = data
      .filter(d => d.update_status)
      .map((d) => {
        const {
          isDirty, id, ...rest
        } = d;

        rest.start_date = formatDateToServerFormat(rest.start_date);
        if (rest.update_status !== 'I') return { id, ...rest };
        return rest;
      });
    if (items.length === 0) return;
    const endpoint = getEndpoints.equipmentsschedule;

    const body = {
      [endpoint.responseObject]: items,
    };
    const { status } = await api.post(`${endpoint.url}`, body);
    if (status === 200) {
      if (perforator) {
        dispatch(getPerforatorEquipmentScheduleList({ equip_type: 4 }));
        dispatch(getEquipmentScheduleSubelements({ equip_type: 4 }));
      } else {
        dispatch(getEquipmentsSchedules(filters));
      }
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
    dispatch(getEquipmentsSchedules(filters));
  }
};

export const setEquipmentsScheduleValue = (item, key, value) => (dispatch) => {
  let newCodeGroupId = item.code_groups_id;
  let newCodeId = item.codes_id;
  let newPreventiveType = item.preventive_type;

  if (key === 'code_types_id') {
    newCodeGroupId = 0;
    newCodeId = 0;
    newPreventiveType = value !== CODE_TYPE_MAINTENANCE
      ? 0 : newPreventiveType;
  } else if (key === 'code_groups_id') {
    newCodeId = 0;
    newPreventiveType = value !== CODE_GROUP_PREVENTIVE
      ? 0 : newPreventiveType;
  }

  const newItem = {
    ...item,
    code_groups_id: newCodeGroupId,
    codes_id: newCodeId,
    preventive_type: newPreventiveType,
    [key]: value,
  };
  dispatch(setValues(newItem, types.SET_EQUIPMENTS_SCHEDULE_VALUE));
};

export const resetEquipmentsSchedules = () => (dispatch) => {
  dispatch({ type: types.RESET_EQUIPMENTS_SCHEDULES });
};

export const addEquipmentsSchedule = data => dispatch => dispatch({
  type: types.ADD_EQUIPMENTS_SCHEDULE,
  payload: data,
});

export const getDispatchOperationalControl = filters => (
  getAll({ domain: 'operational_control_report', filters })
);


export const getTemplateDispatchOperationalControl = () => (
  getTemplate('dispatch/operational_control_report')
);

export const importDispatchOperationalControl = (file, filters) => importData(
  'dispatch/operational_control_report',
  file,
  () => getDispatchOperationalControl({
    ...filters,
    start_date: formatDateToServerFormat(filters.start_date),
    end_date: formatDateToServerFormat(filters.end_date),
  }),
);

export const addDispatchOperationalControl = data => (dispatch, getState) => dispatch({
  type: types.ADD_DISPATCH_OPERATIONAL_CONTROL,
  payload: {
    ...data,
    id: -(getState().dispatch.operationalControl.length + 1),
    update_status: 'I',
  },
});

export const restoreOperationalControl = () => ({
  type: types.RESTORE_DISPATCH_OPERATIONAL_CONTROL,
});

export const updateDispatchOperationalControl = () => async (dispatch, getState) => {
  try {
    const { operationalControl, currentOperationalFilter } = getState().dispatch;
    const changes = operationalControl.filter(opContr => opContr.update_status);
    const endpoint = getEndpoints.operational_control_report;
    const body = { [endpoint.responseObject]: changes };
    const { status } = await api.post(endpoint.url, body);

    if (status === 200) {
      const filters = {
        ...currentOperationalFilter,
        start_date: formatDateToServerFormat(currentOperationalFilter.start_date),
        end_date: formatDateToServerFormat(currentOperationalFilter.end_date),
      };
      dispatch(getDispatchOperationalControl(filters));
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const setOperationalControlValues = operationalControlItem => ({
  type: types.SET_OPERATIONAL_CONTROL_VALUES,
  payload: {
    ...operationalControlItem,
    update_status: operationalControlItem.update_status || 'U',
    exception_type: operationalControlItem.update_status === 'I' ? 13 : 15,
  },
});

export const deleteOperationalControlValues = operationalControlItem => ({
  type: types.SET_OPERATIONAL_CONTROL_VALUES,
  payload: {
    ...operationalControlItem,
    update_status: 'D',
    exception_type: 16,
  },
});

export const setCurrentEquipmentSchedulesFilter = filter => (dispatch) => {
  dispatch({
    type: types.SET_EQUIPMENT_SCHEDULES_FILTER,
    payload: filter,
  });

  dispatch(getEquipmentsSchedules(filter));
};

export const restoreEquipmentSchedulesFilter = () => (dispatch) => {
  dispatch({ type: types.RESTORE_EQUIPMENT_SCHEDULES_FILTER });
  dispatch(getEquipmentsSchedules());
};

export const setCurrentAnomalyLogsFilter = filter => (dispatch) => {
  dispatch({
    type: types.SET_ANOMALY_LOGS_FILTER,
    payload: filter,
  });
};

export const setCurrentOperationalControlFilter = filters => ({
  type: types.SET_CURRENT_OPERATIONAL_CONTROL_FILTER,
  payload: filters,
});

export const clearCurrentOperationalControlFilter = () => ({
  type: types.CLEAR_CURRENT_OPERATIONAL_CONTROL_FILTER,
});

export const restoreAnomalyLogsFilter = () => (dispatch) => {
  dispatch({ type: types.RESTORE_ANOMALY_LOGS_FILTER });
};

export const getLoadMaterialMappings = (filters = {}, then = () => {}) => (dispatch) => {
  const serverFormattedFilters = {
    equip_id: filters.equipmentId || 0,
    material_id: filters.materialId || 0,
  };
  dispatch(getAll({
    domain: 'loadmaterialmappings',
    filters: removeEmptyValuesFromObj(serverFormattedFilters),
    then,
  }));
};

export const clearLoadMaterialMappings = () => (dispatch) => {
  dispatch({ type: types.CLEAR_LOAD_MATERIAL_MAPPINGS_LIST });
};

export const setLoadMaterialMappingsValue = (item, key, value) => (dispatch) => {
  const newItem = {
    ...item,
    [key]: value,
  };
  dispatch(setValues(newItem, types.SET_LOAD_MATERIAL_MAPPINGS_VALUE));
};

export const resetLoadMaterialMappings = () => (dispatch) => {
  dispatch({ type: types.RESET_LOAD_MATERIAL_MAPPINGS });
};

export const updateLoadMaterialMappings = (data, filters = {}) => async (dispatch) => {
  try {
    const items = data
      .filter(d => d.update_status);

    const endpoint = getEndpoints.loadmaterialmappings;

    const body = {
      [endpoint.responseObject]: items,
    };

    const { status } = await api.post(`${endpoint.url}`, body);
    if (status === 200) {
      dispatch(getLoadMaterialMappings(filters));
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const setLoadMaterialMappingsItem = item => (dispatch) => {
  dispatch({ type: types.SET_LOAD_MATERIAL_MAPPINGS_ITEM, payload: item });
};

export const getLoadAllocations = () => (dispatch) => {
  dispatch(getAll({
    domain: 'loadallocations',
  }));
};

export const getTruckAllocations = () => (dispatch) => {
  dispatch(getAll({
    domain: 'truckallocations',
  }));
};

export const getDispatchOriginsTrucks = () => (dispatch) => {
  dispatch(getAll({ domain: 'dispatchOriginsTrucks' }));
};

export const getSyncStatus = (filters, then) => (dispatch) => {
  dispatch(getAll({ domain: 'syncStatus', filters, then }));
};

export const getDispatchOriginsExcavators = () => (dispatch) => {
  dispatch(getAll({ domain: 'dispatchOriginsExcavators' }));
};

export const getDispatchDestinationsTrucks = () => (dispatch) => {
  dispatch(getAll({ domain: 'dispatchDestinationTrucks' }));
};

export const getDispatchDestinationsElements = () => (dispatch) => {
  dispatch(getAll({ domain: 'dispatchDestinationElements' }));
};

export const updateLoadAllocations = (item, materialMappings = []) => async (dispatch) => {
  try {
    const endpoint = getEndpoints.loadallocations;

    const body = {
      [endpoint.responseObject]: [{
        ...item,
        load_material_mappings: materialMappings,
      }],
    };

    const { status } = await api.post(`${endpoint.url}`, body);

    if (status === 200) {
      dispatch(getLoadAllocations());
      dispatch(getTruckAllocations());
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const setPendingTruckAllocationUpdate = item => async (dispatch) => {
  dispatch({
    type: types.SET_PENDING_TRUCK_ALLOCATION_UPDATE,
    payload: item,
  });
};

export const updateTruckAllocations = (
  item, key, value, refreshOrigins = false, refreshDestinations = false,
) => async (dispatch) => {
  try {
    const endpoint = getEndpoints.truckallocations;

    const body = {
      [endpoint.responseObject]: [{
        ...item,
        [key]: value || null,
      }],
    };

    const { status } = await api.post(`${endpoint.url}`, body);

    if (status === 200) {
      dispatch(getTruckAllocations());
      if (refreshOrigins) {
        dispatch(getDispatchOriginsTrucks());
      }
      if (refreshDestinations) {
        dispatch(getDispatchDestinationsTrucks());
      }
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const deallocateAllLoadAllocations = () => async (dispatch) => {
  try {
    const body = {
      all: true,
      allocation_ids: [],
    };
    const { status } = await api.post('/dispatch/deallocateloads', body);
    if (status === 200) {
      dispatch(getLoadAllocations());
      dispatch(getTruckAllocations());
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const deallocateLoadAllocations = id => async (dispatch) => {
  try {
    const body = {
      all: false,
      allocation_ids: [id],
    };
    const { status } = await api.post('/dispatch/deallocateloads', body);
    if (status === 200) {
      dispatch(getLoadAllocations());
      dispatch(getTruckAllocations());
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const deallocateTruckAllocations = id => async (dispatch) => {
  try {
    const body = {
      all: false,
      allocation_ids: [id],
    };
    const { status } = await api.post('/dispatch/deallocatetrucks', body);
    if (status === 200) {
      dispatch(getTruckAllocations());
      dispatch(getDispatchOriginsTrucks());
      dispatch(getDispatchDestinationsTrucks());
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const setLoadAllocationPriority = (item, list, priority = 1) => async (dispatch) => {
  try {
    const body = {
      allocation_id: item.id,
      priority,
    };

    const { status } = await api.post('/dispatch/updatepriority', body);

    if (status === 200) {
      dispatch(getLoadAllocations());
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const setSelectedTruckAllocation = truckAllocation => (dispatch) => {
  dispatch({
    type: types.SET_SELECTED_TRUCK_ALLOCATION,
    payload: truckAllocation,
  });
};

export const setSelectedPerforatorAllocation = perforatorAllocation => (dispatch) => {
  dispatch({
    type: types.SET_SELECTED_PERFORATOR_ALLOCATION,
    payload: perforatorAllocation,
  });
};

export const setSelectedLoadAllocation = loadAllocation => (dispatch) => {
  dispatch({
    type: types.SET_SELECTED_LOAD_ALLOCATION,
    payload: loadAllocation,
  });
};

export const setTruckAllocation = (truckAllocation, allocateTo) => (dispatch) => {
  dispatch({
    type: types.SET_TRUCK_ALLOCATION,
    payload: { truckAllocation, allocateTo },
  });
};

export const setSelectedLoadAllocationValues = newItem => (dispatch) => {
  dispatch({
    type: types.SET_SELECTED_LOAD_ALLOCATION_VALUES,
    payload: newItem,
  });
};

export const setSelectedTruckOrigin = truckOrigin => (dispatch) => {
  dispatch({
    type: types.SET_SELECTED_TRUCK_ORIGIN,
    payload: truckOrigin,
  });
};

export const setSelectedLoadOrigin = loadOrigin => (dispatch) => {
  dispatch({
    type: types.SET_SELECTED_LOAD_ORIGIN,
    payload: loadOrigin,
  });
};

export const setSelectedTruckDestination = truckDestination => (dispatch) => {
  dispatch({
    type: types.SET_SELECTED_TRUCK_DESTINATION,
    payload: truckDestination,
  });
};

export const setSelectedElementDestination = elementDestination => (dispatch) => {
  dispatch({
    type: types.SET_SELECTED_ELEMENT_DESTINATION,
    payload: elementDestination,
  });
};

export const getAutoAllocationLogs = (startDate, endDate) => async (dispatch) => {
  const serverFormattedFilter = {
    start_date: formatDateToServerFormat(startDate),
    end_date: formatDateToServerFormat(endDate),
  };

  dispatch(getAll({
    domain: 'autoAllocationLogs',
    filters: removeEmptyValuesFromObj(serverFormattedFilter),
  }));
};


export const getTruckIndicators = () => getAll({ domain: 'truck_indicators', dispatchError: false });
export const getLoaderIndicators = () => getAll({ domain: 'loader_indicators', dispatchError: false });

export const setDispatchSocketConnection = token => (dispatch) => {
  if (token) {
    const tenant = getTenant();
    const socket = socketBuilder(token, `/${tenant}/dispatch`);

    if (!socket) return;

    socket.on('connect', () => {
    });
    socket.on('disconnect', () => {
    });
    socket.on('truck_indicators', () => {
      dispatch(getTruckIndicators());
    });
    socket.on('loader_indicators', () => {
      dispatch(getLoaderIndicators());
    });

    dispatch({
      type: types.SET_DISPATCH_SOCKET_CONNECTION,
      payload: socket,
    });
  }
};

export const closeDispatchSocketConnection = () => (dispatch, getState) => {
  const { socket } = getState().dispatch;

  if (socket) {
    socket.close();
    dispatch({
      type: types.SET_DISPATCH_SOCKET_CONNECTION,
      payload: null,
    });
  }
};
