/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import styles from './styles';
import IconLabel from '../IconLabel';
import { WarningTwoTone } from '@material-ui/icons';

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      <IconLabel
          text={props.children}
          icon={(
            <WarningTwoTone fontSize="small" htmlColor="#FF9999" />
          )}
          condition={props.data.inactiveCondition}
          tooltip={props.data.tooltip}
        />
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <div
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </div>
  );
}

function SingleValue(props) {
  return (
    <div
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {props.children}
    </div>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  //Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const PF2MSearchSelect = ({
  children,
  classes,
  theme,
  onChange,
  value,
  disabled,
  placeholder,
  ...rest
}) => {
  const { t: translate } = useTranslation();
  const foundChild = children.find(r => r.value === value);
  return (
    <FormControl className={classes.root}>
      <NoSsr>
        <Select
          classes={classes}
          options={children}
          components={components}
          menuPlacement='auto'
          minMenuHeight={200}
          value={foundChild || { label: '', value: 0 }}
          onChange={e => onChange({
            target: {
              value: e ? e.value : 0,
              label: e ? e.label : '',
            },
          })}
          placeholder={placeholder}
          isClearable
          noOptionsMessage={() => translate('common:NoOptions')}
          isDisabled={disabled}
          {...rest}
        />
      </NoSsr>
    </FormControl>
  );
};

PF2MSearchSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
};

PF2MSearchSelect.defaultProps = {
  value: 0,
  onChange: () => null,
  disabled: false,
  children: [],
  placeholder: '',
};

export default withStyles(styles, { withTheme: true })(PF2MSearchSelect);
