import React, { useState, useEffect } from 'react';
import { IconButton, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import Header from '~/pages/Manager/Header';
import { useTranslation } from 'react-i18next';
import { AddPhotoAlternate } from '@material-ui/icons';
import styles from '../styles';
import {
  getEquipmentTypes,
  getCodes,
  getCodeTypes,
  getCodeGroups,
  getCodesTemplate,
  exportCodes,
  importCodes,
  setCodesValues,
  updateCodes,
  resetCodes,
  addCode,
} from '~/store/manager/actions';
import PF2MDataTable from '~/components/PF2MDataTable';

const CodesTab = ({
  classes,
  codes,
  equipmentTypes,
  codeTypes,
  codeGroups,
  getEquipmentTypes: loadEquipmentTypes,
  getCodeTypes: loadCodeTypes,
  getCodeGroups: loadCodeGroups,
  getCodes: getAll,
  getCodesTemplate: getTemplateAction,
  exportCodes: exportAction,
  importCodes: importAction,
  setCodesValues: updateItem,
  updateCodes: update,
  resetCodes: reset,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const { t: translate } = useTranslation();

  const dispatch = useDispatch();


  useEffect(() => {
    async function fetchData() {
      getAll(true);
      loadEquipmentTypes();
      loadCodeTypes();
      await loadCodeGroups();
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll, loadCodeGroups, loadCodeTypes, loadEquipmentTypes]);

  const columns = [
    {
      editable: (_, rowData) => rowData && rowData.update_status === 'I',
      title: translate('common:EquipmentType'),
      field: 'id_equip',
      lookup: equipmentTypes,
    },
    {
      editable: 'always',
      title: translate('common:CodeType'),
      field: 'code_type',
      lookup: codeTypes.map(item => ({ ...item, name: translate(`common:${item.name}`) })),
    },
    {
      editable: 'always',
      title: translate('common:CodeGroup'),
      field: 'id_group',
      lookup: codeGroups,
    },
    { editable: 'always', title: translate('common:Code'), field: 'name' },
    {
      editable: 'always', title: translate('common:LOGOUT'), field: 'is_logout', customType: 'boolean', filtering: false,
    },
    {
      editable: 'always', title: translate('common:Visible'), field: 'is_visible', customType: 'boolean', inverted: false,
    },
    {
      editable: 'always', title: translate('common:STATUS'), field: 'active', customType: 'boolean',
    },
    {
      hidden: true,
      filtering: false,
      editable: 'never',
      title: translate('common:Icon'),
      render: () => (
        <IconButton
          style={{ outline: 'none' }}
          onClick={() => {
            // todo
          }}
        >
          <AddPhotoAlternate className={classes.iconStyle} />
        </IconButton>
      ),
    },
    {
      editable: 'always',
      title: translate('common:HourMachine'),
      field: 'hour_machine',
      type: 'boolean',
    },
  ];

  return (
    <div className={classes.tabContainer}>
      <Header
        getTemplateAction={getTemplateAction}
        importAction={importAction}
        exportAction={exportAction}
      />
      <hr style={{ margin: '0px' }} />
      <PF2MDataTable
        title={translate('common:Codes')}
        options={{
          showTitle: true,
          search: true,
          selection: true,
          filtering: true,
          pageSize: 20,
          pageSizeOptions: [20, 50, 100],
          hideDelete: true,
          editableRowValidation: rowData => (rowData.id < 0 && rowData.update_status !== 'I'),
          maxBodyHeight: 'calc(100vh - 350px)',
          minBodyHeight: 'calc(100vh - 350px)',
        }}
        onAdd={newData => dispatch(addCode(newData))}
        onChange={(newData) => {
          newData.forEach((row) => {
            updateItem(row);
          });
        }}
        onDiscard={() => reset()}
        onApply={() => update(codes)}
        columns={columns}
        data={codes}
      />
    </div>
  );
};

CodesTab.propTypes = {
  classes: PropTypes.object.isRequired,
  codes: PropTypes.array,
  equipmentTypes: PropTypes.array,
  codeTypes: PropTypes.array,
  codeGroups: PropTypes.array,
  getEquipmentTypes: PropTypes.func,
  getCodes: PropTypes.func,
  getCodeTypes: PropTypes.func,
  getCodeGroups: PropTypes.func,
  getCodesTemplate: PropTypes.func,
  exportCodes: PropTypes.func,
  importCodes: PropTypes.func,
  setCodesValues: PropTypes.func,
  updateCodes: PropTypes.func,
  resetCodes: PropTypes.func,
};

CodesTab.defaultProps = {
  codes: [],
  equipmentTypes: [],
  codeTypes: [],
  codeGroups: [],
  getEquipmentTypes: () => { },
  getCodes: () => { },
  getCodeTypes: () => { },
  getCodeGroups: () => { },
  getCodesTemplate: () => { },
  exportCodes: () => { },
  importCodes: () => { },
  setCodesValues: null,
  updateCodes: null,
  resetCodes: null,
};

const mapStateToProps = state => ({
  equipmentTypes: state.manager.equipmentTypes,
  codes: state.manager.codes,
  codeTypes: state.manager.codeTypes,
  codeGroups: state.manager.codeGroups,
  isCodesDirty: state.manager.isCodesDirty,
});

const mapDispatchToProps = {
  getEquipmentTypes,
  getCodes,
  getCodeTypes,
  getCodeGroups,
  getCodesTemplate,
  exportCodes,
  importCodes,
  setCodesValues,
  updateCodes,
  resetCodes,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CodesTab));
