import React, { useEffect, useMemo, useState } from 'react';
import { Modal, withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import PF2MDataTable, { icons } from '~/components/PF2MDataTable';
import usePlatformConfiguration from '~/services/platformConfiguration';

import {
  daysToMiliseconds, timeDiffFormater,
} from '~/utils/moment';
import { getCodeTypes } from '~/store/manager/actions';
import {
  deleteCodesRawItem,
  newCodesRawItem,
  restoreOriginalCodesRaw,
  setCodesRawValues,
  updateCodesRawList,
} from '~/store/dailyparts/actions';
import styles from '../styles';
import CodeMigrationsModal from './CodeMigrationsModal';

function calculateDuration(code, defaultValue = 'InProgress') {
  // return string
  return code.end_timestamp
    ? timeDiffFormater((code.end_timestamp - code.timestamp_op) * 1000, 'h:m:s')
    : defaultValue;
}

const CodeRawList = () => {
  const { t: translate } = useTranslation();
  const equipmentGroups = useSelector(state => state.manager.equipmentsGroups);
  const filters = useSelector(state => state.dailyParts.filters);
  const codesRawListState = useSelector(state => state.dailyParts.codesRawList);

  // eslint-disable-next-line no-underscore-dangle

  const [codeMigrationsModal, setCodeMigrationsModal] = useState(false);

  const codesRawList = useMemo(() => codesRawListState.map(code => ({
    ...code,
    timestamp_op: code.timestamp_op * 1000,
    end_timestamp: code.end_timestamp * 1000,
    duration: calculateDuration(code, translate('common:InProgress')),
    id: code.id_code,
  })), [codesRawListState, translate]);

  const codeTypes = useSelector(state => state.manager.codeTypes);

  const codeGroups = useSelector(state => state.manager.codeGroups);
  const codes = useSelector(state => state.manager.codes);
  const equipmentTypes = useSelector(state => state.manager.equipmentTypes);
  const equipments = useSelector(state => state.manager.equipments);
  const operatorsGroups = useSelector(state => state.manager.operatorsGroups);
  const operators = useSelector(state => state.manager.operators);
  const elements = useSelector(state => state.manager.elements);
  const subElements = useSelector(state => state.manager.subElements);
  const exceptionTypes = useSelector(state => state.manager.exceptionTypes);
  const dispatch = useDispatch();
  const { value: consolidationPeriod } = usePlatformConfiguration('expire_edition_data');

  const validateDates = type => (row) => {
    const now = new Date();
    if (row.end_timestamp && row.timestamp_op > row.end_timestamp) {
      return { error: true, helperText: '' };
    }
    if (type === 'time') return true;

    const consolidationTimestamp = (now.getTime() - daysToMiliseconds(consolidationPeriod)) / 1000;
    if (row.timestamp_op < consolidationTimestamp) {
      return { error: true, helperText: translate('validation:DateCantBeLowerThanConsolidation') };
    }
    return true;
  };

  const validateInprogress = row => (
    row.id_code < 0 || row.duration !== translate('common:InProgress')
  );

  const columns = [
    {
      editable: (_columnDef, rowData) => validateInprogress(rowData),
      title: translate('common:DatetimeStart'),
      field: 'timestamp_op',
      validate: validateDates('date'),
      customType: 'dateTimeByTimestamp',
    },
    {
      editable: (_columnDef, rowData) => (
        !(rowData?.update_status === 'I' || !rowData?.end_timestamp)
          && validateInprogress(rowData)
      ),
      title: translate('common:DatetimeEnd'),
      field: 'end_timestamp',
      validate: validateDates('date'),
      customType: 'dateTimeByTimestamp',
    },
    {
      editable: 'never',
      title: translate('common:Duration'),
      field: 'duration',
    },
    {
      editable: (_columnDef, rowData) => validateInprogress(rowData),
      title: translate('common:CodeType'),
      field: 'code_type',
      lookup: codeTypes.map(e => ({ ...e, name: translate(`common:${e.name}`) })),
      customType: 'filterLookup',
    },
    {
      editable: (_columnDef, rowData) => validateInprogress(rowData),
      title: translate('common:CodeGroup'),
      field: 'code_group',
      customType: 'filterLookup',
      lookup: codeGroups,
      lookupFilter: (codeGroupOption, row) => (
        String(codeGroupOption.code_type) === String(row.code_type)
        && String(row.equip_type) === String(codeGroupOption.id_equip)
      ),
    },
    {
      editable: (_columnDef, rowData) => validateInprogress(rowData),
      title: translate('common:Code'),
      field: 'code_id',
      customType: 'filterLookup',
      lookup: codes,
      render: rowData => codes.find(
        e => e.id_group === rowData.code_group
        && e.id === rowData.code_id,
      )?.name || null,
      lookupFilter: (codeOption, row) => {
        // No code Group Selected
        if (String(row.code_group) === '0') {
          return (
            String(row.code_type) === String(codeOption.code_type)
            && String(row.equip_type) === String(codeOption.id_equip)
          );
        }
        return (
          String(codeOption.id_group) === String(row.code_group)
          && String(row.equip_type) === String(codeOption.id_equip)
        );
      },
    },
    {
      editable: (_columnDef, rowData) => validateInprogress(rowData),
      title: translate('common:OperatorGroup'),
      field: 'operator_group',
      lookup: operatorsGroups,
      customType: 'filterLookup',
    },
    {
      editable: (_columnDef, rowData) => validateInprogress(rowData),
      title: translate('common:Operator'),
      field: 'operator_id',
      customType: 'filterLookup',
      lookupKey: 'id_operator',
      lookup: operators,
      lookupFilter: (e, f) => String(e.id_group) === String(f.operator_group)
        || f.id_operator_group === '0',
    },
    {
      editable: (_columnDef, rowData) => validateInprogress(rowData),
      title: translate('common:EquipmentType'),
      field: 'equip_type',
      lookup: equipmentTypes,
      customType: 'filterLookup',
    },
    {
      editable: (_columnDef, rowData) => validateInprogress(rowData),
      customType: 'filterLookup',
      title: translate('common:EquipmentGroup'),
      field: 'equip_group',
      lookup: equipmentGroups,
      lookupFilter: (e, f) => String(e.id_equipament) === String(f.equip_type)
        || String(f.equip_type) === '0',
    },
    {
      editable: (_columnDef, rowData) => validateInprogress(rowData),
      title: translate('common:Equipment'),
      customType: 'filterLookup',
      field: 'equip_id',
      lookup: equipments,
      lookupFilter: (e, f) => String(e.id_group) === String(f.equip_group)
          || String(f.equip_group) === '0',
    },
    {
      editable: (_columnDef, rowData) => validateInprogress(rowData),
      title: translate('common:Origin'),
      customType: 'filterLookup',
      field: 'element_id',
      lookup: elements,
    },
    {
      editable: (_columnDef, rowData) => validateInprogress(rowData),
      title: translate('common:OriginPoint'),
      field: 'point_id',
      customType: 'filterLookup',
      lookupFilter: (option, row) => (
        String(option.id_element) === String(row.element_id)
        || String(row.element_id) === '0'
      ),
      lookup: subElements,
    },
    {
      editable: 'always',
      title: translate('common:InactiveTurnPersistency'),
      field: 'has_logged_out',
      customType: 'boolean',
      inverted: true,
    },
    {
      editable: 'never',
      title: translate('common:EntryType'),
      field: 'exception_type',
      lookup: exceptionTypes,
      customType: 'filterLookup',
    },
  ];

  useEffect(() => {
    if (codeTypes.length <= 0) dispatch(getCodeTypes());
  }, [codeTypes, dispatch]);

  const sanitizeCodesRawList = (list) => {
    const sanitized = list.map((e) => {
      const { code_migrations, ...rest } = e;
      return rest;
    });

    return sanitized;
  };

  return (
    <div>
      <PF2MDataTable
        title={translate('common:Cycles')}
        options={{
          showTitle: true,
          search: true,
          selection: true,
          cacheKey: 'daily_parts_codes_raw',
        }}
        extraActions={[
          {
            icon: icons.AccessTime,
            isFreeAction: true,
            tooltip: translate('common:CodeMigrationsDynamic'),
            onClick: () => {
              setCodeMigrationsModal(true);
            },
          },
        ]}
        onAdd={newData => dispatch(newCodesRawItem(newData))}
        onDiscard={() => dispatch(restoreOriginalCodesRaw())}
        onApply={() => dispatch(updateCodesRawList(sanitizeCodesRawList(codesRawList), filters))}
        onChange={(newData) => {
          newData.forEach((row) => {
            const code = codes.find(cp => cp.id === row.code_id);
            if (!code) {
              // eslint-disable-next-line no-console
              console.error('Código não encontrado');
              return;
            }
            const newRow = {
              ...row,
              timestamp_op: (
                typeof row.timestamp_op === 'object'
                  ? row.timestamp_op.unix()
                  : (row.timestamp_op / 1000)
              ),
              end_timestamp: (
                typeof row.end_timestamp === 'object'
                  ? row.end_timestamp.unix()
                  : (row.end_timestamp / 1000)
              ),
              is_logout: code.is_logout,
            };
            dispatch(setCodesRawValues(newRow));
          });
        }}
        onDelete={(newData) => {
          newData.forEach((e) => {
            const newRow = {
              ...e,
              timestamp_op: e.timestamp_op / 1000,
              end_timestamp: e.end_timestamp / 1000,
            };
            dispatch(deleteCodesRawItem(newRow));
          });
        }}
        initialFormData={
          {
            ...columns.reduce((acc, e) => ({ ...acc, [e.field]: 0 }), {}),
            equip_type: filters.equipmentType,
            timestamp_op: Math.floor(Date.now() / 1000),
            end_timestamp: null,
            id_code: -(codesRawList.length + 1),
          }
        }
        columns={columns}
        data={codesRawList.length > 0 ? codesRawList : []}
      />
      <Modal
        open={codeMigrationsModal}
        onClose={() => setCodeMigrationsModal(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CodeMigrationsModal
          handleClose={() => setCodeMigrationsModal(false)}
          filters={filters}
        />
      </Modal>
    </div>
  );
};

CodeRawList.propTypes = {};
CodeRawList.defaultProps = {};

export default withStyles(styles)(CodeRawList);
