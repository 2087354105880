import React, { useState } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup, Fab, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import CreateIcon from '@material-ui/icons/Create';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import PF2MButton from '~/components/PF2MButton';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import {
  Card, CardBody, CardHeader, CardIcon,
} from '~/components/Card';
import { updateEquipmentStatus } from '~/store/dispatch/actions';

const INIT_SELECTED_FIELDS = {
  id_code_group: 0,
  code_type: -1,
  id_code: 0,
};

const EquipmentStatusEditAllModal = ({
  classes, selectedItems, handleClose,
}) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const equipmentStatuses = useSelector(state => state.dispatch.equipmentStatuses);
  const [isModalOpen, setModalOpen] = useState(false);
  const codeTypes = useSelector(state => state.manager.codeTypes.map(e => ({ ...e, name: translate(`common:${e.name}`) })));
  const codeGroups = useSelector(state => state.manager.codeGroups);
  const codes = useSelector(state => state.manager.codes);
  const [selectedFields, setSelectedFields] = useState(INIT_SELECTED_FIELDS);
  const currentFilter = useSelector(state => state.dispatch.currentEquipmentStatusFilter);

  const filterCodeGroups = u => (currentFilter.equip_type === u.id_equip)
    && (selectedFields.code_type === -1 || u.code_type === selectedFields.code_type);

  const handleUpdateItems = () => {
    const selectedEquipmentStatuses = equipmentStatuses.filter(
      e => selectedItems.find(sel => sel.id === e.id),
    );
    const newSelectedItems = selectedEquipmentStatuses.map(i => ({
      ...i,
      code_type: selectedFields.code_type !== -1 ? selectedFields.code_type : i.code_type,
      id_code_group: selectedFields.id_code_group !== -1
        ? selectedFields.id_code_group : i.id_code_group,
      id_code: selectedFields.id_code ? selectedFields.id_code : i.id_code,
    }));
    return dispatch(updateEquipmentStatus(newSelectedItems));
  };

  const filterCodes = c => selectedFields.code_type === c.code_type
    && (selectedFields.id_code_group === 0 || c.id_group === selectedFields.id_code_group)
    && (currentFilter.equip_type === c.id_equip);


  // só pode salvar a alteração caso todos os campos sejam preenchidos.
  const disableSaveButton = Object.keys(selectedFields)
    .map(k => (selectedFields[k] === INIT_SELECTED_FIELDS[k]))
    .includes(true);

  const modalHandleChange = (field, value) => {
    setSelectedFields((prevSelectedFields) => {
      switch (field) {
        case 'code_type':
          return {
            ...prevSelectedFields, id_code_group: 0, id_code: 0, code_type: value,
          };
        case 'id_code_group':
          return { ...prevSelectedFields, id_code: 0, id_code_group: value };
        default:
          return { ...prevSelectedFields, [field]: value };
      }
    });
  };

  const renderHeader = () => (
    <div>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
      >
        {`${translate('common:EditEntries')}`}
      </Typography>
    </div>
  );

  const renderFormControl = (
    item,
    key,
    title,
    collection,
    initialValue = 0,
    collectionId = 'id',
  ) => (
    <FormControl>
      <FormLabel className={classes.formLabel}>
        {title}
      </FormLabel>
      <FormGroup>
        <PF2MSearchSelectOutlined
          placeholder={null}
          className={classnames('form-control', classes.field)}
          value={item[key] || item[key] === 0 ? item[key] : initialValue}
          onChange={e => modalHandleChange(key, e.target.value)}
        >
          {[{ value: initialValue, label: translate('common:Select') }]
            .concat(collection
              .map(e => ({ value: e[collectionId], label: e.name || '0' })))}
        </PF2MSearchSelectOutlined>
      </FormGroup>
    </FormControl>
  );

  return (
    <div>
      <Card>
        <CardHeader icon>
          <CardIcon color="warning">
            <CreateIcon />
          </CardIcon>
        </CardHeader>
        <CardBody>
          {renderHeader()}
          <hr />
          <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridColumnGap: 25,
            gridRowGap: 10,
          }}
          >
            {renderFormControl(selectedFields, 'code_type', translate('common:CodeType'), codeTypes, -1)}
            {renderFormControl(selectedFields, 'id_code_group', translate('common:CodeGroup'), codeGroups.filter(filterCodeGroups))}
            {renderFormControl(selectedFields, 'id_code', translate('common:Code'), codes.filter(filterCodes))}
          </div>
          <div style={{
            display: 'flex',
            marginTop: 10,
            alignItems: 'center',
            justifyContent: 'center',
          }}
          >
            <PF2MButton
              color="primary"
              onClick={() => setSelectedFields(INIT_SELECTED_FIELDS)}
            >
              <DeleteOutline className={classes.icon} />
              {translate('common:DiscardChanges')}
            </PF2MButton>
            <Fab
              variant="extended"
              size="medium"
              color="secondary"
              onClick={() => {
                handleUpdateItems();
                handleClose();
              }}
              className={classes.saveButton}
              disabled={disableSaveButton}
            >
              {translate('common:SaveData')}
            </Fab>
          </div>
        </CardBody>
      </Card>
      <PF2MAlertDialog
        hasCancel={false}
        confirmText={translate('common:Understood')}
        description={translate('validation:ThereAreBlankFields')}
        open={isModalOpen}
        onConfirm={() => setModalOpen(false)}
      />
    </div>
  );
};

EquipmentStatusEditAllModal.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedItems: PropTypes.array,
  handleClose: PropTypes.func.isRequired,
};

EquipmentStatusEditAllModal.defaultProps = {
  selectedItems: [],
};

export default withStyles(styles)(EquipmentStatusEditAllModal);
