import React, { useEffect, useState, useMemo } from 'react';
import {
  withStyles,
} from '@material-ui/core';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { formatDate, newMomentDate, formatTime } from '~/utils/moment';
import {
  deleteHourmeterItem,
  setHourmeterValues,
  restoreOriginalHourmeter,
  createHourmeterItem,
  updateHourmeterList,
} from '~/store/dailyparts/actions';
import PF2MDataTable from '~/components/PF2MDataTable';
import { useTranslation } from 'react-i18next';
import { defaultValue } from './utils';
import styles from '../styles';


const HourmeterNamesAssoc = [
  {
    final_field: 'hourmeter_final',
    start_field: 'hourmeter_start',
    name_field: 'hourmeter_name',
    visible_field: 'hourmeter_visible',
  },
  {
    final_field: 'kilometer_final',
    start_field: 'kilometer_start',
    name_field: 'kilometer_name',
    visible_field: 'kilometer_visible',
  },
  {
    final_field: 'hourmeter_final_3',
    start_field: 'hourmeter_start_3',
    name_field: 'hourmeter_name_3',
    visible_field: 'hourmeter_visible_3',
  },
  {
    final_field: 'hourmeter_final_4',
    start_field: 'hourmeter_start_4',
    name_field: 'hourmeter_name_4',
    visible_field: 'hourmeter_visible_4',
  },
  {
    final_field: 'hourmeter_final_5',
    start_field: 'hourmeter_start_5',
    name_field: 'hourmeter_name_5',
    visible_field: 'hourmeter_visible_5',
  },
  {
    final_field: 'hourmeter_final_6',
    start_field: 'hourmeter_start_6',
    name_field: 'hourmeter_name_6',
    visible_field: 'hourmeter_visible_6',
  },
  {
    final_field: 'hourmeter_final_7',
    start_field: 'hourmeter_start_7',
    name_field: 'hourmeter_name_7',
    visible_field: 'hourmeter_visible_7',
  },
  {
    final_field: 'hourmeter_final_8',
    start_field: 'hourmeter_start_8',
    name_field: 'hourmeter_name_8',
    visible_field: 'hourmeter_visible_8',
  },
  {
    final_field: 'hourmeter_final_9',
    start_field: 'hourmeter_start_9',
    name_field: 'hourmeter_name_9',
    visible_field: 'hourmeter_visible_9',
  },
  {
    final_field: 'hourmeter_final_10',
    start_field: 'hourmeter_start_10',
    name_field: 'hourmeter_name_10',
    visible_field: 'hourmeter_visible_10',
  },
];

const HourmeterList = () => {
  const { t: translate } = useTranslation();

  const hourmeterList = useSelector(state => state.dailyParts.hourmeterList);
  const hourmeterNames = useSelector(state => state.manager.hourmeterNames);
  const filters = useSelector(state => state.dailyParts.filters);
  const [dynamicTitles, setDynamicTitles] = useState([]);
  const turns = useSelector(state => state.manager.turns);
  const teams = useSelector(state => state.manager.teams);
  const operatorsGroups = useSelector(state => state.manager.operatorsGroups
    .filter(o => o.name), shallowEqual);
  const operators = useSelector(state => state.manager.operators.filter(o => o.name), shallowEqual);
  const equipmentGroups = useSelector(state => state.manager.equipmentsGroups
    .filter(eg => eg.id_equipament === filters.equipmentType), shallowEqual);
  const equipments = useSelector(state => state.manager.equipments
    .filter(equip => equip.id_equip === filters.equipmentType), shallowEqual);
  const equipmentTypes = useSelector(state => state.manager.equipmentTypes);
  const teamGroups = useSelector(state => state.manager.teamGroups);
  const dispatch = useDispatch();

  const columns = [
    {
      editable: 'always',
      title: translate('common:Date'),
      field: 'hourmeter_date',
      customType: 'date',
    },
    {
      editable: 'always',
      title: translate('common:Hour'),
      field: 'hourmeter_time',
      customType: 'time',
    },
    {
      editable: 'always',
      title: translate('common:OperatorGroup'),
      field: 'operator_group',
      lookup: operatorsGroups,
    },
    {
      editable: 'always',
      title: translate('common:Operator'),
      field: 'operator_id',
      customType: 'filterLookup',
      lookup: operators,
      lookupKey: 'id_operator',
      lookupFilter: (option, row) => String(option.id_group) === String(row.operator_group)
        || String(row.operator_group) === '0',
    },
    {
      editable: 'always',
      title: translate('common:Shift'),
      field: 'turn',
      lookup: turns.map(e => ({ id: e.id, name: `${e.turn_group_name} - ${e.name}` })),
    },
    {
      editable: 'always',
      title: translate('common:TeamGroup'),
      field: 'team_group',
      lookup: teamGroups,
    },
    {
      editable: 'always',
      title: translate('common:Team'),
      field: 'team_id',
      lookup: teams,
      lookupFilter: (option, row) => String(option.team_id) === String(row.team_group)
        || String(row.team_group) === '0',
    },
    {
      editable: 'always',
      title: translate('common:EquipmentType'),
      field: 'equip_type',
      lookup: equipmentTypes,
    },
    {
      editable: 'always',
      customType: 'filterLookup',
      title: translate('common:EquipmentGroup'),
      field: 'equip_group',
      lookup: equipmentGroups,
      lookupFilter: (option, row) => String(option.id_equipament) === String(row.equip_type)
        || String(row.equip_type) === '0',
    },
    {
      editable: 'always',
      title: translate('common:Equipment'),
      customType: 'filterLookup',
      field: 'equip_id',
      lookup: equipments,
      lookupFilter: (option, row) => String(option.id_group) === String(row.equip_group)
        || String(row.equip_group) === '0',
    },
  ];

  useEffect(() => {
    const makeHourmeterLabel = (hourmeterName, state, i) => {
      if (state === 'initial') {
        return hourmeterName ? `${hourmeterName} ${translate('common:Initial')}` : `${translate('common:InitialHourmeter')} ${i}`;
      }
      return hourmeterName ? `${hourmeterName} ${translate('common:Final')}` : `${translate('common:FinalHourmeter')} ${i}`;
    };

    const filteredHourmeterNames = hourmeterNames.find(
      k => k.equipament_types_id === filters.equipmentType,
    );
    if (filteredHourmeterNames) {
      const newTitles = [];
      HourmeterNamesAssoc
        .forEach((e, i) => {
          newTitles.push({
            editable: 'always',
            title: makeHourmeterLabel(filteredHourmeterNames[e.name_field], 'initial', i + 1),
            field: e.start_field,
          });
          newTitles.push({
            editable: 'always',
            title: makeHourmeterLabel(filteredHourmeterNames[e.name_field], 'final', i + 1),
            field: e.final_field,
          });
        });
      setDynamicTitles(newTitles);
    } else {
      setDynamicTitles([
        {
          editable: 'always',
          title: translate('common:InitialHourmeter'),
          field: 'hourmeter_start',
        },
        {
          editable: 'always',
          title: translate('common:FinalHourmeter'),
          field: 'hourmeter_final',
        },
        {
          editable: 'always',
          title: translate('common:InitialKilometer'),
          field: 'kilometer_start',
        },
        {
          editable: 'always',
          title: translate('common:FinalKilometer'),
          field: 'kilometer_final',
        },
      ]);
    }
  }, [hourmeterNames, filters, translate]);
  const getTableHeaderList = () => {
    if (dynamicTitles.length) {
      return columns.concat(dynamicTitles);
    }
    return columns;
  };

  const getHourmeterList = useMemo(() => hourmeterList
    .map(hourmeter => ({
      ...hourmeter,
      id: hourmeter.id_line,
    })), [hourmeterList]);

  return (
    <>
      <PF2MDataTable
        title={translate('common:Cycles')}
        options={{
          showTitle: true,
          search: true,
          selection: true,
          cacheKey: 'daily_parts_hourmeter',
        }}
        onAdd={(newData) => {
          dispatch(createHourmeterItem(newData));
        }}
        onDiscard={() => {
          dispatch(restoreOriginalHourmeter());
        }
        }
        onApply={() => {
          let newData = [...hourmeterList];
          newData = newData
            .map((hourmeter, index) => Object.keys(hourmeter).reduce((newObj, key) => {
              if (
                (newData[index][key] !== '' && newData[index][key] !== null)
                && (key !== 'id' && key !== 'undefined')
              ) {
                return Object.assign(newObj, { [key]: newData[index][key] });
              }
              return newObj;
            }, {}));
          dispatch(updateHourmeterList(newData, filters));
        }}
        onChange={(newData) => {
          newData.forEach(({ ...row }) => {
            dispatch(setHourmeterValues({
              ...row,
            }));
          });
        }}
        onDelete={(newData) => {
          newData.forEach((e) => {
            dispatch(deleteHourmeterItem(e));
          });
        }}
        initialFormData={
          {
            ...columns.reduce((acc, e) => ({ ...acc, [e.field]: '0' }), {}),
            equip_type: filters.equipmentType,
            hourmeter_date: formatDate(newMomentDate(), true),
            hourmeter_time: formatTime(newMomentDate(), 'HH:mm:ss'),
            ...defaultValue,
          }
        }
        columns={getTableHeaderList()}
        data={hourmeterList.length ? getHourmeterList : []}
      />
    </>
  );
};

export default withStyles(styles)(HourmeterList);
