/* eslint-disable camelcase */
import React from 'react';
import {
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';
import { DispatchDragNDropTypes, DispatchStatus, padNumber } from '~/utils';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import styles from '../styles';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import PF2MEquipIcon from '~/components/PF2MEquipIcon';
import {
  getLoadMaterialMappings,
  setLoadAllocationPriority,
  setLoadMaterialMappingsItem,
  setSelectedLoadAllocation,
  setSelectedTruckAllocation,
} from '~/store/dispatch/actions';

const AllocationRow = ({
  loadAllocation, loadAllocations, trucks, isFirst, isLast,
  onTruckDrop, onLoadDrop,
  classes, openLoadModal, openTruckModal,
}) => {
  const dispatch = useDispatch();

  const [, loadDrop] = useDrop({
    accept: DispatchDragNDropTypes.LOAD,
    drop: (monitor) => {
      if (loadAllocation.equip_id !== monitor.obj.equip_id) {
        dispatch(getLoadMaterialMappings({
          equipmentId: loadAllocation.equip_id,
        },
        (response) => {
          const foundLoadMaterialMapping = response
            .filter(r => r.load_allocation_id
              && r.load_allocation_id === loadAllocation.id)
            .map(r => r.material_id);

          const newLoadMaterialMappingItems = response.map(m => ({
            ...m,
            equip_id: monitor.obj.equip_id,
            equip_name: monitor.obj.equip_name,
            update_status: 'I',
            id: null,
          }));

          newLoadMaterialMappingItems.forEach((m) => {
            dispatch(setLoadMaterialMappingsItem(m));
          });

          const newLoadAllocation = {
            ...loadAllocation,
            equip_id: monitor.obj.equip_id,
            equip_name: monitor.obj.equip_name,
            materials: foundLoadMaterialMapping,
          };

          onLoadDrop(newLoadAllocation,
            newLoadMaterialMappingItems
              .filter(r => newLoadAllocation.materials.includes(r.material_id)));
        }));
      }
    },
    collect: monitor => ({
      isLoadOver: !!monitor.isOver(),
    }),
  });

  const [{ dragItem }, truckDrop] = useDrop({
    accept: DispatchDragNDropTypes.TRUCK,
    drop: (monitor) => {
      if (loadAllocation.id !== monitor.obj.load_allocation_id) {
        onTruckDrop(monitor.obj, loadAllocation.id);
      }
    },
    collect: monitor => ({
      dragItem: monitor.getItem(),
    }),
  });

  return (
    <div
      key={loadAllocation.id}
      style={{
        display: 'flex',
        width: '100%',
        height: 110,
      }}
    >
      <div style={{
        width: '8%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
      >
        <div style={{ cursor: 'pointer', width: '50%', textAlign: 'center' }}>
          <ArrowDropUpIcon
            fontSize={'large'}
            style={{ cursor: 'pointer', width: '100%' }}
            onClick={() => {
              if (!isFirst) {
                dispatch(setLoadAllocationPriority(
                  loadAllocation, loadAllocations, loadAllocation.priority - 1,
                ));
              }
            }}
          />
          <ArrowDropDownIcon
            fontSize={'large'}
            style={{ cursor: 'pointer', width: '100%' }}
            onClick={() => {
              if (!isLast) {
                dispatch(setLoadAllocationPriority(
                  loadAllocation, loadAllocations, loadAllocation.priority + 1,
                ));
              }
            }}
          />
        </div>
        <div
          className={classes.labelTypeSelector}
          style={{
            width: '50%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            fontSize: 40,
          }}
        >
          {padNumber(loadAllocation.priority, 2)}
        </div>
      </div>

      <div
        className={classes.labelTypeSelector}
        style={{
          width: '4%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: 40,
        }}
      >
        {loadAllocation.dynamic_configuration?.group_name || '-'}
      </div>
      <div
        style={{
          width: '10%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#3D5465',
          borderBottom: '1px solid white',
          color: 'white',
        }}
        ref={loadDrop}
      >
        <PF2MEquipIcon
          item={loadAllocation}
          itemType={DispatchDragNDropTypes.LOAD}
          customStyles={{
            opacity: (dragItem && dragItem.obj?.equip_id === loadAllocation.equip_id) ? 0 : 1,
          }}
          onClick={() => {
            openLoadModal(true);
            dispatch(setSelectedLoadAllocation(loadAllocation));
          }}
        />
      </div>
      <PF2MScrollbar
        className={classes.scrollbars}
        style={{ width: '77%' }}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#dedede',
          }}
          ref={truckDrop}
        >
          {trucks
            .map(truck => (
              <PF2MEquipIcon
                key={truck.equip_id}
                item={truck}
                customStyles={{
                  marginLeft: 15,
                  opacity: (dragItem && dragItem.obj?.equip_id === truck.equip_id) ? 0 : 1,
                }}
                itemType={DispatchDragNDropTypes.TRUCK}
                onClick={() => {
                  dispatch(setSelectedTruckAllocation(truck));
                  openTruckModal(true);
                }}
              />
            ))}
        </div>
      </PF2MScrollbar>
      <div style={{ width: '5%', border: '2px solid #4a4a4a' }}>
        <div style={{ paddingLeft: 10, height: '33%' }} className={classes.labelTypeSelector}>
          {`OP: ${trucks.filter(r => r.status === DispatchStatus.OP).length}`}
        </div>
        <div style={{ paddingLeft: 10, height: '33%' }} className={classes.labelTypeSelector}>
          {`PO: ${trucks.filter(r => r.status === DispatchStatus.PO).length}`}
        </div>
        <div style={{ paddingLeft: 10, height: '33%' }} className={classes.labelTypeSelector}>
          {`MA: ${trucks.filter(r => r.status === DispatchStatus.MA).length}`}
        </div>
      </div>
    </div>
  );
};

AllocationRow.propTypes = {
  loadAllocation: PropTypes.object,
  loadAllocations: PropTypes.array,
  trucks: PropTypes.array,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  onTruckDrop: PropTypes.func,
  onLoadDrop: PropTypes.func,
  classes: PropTypes.object,
  openLoadModal: PropTypes.func,
  openTruckModal: PropTypes.func,
};

AllocationRow.defaultProps = {
  loadAllocation: {},
  loadAllocations: [],
  trucks: [],
  isFirst: false,
  isLast: false,
  onTruckDrop: () => null,
  onLoadDrop: () => null,
  classes: {},
  openLoadModal: () => null,
  openTruckModal: () => null,
};

export default withStyles(styles)(AllocationRow);
