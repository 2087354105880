export const SET_OPERATORS_LIST = 'SET_OPERATORS_LIST';
export const SET_OPERATORS_GROUPS_LIST = 'SET_OPERATORS_GROUPS_LIST';
export const SET_EQUIPMENTS_LIST = 'SET_EQUIPMENTS_LIST';
export const SET_EQUIPMENTS_GROUPS_LIST = 'SET_EQUIPMENTS_GROUPS_LIST';
export const SET_MATERIALS_LIST = 'SET_MATERIALS_LIST';
export const SET_MATERIALS_GROUPS_LIST = 'SET_MATERIALS_GROUPS_LIST';
export const SET_TURNS_GROUPS_LIST = 'SET_TURNS_GROUPS_LIST';
export const SET_TURN_GROUP_VALUES = 'SET_TURN_GROUP_VALUES';
export const RESTORE_TURN_ITEM = 'RESTORE_TURN_ITEM';
export const DELETE_TURN_ITEM = 'DELETE_TURN_ITEM';
export const ADD_TURN_GROUP = 'ADD_TURN_GROUP';
export const RESET_TURNS_GROUPS = 'RESET_TURNS_GROUPS';
// escala de turno
export const SET_SHIFT_SCALES_LIST = 'SET_SHIFT_SCALES_LIST';
export const SET_SHIFT_SCALE_VALUES = 'SET_SHIFT_SCALE_VALUES';
export const ADD_SHIFT_SCALE = 'ADD_SHIFT_SCALE';
export const RESET_SHIFT_SCALES = 'RESET_SHIFT_SCALES';
// grupo de equipamento
export const SET_EQUIPMENT_TURN_GROUPS_LIST = 'SET_EQUIPMENT_TURN_GROUPS_LIST';
export const SET_EQUIPMENT_TURN_GROUP_VALUES = 'SET_EQUIPMENT_TURN_GROUPS_VALUES';
export const ADD_EQUIPMENT_TURN_GROUP = 'ADD_EQUIPMENT_TURN_GROUPS';
export const RESET_EQUIPMENT_TURN_GROUPS = 'RESET_EQUIPMENT_TURN_GROUPS';
// tipo de movimentação
export const SET_HANDLING_TYPES_LIST = 'SET_HANDLING_TYPES_LIST';
export const SET_HANDLING_TYPE_VALUES = 'SET_HANDLING_TYPE_VALUES';
export const ADD_HANDLING_TYPE = 'ADD_HANDLING_TYPE';
export const RESET_HANDLING_TYPES = 'RESET_HANDLING_TYPES';
// elementos
export const SET_ELEMENTS_LIST = 'SET_ELEMENTS_LIST';
export const SET_ELEMENT_VALUES = 'SET_ELEMENT_VALUES';
export const RESET_ELEMENTS = 'RESET_ELEMENTS';
// sub-elementos
export const SET_SUBELEMENTS_LIST = 'SET_SUBELEMENTS_LIST';
export const SET_SUBELEMENT_VALUES = 'SET_SUBELEMENT_VALUES';
export const RESET_SUBELEMENTS = 'RESET_SUBELEMENTS';
// Activity
export const SET_ACTIVITY_TYPES_CHECKLIST_LIST = 'SET_ACTIVITY_TYPES_CHECKLIST_LIST';
export const SET_ACTIVITY_TYPES_CHECKLIST_VALUES = 'SET_ACTIVITY_TYPES_CHECKLIST_VALUES';
export const RESET_ACTIVITY_TYPES_CHECKLIST = 'RESET_ACTIVITY_TYPES_CHECKLIST';
export const ADD_ACTIVITY_TYPES_CHECKLIST = 'ADD_ACTIVITY_TYPES_CHECKLIST';

export const SET_ACTIVITY_ITEMS_CHECKLIST_LIST = 'SET_ACTIVITY_ITEMS_CHECKLIST_LIST';
export const SET_ACTIVITY_ITEMS_CHECKLIST_VALUES = 'SET_ACTIVITY_ITEMS_CHECKLIST_VALUES';
export const RESET_ACTIVITY_ITEMS_CHECKLIST = 'RESET_ACTIVITY_ITEMS_CHECKLIST';
export const ADD_ACTIVITY_ITEMS_CHECKLIST = 'ADD_ACTIVITY_ITEMS_CHECKLIST';
// tipos de sub-elementos
export const SET_SUBELEMENT_TYPES_LIST = 'SET_SUBELEMENT_TYPES_LIST';
export const SET_SUBELEMENT_TYPES_VALUES = 'SET_SUBELEMENT_TYPES_VALUES';
export const RESET_SUBELEMENT_TYPES = 'RESET_SUBELEMENT_TYPES';
// tipos de fluxo
export const SET_FLOW_TYPES_LIST = 'SET_FLOW_TYPES_LIST';
// fluxo de movimentação
export const SET_FLOW_MOVEMENTS_LIST = 'SET_FLOW_MOVEMENTS_LIST';
export const SET_FLOW_MOVEMENT_VALUES = 'SET_FLOW_MOVEMENT_VALUES';
export const ADD_FLOW_MOVEMENT = 'ADD_FLOW_MOVEMENT';
export const RESET_FLOW_MOVEMENTS = 'RESET_FLOW_MOVEMENTS';
// movimentação
export const SET_MATERIAL_MOVEMENTS_LIST = 'SET_MATERIAL_MOVEMENTS_LIST';
export const SET_MATERIAL_MOVEMENT_VALUES = 'SET_MATERIAL_MOVEMENT_VALUES';
export const ADD_MATERIAL_MOVEMENT = 'ADD_MATERIAL_MOVEMENT';
export const RESET_MATERIAL_MOVEMENTS = 'RESET_MATERIAL_MOVEMENTS';
// material subelements
export const SET_MATERIAL_ELEMENTS_LIST = 'SET_MATERIAL_ELEMENTS_LIST';
export const RESET_MATERIAL_ELEMENTS = 'RESET_MATERIAL_ELEMENTS';
export const SET_MATERIAL_ELEMENTS_VALUES = 'SET_MATERIAL_ELEMENTS_VALUES';

export const SET_ALERT_DIALOG = 'SET_ALERT_DIALOG';

// content
export const SET_CONTENTS_LIST = 'SET_CONTENTS_LIST';
export const SET_CONTENT_VARIABLES_LIST = 'SET_CONTENT_VARIABLES_LIST';

// unit operation
export const SET_UNIT_OPERATION_GROUPS_LIST = 'SET_UNIT_OPERATION_GROUPS_LIST';
export const SET_UNIT_OPERATIONS_LIST = 'SET_UNIT_OPERATIONS_LIST';

// hour_machine
export const SET_HOUR_MACHINE_LIST = 'SET_HOUR_MACHINE_LIST';

export const SET_CODES_LIST = 'SET_CODES_LIST';
export const SET_CODE_TYPES_LIST = 'SET_CODE_TYPES_LIST';
export const SET_CODE_GROUPS_LIST = 'SET_CODE_GROUPS_LIST';
export const SET_CODES_VALUES = 'SET_CODES_VALUES';
export const RESET_CODES = 'RESET_CODES';
export const SET_EQUIPMENT_TYPES_LIST = 'SET_EQUIPMENT_TYPES_LIST';
export const ADD_CODE = 'ADD_CODE';


export const SET_WEIGHINGS_LIST = 'SET_WEIGHINGS_LIST';
export const RESET_WEIGHINGS_LIST = 'RESET_WEIGHINGS_LIST';
export const SET_WEIGHING_VALUES = 'SET_WEIGHING_VALUES';

export const SET_CHECKLIST_LIST = 'SET_CHECKLIST_LIST';
// checklist maps
export const SET_CHECKLIST_MAPS_LIST = 'SET_CHECKLIST_MAPS_LIST';
export const SET_CHECKLIST_MAP_VALUES = 'SET_CHECKLIST_MAP_VALUES';
export const RESET_CHECKLIST_MAPS = 'RESET_CHECKLIST_MAPS';

export const SET_TURN_VALUES = 'SET_TURN_VALUES';
export const SET_TURNS_LIST = 'SET_TURNS_LIST';
export const ADD_TURN = 'ADD_TURN';
export const RESET_TURNS = 'RESET_TURNS';

export const SET_DMTS_LIST = 'SET_DMTS_LIST';
export const SET_LOADS_LIST = 'SET_LOADS_LIST';

export const SET_TEAM_GROUPS_LIST = 'SET_TEAM_GROUPS_LIST';
export const ADD_TEAM_GROUP = 'ADD_TEAM_GROUP';
export const SET_TEAM_GROUP_VALUES = 'SET_TEAM_GROUP_VALUES';
export const RESET_TEAM_GROUPS = 'RESET_TEAM_GROUPS';

export const SET_TEAMS_LIST = 'SET_TEAMS_LIST';
export const ADD_TEAM = 'ADD_TEAM';
export const SET_TEAM_VALUES = 'SET_TEAM_VALUES';
export const RESET_TEAMS = 'RESET_TEAMS';

export const SET_TEAMS_LISTING_LIST = 'SET_TEAMS_LISTING_LIST';
export const ADD_TEAM_LISTING = 'ADD_TEAM_LISTING';
export const SET_TEAM_LISTING_VALUES = 'SET_TEAM_LISTING_VALUES';
export const RESET_TEAMS_LISTING = 'RESET_TEAMS_LISTING';
export const REMOVE_TEAM_LISTING_DAY = 'REMOVE_TEAM_LISTING_DAY';

export const SET_FIRE_IDS_LIST = 'SET_FIRE_IDS_LIST';
export const ADD_FIRE_ID = 'ADD_FIRE_ID';
export const SET_FIRE_ID_VALUES = 'SET_FIRE_ID_VALUES';
export const RESET_FIRE_IDS = 'RESET_FIRE_IDS';

export const SET_DRILLHOLE_TYPES_LIST = 'SET_DRILLHOLE_TYPES_LIST';
export const ADD_DRILLHOLE_TYPE = 'ADD_DRILLHOLE_TYPE';
export const SET_DRILLHOLE_TYPE_VALUES = 'SET_DRILLHOLE_TYPE_VALUES';
export const RESET_DRILLHOLE_TYPES = 'RESET_DRILLHOLE_TYPES';

export const SET_DRILLHOLE_DEPTHS_LIST = 'SET_DRILLHOLE_DEPTHS_LIST';
export const SET_DRILLHOLE_DEPTH_VALUES = 'SET_DRILLHOLE_DEPTH_VALUES';
export const RESET_DRILLHOLE_DEPTHS = 'RESET_DRILLHOLE_DEPTHS';
export const ADD_DRILLHOLE_DEPTH = 'ADD_DRILLHOLE_DEPTH';

export const SET_CALENDAR_TURNS_TEAMS = 'SET_CALENDAR_TURNS_TEAMS';
export const RESET_CALENDAR_TURNS_TEAMS = 'RESET_CALENDAR_TURNS_TEAMS';
export const SET_CALENDAR_TURN_TEAM = 'SET_CALENDAR_TURN_TEAM';
export const SET_DAYS_OFF = 'SET_DAYS_OFF';

export const SET_ELEMENTS_OD_LIST = 'SET_ELEMENTS_OD_LIST';
export const RESET_ELEMENTS_OD = 'RESET_ELEMENTS_OD';
export const SET_ELEMENTS_OD_VALUES = 'SET_ELEMENTS_OD_VALUES';

export const SET_OPERATION_TYPES_LIST = 'SET_OPERATION_TYPES_LIST';
export const ADD_OPERATION_TYPE = 'ADD_OPERATION_TYPE';
export const SET_OPERATION_TYPE_VALUES = 'SET_OPERATION_TYPE_VALUES';
export const RESET_OPERATION_TYPES = 'RESET_OPERATION_TYPES';

export const SET_OPERATION_TYPE_ACTIVITIES_LIST = 'SET_OPERATION_TYPE_ACTIVITIES_LIST';

export const SET_TRANSPORT_OPERATION_TYPES_LIST = 'SET_TRANSPORT_OPERATION_TYPES_LIST';
export const ADD_TRANSPORT_OPERATION_TYPE = 'ADD_TRANSPORT_OPERATION_TYPE';
export const SET_TRANSPORT_OPERATION_TYPE_VALUES = 'SET_TRANSPORT_OPERATION_TYPE_VALUES';
export const RESET_TRANSPORT_OPERATION_TYPES = 'RESET_TRANSPORT_OPERATION_TYPES';

export const SET_TRACTOR_PRODUCTIVITIES_LIST = 'SET_TRACTOR_PRODUCTIVITIES_LIST';
export const ADD_TRACTOR_PRODUCTIVITY = 'ADD_TRACTOR_PRODUCTIVITY';
export const SET_TRACTOR_PRODUCTIVITY_VALUES = 'SET_TRACTOR_PRODUCTIVITY_VALUES';
export const RESET_TRACTOR_PRODUCTIVITIES = 'RESET_TRACTOR_PRODUCTIVITIES';
export const SET_EXCEPTION_TYPES_LIST = 'SET_EXCEPTION_TYPES_LIST';

export const SET_SLEEP_LEVEL_LIST = 'SET_SLEEP_LEVEL_LIST';
export const ADD_SLEEP_LEVEL = 'ADD_SLEEP_LEVEL';
export const SET_SLEEP_LEVEL_VALUES = 'SET_SLEEP_LEVEL_VALUES';
export const RESET_SLEEP_LEVELS = 'RESET_SLEEP_LEVELS';

export const SET_FANDRILL_FAN_LIST = 'SET_FANDRILL_FAN_LIST';
export const ADD_FANDRILL_FAN = 'ADD_FANDRILL_FAN';
export const SET_FANDRILL_FAN_VALUES = 'SET_FANDRILL_FAN_VALUES';
export const RESET_FANDRILL_FANS = 'RESET_FANDRILL_FANS';

export const SET_FANDRILL_BIT_DIAMETERS_LIST = 'SET_FANDRILL_BIT_DIAMETERS_LIST';
export const SET_FANDRILL_BIT_DIAMETER_VALUES = 'SET_FANDRILL_BIT_DIAMETER_VALUES';
export const RESET_FANDRILL_BIT_DIAMETERS = 'RESET_FANDRILL_BIT_DIAMETERS';
export const ADD_FANDRILL_BIT_DIAMETER = 'ADD_FANDRILL_BIT_DIAMETER';

export const SET_TABLET_CONFIGURATION_LIST = 'SET_TABLET_CONFIGURATION_LIST';
export const SET_TABLET_CONFIGURATION_VALUES = 'SET_TABLET_CONFIGURATION_VALUES';
export const RESET_TABLET_CONFIGURATIONS = 'RESET_TABLET_CONFIGURATIONS';

export const SET_PLATFORM_CONFIGURATION_LIST = 'SET_PLATFORM_CONFIGURATION_LIST';
export const SET_PLATFORM_CONFIGURATION_VALUES = 'SET_PLATFORM_CONFIGURATION_VALUES';
export const RESET_PLATFORM_CONFIGURATIONS = 'RESET_PLATFORM_CONFIGURATIONS';

export const SET_FANDRILL_BIT_DIAMETERS_NAMES_LIST = 'SET_FANDRILL_BIT_DIAMETERS_NAMES_LIST';
export const SET_FANDRILL_BIT_DIAMETER_NAME_VALUES = 'SET_FANDRILL_BIT_DIAMETER_NAME_VALUES';
export const RESET_FANDRILL_BIT_DIAMETERS_NAMES = 'RESET_FANDRILL_BIT_DIAMETERS_NAMES';
export const ADD_FANDRILL_BIT_DIAMETER_NAME = 'ADD_FANDRILL_BIT_DIAMETER_NAME';

export const SET_HOURMETER_NAMES_LIST = 'SET_HOURMETER_NAMES_LIST';
export const SET_HOURMETER_NAMES_VALUES = 'SET_HOURMETER_NAMES_VALUES';
export const RESET_HOURMETER_NAMES = 'RESET_HOURMETER_NAMES';
export const ADD_HOURMETER_NAME = 'ADD_HOURMETER_NAME';

export const SET_LOAD_FACTOR_LIST = 'SET_LOAD_FACTOR_LIST';
export const SET_LOAD_FACTOR_VALUE = 'SET_LOAD_FACTOR_VALUE';
export const RESET_LOAD_FACTOR = 'RESET_LOAD_FACTOR';
export const ADD_LOAD_FACTOR = 'ADD_LOAD_FACTOR';

export const WEB_USERS_LIST = 'WEB_USERS_LIST';
export const SET_WEB_USERS_VALUES = 'SET_WEB_USERS_VALUE';
export const RESET_WEB_USERS = 'RESET_WEB_USERS';
export const ADD_WEB_USERS = 'ADD_WEB_USERS';
export const RESET_WEB_USERS_PASSWORD = 'RESET_WEB_USERS_PASSWORD';

export const SET_GOAL_INDICATORS_LIST = 'SET_GOAL_INDICATORS_LIST';

export const SET_ORIGIN_TYPE_LIST = 'SET_ORIGIN_TYPE_LIST';


// JUMBO DOIS BRAÇOS
export const SET_BOOM_REASON_LIST = 'SET_BOOM_REASON_LIST';
export const SET_BOOM_REASON_VALUES = 'SET_BOOM_REASON_VALUES';
export const ADD_BOOM_REASON = 'ADD_BOOM_REASON';
export const RESET_BOOM_REASONS = 'RESET_BOOM_REASONS';

// Migração de códigos

export const SET_MIGRATION_RULES_LIST = 'SET_MIGRATION_RULES_LIST';
export const ADD_MIGRATION_RULE = 'ADD_MIGRATION_RULE';
export const RESET_MIGRATION_RULES = 'RESET_MIGRATION_RULES';
export const SET_MIGRATION_RULES_VALUES = 'SET_MIGRATION_RULES_VALUES';

export const SET_CODES_RAW_RULES_MATCH = 'SET_CODES_RAW_RULES_MATCH';

export const SET_CONTROL_TYPES_LIST = 'SET_CONTROL_TYPES_LIST';

export const SET_BEACON_TYPES_LIST = 'SET_BEACON_TYPES_LIST';
export const SET_BEACONS_LIST = 'SET_BEACONS_LIST';
export const SET_BEACONS_VALUES = 'SET_BEACONS_VALUES';
export const RESET_BEACONS = 'RESET_BEACONS';
export const ADD_BEACON = 'ADD_BEACON';
export const TOGGLE_LOADING_MIGRATION_CODE_MATCH = 'TOGGLE_LOADING_MIGRATION_CODE_MATCH';
