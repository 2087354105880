import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@material-ui/core';


export const ModalPropTypes = {
  modalData: PropTypes.any,
  closeModal: PropTypes.func.isRequired,
};

export const ModalDefaultProps = {
  modalData: null,
};

/**
 * Component Factory
 * it allows the component only run when modals is open even when not render
 * make it easier to manager states without worry about the modal component
 *
 * It receives the component, the modal body, needs to be forwardedRef because Modal MUI
 *
 * modalData: repass to Component any opens if it is truthy value
 * onClose: function to close the modal, also repassed to the component
 * */
// needs forwarded component
function withModal(Component) {
  // avoid error warning of material ui
  const ComponentInModal = ({ modalData, closeModal, ...props }) => (
    <Modal
      open={Boolean(modalData)}
      onClose={(_, reason) => reason === 'backdropClick' && closeModal()}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Component {...props} modalData={modalData} closeModal={closeModal} />
    </Modal>
  );

  ComponentInModal.propTypes = {
    ...ModalPropTypes,
  };

  ComponentInModal.defaultProps = {
    ...ModalDefaultProps,
  };

  return ComponentInModal;
}

export default withModal;
