/* eslint-disable */
import React, { useEffect, useState } from 'react';
import styles from '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  ConversationHeader,
  Avatar,
  Conversation,
  ConversationList,
  Search,
} from '@chatscope/chat-ui-kit-react';
import './chat.css';
import { newMessage, readChat } from '~/store/chat/actions';
import equipImagePicker from './equipImageSelector';

const messageConstructor = (message, senderName, room) => ({
  message,
  sender: {
    operator_name: senderName,
    room,
  },
});

const ChatTab = ({ selectedChatId: defaultSelectedChatId }) => {
  const [searchChat, setSearchChat] = useState('');
  const dispatch = useDispatch();
  const chatList = useSelector(state => state.chat.chatList);
  const [selectedChat, setSelectedChat] = useState(() => chatList ? defaultSelectedChatId : null);
  const connectionStatus = useSelector(state => state.chat.connectionStatus);
  const platformUser = useSelector(state => state.auth.user);
  const socket = useSelector(state => state.chat.socketConnection);

  const sendNewMessage = (message) => {
    socket.emit('platform_to_tablet_message', { message, room: selectedChat }, () => {
      const rdxMessage = newMessage(
        messageConstructor(message, platformUser.username, selectedChat),
        'sended',
      );
      dispatch(rdxMessage, 'sended');
    });
  };
  const equipmentConnectionStatus = (id) => {
    if (!chatList[id].isReaded) {
      dispatch(readChat(id));
    } 
    return connectionStatus && connectionStatus[id] ? 'available' : 'unavailable';
  };
  const searchedChats = Object.values(chatList || {})
    .filter(e => searchChat === '' || e.name.toLowerCase()
    .includes(searchChat.toLowerCase()));
  return (
    <div style={{ height: '100%', maxHeight: '800px', minHeight: '250px' }}>
      <MainContainer className="main-container">
        {!defaultSelectedChatId && (
        <ConversationList className="conversationList">
          <div style={{ width: '95%', margin: '5px' }}>
            <Search
            placeholder="Search..."
            value={searchChat}
            onChange={v => setSearchChat(v)}
            onClearClick={() => setSearchChat('')}
          />
          </div>
          {searchedChats.map(e => (
            <Conversation
              key={e.id}
              name={e.name}
              className={selectedChat && e.id === selectedChat ? 'cs-selected' : ''}
              unreadDot={!e.isReaded}
              onClick={() => {
                setSelectedChat(e.id);
                dispatch(readChat(e.id));
              }}
            >
              <Avatar
                src={equipImagePicker(e.id_equip)}
                name={e.name}
                status={connectionStatus?.[e.id] ? 'available' : 'unavailable'}
              />
            </Conversation>
          ))}
        </ConversationList>
        )}
        {chatList?.[selectedChat] && (
          <ChatContainer className={defaultSelectedChatId ? 'modal-chat' : ''}>
            <ConversationHeader className="conversation-header">
              <Avatar
                src={equipImagePicker(chatList[selectedChat].id_equip)}
                name={chatList[selectedChat].name}
                status={equipmentConnectionStatus(chatList[selectedChat].id)}
              />
              <ConversationHeader.Content userName={chatList[selectedChat].name} />
            </ConversationHeader>
            <MessageList className="message-container">
              {chatList[selectedChat] && chatList[selectedChat].messages?.map(equipChat => (
                <Message
                  key={equipChat.id}
                  model={{
                    message: `${equipChat.sender.operator_name}: ${equipChat.message}`,
                    sender: equipChat.sender.operator_name,
                    direction: equipChat.type === 'received' ? 'incoming' : 'outgoing',
                  }}
                  className={equipChat.type === 'received' ? 'message-incoming' : 'message-outgoing'}
                />
              ))}
            </MessageList>
            <MessageInput
              attachButton={false}
              placeholder={connectionStatus?.[selectedChat]
                ? 'send your message'
                : 'unavailable equipment, please connect tablet to web'
              }
              onSend={(_innerHTML, textContent) => {
                sendNewMessage(textContent);
              }}
              disabled={!connectionStatus[selectedChat]}
            />
          </ChatContainer>
        )}
      </MainContainer>
    </div>
  );
};

ChatTab.propTypes = {
  selectedChatId: PropTypes.number,
};

ChatTab.defaultProps = {
  selectedChatId: null,
};

export default ChatTab;
