import React, { useState } from 'react';
import {
  withStyles, FormControl, FormLabel, FormGroup, Typography, Fab,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CreateIcon from '@material-ui/icons/Create';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import PF2MButton from '~/components/PF2MButton';
import {
  Card, CardBody, CardHeader, CardIcon,
} from '~/components/Card';
import { updateEquipmentsSchedules } from '~/store/dispatch/actions';
import classnames from 'classnames';
import styles from '../../styles';
import withModal, { ModalDefaultProps, ModalPropTypes } from '~/components/PF2MModal/withModal';
import PF2MOutlinedInput from '~/components/PF2MOutlinedInput';
import { formatTime, newMomentDate } from '~/utils/moment';

const PerforatorAllocationModal = React.forwardRef(({ closeModal, classes }, _ref) => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const selectedPerforatorAllocation = useSelector(
    state => state.dispatch.selectedPerforatorAllocation,
  );
  const subElements = useSelector(state => state.manager.subElements);
  const elements = useSelector(state => state.manager.elements);

  const [selectedFormValues, setSelectedFormValues] = useState({ element: 0, subelement: 0, comment: '' });

  const renderHeader = () => (
    <div>
      <Typography
        variant="h4"
        align="center"
        gutterBottom
      >
        {`${translate('common:EquipmentAllocation')}: ${selectedPerforatorAllocation.equip_name}`}
      </Typography>
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridColumnGap: 10,
        gridRowGap: 10,
      }}
      >
        <div>
          <span className={classes.labelTypeSelector}>
            {`${translate('common:EquipmentType')}:`}
          </span>
          <span style={{ paddingLeft: 10, color: '#647886' }}>
            {selectedPerforatorAllocation.type_name}
          </span>
        </div>
        <div>
          <span className={classes.labelTypeSelector}>
            {`${translate('common:EquipmentGroup')}:`}
          </span>
          <span style={{ paddingLeft: 10, color: '#647886' }}>
            {selectedPerforatorAllocation.group_name}
          </span>
        </div>
        <div>
          <span className={classes.labelTypeSelector}>
            {`${translate('common:Equipment')}:`}
          </span>
          <span style={{ paddingLeft: 10, color: '#647886' }}>
            {selectedPerforatorAllocation.equip_name}
          </span>
        </div>
        <div>
          <span className={classes.labelTypeSelector}>
            {`${translate('common:Status')}:`}
          </span>
          <span style={{ paddingLeft: 10, color: '#647886' }}>
            {selectedPerforatorAllocation.active_name}
          </span>
        </div>
      </div>
    </div>
  );

  const createScheduleItem = (comment, subelement, spa) => ({
    observation: comment,
    flowchart_element_point_id: subelement,
    update_status: 'I',
    equipaments_id: spa.equip_type_id,
    group_equipament_links_id: spa.equip_id,
    code_types_id: 2,
    code_groups_id: 1,
    status_id: 1,
    codes_id: -1,
    start_date: newMomentDate(),
    start_time: formatTime(new Date()),
    duration: '00:00:00',
    delay: '00:00:00',
  });

  const onDelete = () => {
    const spa = selectedPerforatorAllocation;
    dispatch(updateEquipmentsSchedules([{
      ...createScheduleItem(spa.observation, spa.flowchart_element_point_id, spa),
      id: spa.sched_id,
      update_status: 'D',
    }], {}, true));
  };

  const onSave = () => {
    const spa = selectedPerforatorAllocation;
    dispatch(updateEquipmentsSchedules([
      createScheduleItem(selectedFormValues.comment, selectedFormValues.subelement, spa),
    ], {}, true));
    closeModal();
  };
  return (
    <div style={{ width: 600 }}>
      <Card>
        <CardHeader icon>
          <>
            <CardIcon color="warning">
              <CreateIcon />
            </CardIcon>
            <CardIcon
              onClick={closeModal}
              style={{
                float: 'right',
                cursor: 'pointer',
                background: 'darkgray',
                borderRadius: 50,
                boxShadow: '10px 10px 50px gray',
              }}
            >
              <CloseIcon />
            </CardIcon>
          </>
        </CardHeader>
        <CardBody>
          <div />
          <div>
            {renderHeader()}
            <hr />
            <div>
              <div style={{
                display: 'flex',
                marginTop: 10,
                width: '100%',
                flexDirection: 'column',
              }}
              >
                <FormLabel className={classes.formLabel}>
                  {translate('common:AllocateTo')}
                </FormLabel>
                <FormControl>
                  <div className={classes.formControl}>
                    <FormGroup>
                      <PF2MSearchSelectOutlined
                        placeholder={null}
                        className={classnames(classes.field)}
                        value={selectedFormValues.element}
                        onChange={e => setSelectedFormValues({
                          ...selectedFormValues, element: e.target.value, subelement: 0,
                        })}
                      >
                        {[{ value: 0, label: translate('common:Select') }]
                          .concat(elements
                            .map(e => ({ value: e.id, label: e.name })))}
                      </PF2MSearchSelectOutlined>
                    </FormGroup>
                    <FormGroup>
                      <PF2MSearchSelectOutlined
                        placeholder={null}
                        className={classnames(classes.field)}
                        value={selectedFormValues.subelement}
                        onChange={e => setSelectedFormValues({
                          ...selectedFormValues, subelement: e.target.value,
                        })}
                      >
                        {[{ value: 0, label: translate('common:Select') }]
                          .concat(subElements
                            .filter(e => e.id_element === selectedFormValues.element)
                            .map(e => ({ value: e.id, label: e.name })))}
                      </PF2MSearchSelectOutlined>
                    </FormGroup>
                  </div>
                  <FormGroup>
                    <FormLabel className={classes.formLabel}>
                      {`${translate('common:Comment')}:`}
                    </FormLabel>
                    <PF2MOutlinedInput
                      className={classes.textArea}
                      value={selectedFormValues.comment || ''}
                      onBlur={e => setSelectedFormValues({
                        ...selectedFormValues, comment: e.target.value,
                      })}
                      multiline
                      rows={2}
                    />
                  </FormGroup>
                </FormControl>
              </div>
              <div style={{
                display: 'flex',
                marginTop: 10,
                alignItems: 'center',
                justifyContent: 'center',
              }}
              >
                {!!selectedPerforatorAllocation.id && (
                  <PF2MButton
                    color="primary"
                    onClick={() => {
                      onDelete();
                      // dispatch(deallocateTruckAllocations(selectedPerforatorAllocation.id));
                      closeModal();
                    }}
                  >
                    <DeleteOutline className={classes.icon} />
                    {translate('common:DeleteAllocation')}
                  </PF2MButton>
                )}
                <Fab
                  variant="extended"
                  size="medium"
                  color="secondary"
                  onClick={onSave}
                  className={classes.saveButton}
                >
                  <SaveIcon className={classes.icon} />
                  {translate('common:Save')}
                </Fab>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
});

PerforatorAllocationModal.propTypes = {
  ...ModalPropTypes,
};

PerforatorAllocationModal.defaultProps = {
  ...ModalDefaultProps,
};

export const AllocationModal = withModal(withStyles(styles)(PerforatorAllocationModal));
