import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './styles';
import { DispatchStatus, DispatchDragNDropTypes, DispatchOperationType } from '~/utils';
import PF2MDispatchIconMaker from '../PF2MDispatchIconMaker';

const TRUCK = 2;

const statusClasses = {
  [DispatchStatus.OP]: 'greenLabel',
  [DispatchStatus.PO]: 'yellowLabel',
  [DispatchStatus.MA]: 'redLabel',
  [DispatchStatus.IN]: 'yellowLabel',
  [DispatchOperationType.UNLOAD_TRAFFIC]: 'blackLabel',
  [DispatchOperationType.UNLOAD_QUEUE]: 'brownLabel',
  [DispatchOperationType.UNLOAD_MANEUVER]: 'blueLabel',
  [DispatchOperationType.UNLOAD]: 'greenLabel',
  [DispatchOperationType.LOAD_TRAFFIC]: 'grayLabel',
  [DispatchOperationType.LOAD_QUEUE]: 'brownLabel',
  [DispatchOperationType.LOAD_MANEUVER]: 'blueLabel',
  [DispatchOperationType.LOAD]: 'limeLabel',
};

const statusIcons = (status) => {
  const icons = {
    [DispatchStatus.OP]: { main: 'truckFull', warning: 'none' },
    [DispatchStatus.PO]: { main: 'truckEmpty', warning: 'pause' },
    [DispatchStatus.MA]: { main: 'truckEmpty', warning: 'maintenance' },
    [DispatchStatus.IN]: { main: 'truckEmpty', warning: 'pause' },
    [DispatchOperationType.UNLOAD_TRAFFIC]: { main: 'truckFull', warning: 'none' },
    [DispatchOperationType.UNLOAD_QUEUE]: { main: 'truckFull', warning: 'time' },
    [DispatchOperationType.UNLOAD_MANEUVER]: { main: 'truckFull', warning: 'steering' },
    [DispatchOperationType.UNLOAD]: { main: 'truckUnloading', warning: 'none', isBackground: true },
    [DispatchOperationType.LOAD_TRAFFIC]: { main: 'truckEmpty', warning: 'none' },
    [DispatchOperationType.LOAD_QUEUE]: { main: 'truckEmpty', warning: 'time' },
    [DispatchOperationType.LOAD_MANEUVER]: { main: 'truckEmpty', warning: 'steering' },
    [DispatchOperationType.LOAD]: { main: 'truckEmpty', warning: 'none' },
  };
  if (icons[status] === undefined) {
    return { main: 'truckEmpty', warning: 'none' };
  }
  return icons[status];
};

const PF2MEquipIcon = ({
  item, itemType, customStyles, classes, reverse,
  useOperationType, extraInfo,
  onContextMenu,
  footer,
  header,
  height = 85,
  ...rest
}) => {
  const [, drag, preview] = useDrag({
    item: { type: itemType, obj: item },
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, [preview]);

  const statusProp = useOperationType ? 'operation_type' : 'status';

  return (
    <div
      onContextMenu={event => onContextMenu ?? onContextMenu(event, item)}
      style={{
        width: 70,
        height,
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        cursor: 'pointer',
        ...customStyles,
      }}
      role="cell"
      ref={drag}
      {...rest}
    >
      {header !== undefined ? header : (
        item.equip_type_id === TRUCK && (
          <div
            className={classes.labelTypeSelector}
            style={{
              display: 'flex', justifyContent: 'center', width: '100%', height: 20, color: '#647886',
            }}
          >
            {String(extraInfo) !== 'null' ? String(extraInfo).slice(0, 9) : '  '}
          </div>
        )
      )}
      <div style={{ position: 'relative', top: 0, left: 0 }}>
        <PF2MDispatchIconMaker
          iconName={
            {
              2: statusIcons(item[statusProp]).main,
              1: 'excavator',
              15: 'loader',
              4: 'perforator',
            }[item.equip_type_id] || 'excavator'
          }
          settings={{
            iconSize: 70,
            isBackground: statusIcons(item[statusProp]).isBackground,
            reverse,
            alt: item.equip_name,
            warnings: statusIcons(item[statusProp]).warning !== 'none' ? [{
              icon: statusIcons(item[statusProp]).warning,
              position: 'top-left',
              alt: 'Atenção',
            }] : null,
          }}
        />
      </div>
      {footer !== undefined ? footer : (
        <div
          className={cx(classes.labelTypeSelector,
            classes.greenLabel, classes[statusClasses[item[statusProp]]])}
          style={{ width: '100%', textAlign: 'center' }}
        >
          {(item.equip_name || '').slice(0, 9)}
        </div>
      )}
    </div>
  );
};

PF2MEquipIcon.propTypes = {
  classes: PropTypes.object,
  customStyles: PropTypes.object,
  itemType: PropTypes.string,
  item: PropTypes.object,
  onClick: PropTypes.func,
  status: PropTypes.string,
  reverse: PropTypes.bool,
  useOperationType: PropTypes.bool,
  extraInfo: PropTypes.string,
  onContextMenu: PropTypes.func,
  header: PropTypes.node,
  footer: PropTypes.node,
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

PF2MEquipIcon.defaultProps = {
  classes: {},
  customStyles: {},
  status: DispatchStatus.OP,
  itemType: DispatchDragNDropTypes.TRUCK,
  item: {},
  onClick: () => null,
  reverse: false,
  useOperationType: false,
  extraInfo: '',
  onContextMenu: null,
  header: undefined,
  footer: undefined,
  height: 85,
};

export default withStyles(styles)(PF2MEquipIcon);
