export const SET_FILTERS = 'SET_FILTERS';
export const SET_EQUIPMENT_STATUS_LIST = 'SET_EQUIPMENT_STATUS_LIST';
export const SET_EQUIPMENT_STATUS_ITEM = 'SET_EQUIPMENT_STATUS_ITEM';
export const SET_ORIGINAL_EQUIPMENT_STATUS_ITEM = 'SET_ORIGINAL_EQUIPMENT_STATUS_ITEM';
export const RESTORE_EQUIPMENT_STATUS_ITEM = 'RESTORE_EQUIPMENT_STATUS_ITEM';
export const SET_EQUIPMENT_STATUS_SAVED = 'SET_EQUIPMENT_STATUS_SAVED';

export const RESTORE_EQUIPMENT_STATUS_FILTER = 'RESTORE_EQUIPMENT_STATUS_FILTER';
export const SET_EQUIPMENT_STATUS_FILTER = 'SET_EQUIPMENT_STATUS_FILTER';

export const SET_INPUT_UNITS_LIST = 'SET_INPUT_UNITS_LIST';
export const SET_INPUT_UNIT_VALUES = 'SET_INPUT_UNIT_VALUES';
export const ADD_INPUT_UNIT = 'ADD_INPUT_UNIT';
export const RESET_INPUT_UNITS = 'RESET_INPUT_UNITS';


export const SET_PREVENTIVE_MAINTENANCE_TYPES_LIST = 'SET_PREVENTIVE_MAINTENANCE_TYPES_LIST';
export const SET_EQUIPMENTS_SCHEDULES_LIST = 'SET_EQUIPMENTS_SCHEDULES_LIST';
export const SET_EQUIPMENTS_SCHEDULES_VALIDATION_LIST = 'SET_EQUIPMENTS_SCHEDULES_VALIDATION_LIST';
export const SET_EQUIPMENTS_SCHEDULE_VALUE = 'SET_EQUIPMENTS_SCHEDULE_VALUE';
export const RESET_EQUIPMENTS_SCHEDULES = 'RESET_EQUIPMENTS_SCHEDULES';
export const ADD_EQUIPMENTS_SCHEDULE = 'ADD_EQUIPMENTS_SCHEDULE';
export const RESTORE_EQUIPMENT_SCHEDULES_FILTER = 'RESTORE_EQUIPMENT_SCHEDULES_FILTER';
export const SET_EQUIPMENT_SCHEDULES_FILTER = 'SET_EQUIPMENT_SCHEDULES_FILTER';
export const SET_EQUIPMENT_SCHEDULE_STATUSES_LIST = 'SET_EQUIPMENT_SCHEDULE_STATUSES_LIST';

export const SET_ANOMALY_LOGS_LIST = 'SET_ANOMALY_LOGS_LIST';
export const SET_ANOMALY_LOGS_VALUE = 'SET_ANOMALY_LOGS_VALUE';
export const RESET_ANOMALY_LOGS = 'RESET_ANOMALY_LOG';
export const RESTORE_ANOMALY_LOGS_FILTER = 'RESTORE_ANOMALY_LOGS_FILTER';
export const SET_ANOMALY_LOGS_FILTER = 'SET_ANOMALY_LOGS_FILTER';
export const SET_ANOMALY_LOGS_CODES_LIST = 'SET_ANOMALY_LOGS_CODES_LIST';
export const SET_ANOMALY_LOGS_CLOUD_LIST = 'SET_ANOMALY_LOGS_CLOUD_LIST';

export const SET_ANOMALY_TYPES_LIST = 'SET_ANOMALY_TYPES_LIST';

export const SET_LOAD_MATERIAL_MAPPINGS_LIST = 'SET_LOAD_MATERIAL_MAPPINGS_LIST';
export const SET_LOAD_MATERIAL_MAPPINGS_VALUE = 'SET_LOAD_MATERIAL_MAPPINGS_VALUE';
export const RESET_LOAD_MATERIAL_MAPPINGS = 'RESET_LOAD_MATERIAL_MAPPINGS';
export const CLEAR_LOAD_MATERIAL_MAPPINGS_LIST = 'CLEAR_LOAD_MATERIAL_MAPPINGS_LIST';
export const SET_LOAD_MATERIAL_MAPPINGS_ITEM = 'SET_LOAD_MATERIAL_MAPPINGS_ITEM';

export const SET_LOAD_ALLOCATIONS_LIST = 'SET_LOAD_ALLOCATIONS_LIST';
export const SET_TRUCK_ALLOCATIONS_LIST = 'SET_TRUCK_ALLOCATIONS_LIST';
export const SET_TRUCK_ALLOCATION = 'SET_TRUCK_ALLOCATION';
export const SET_SELECTED_TRUCK_ALLOCATION = 'SET_SELECTED_TRUCK_ALLOCATION';
export const SET_PENDING_TRUCK_ALLOCATION_UPDATE = 'SET_PENDING_TRUCK_ALLOCATION_UPDATE';
export const SET_SELECTED_LOAD_ALLOCATION = 'SET_SELECTED_LOAD_ALLOCATION';
export const SET_SELECTED_LOAD_ALLOCATION_VALUES = 'SET_SELECTED_LOAD_ALLOCATION_VALUES';

export const SET_DISPATCH_ORIGINS_TRUCKS = 'SET_DISPATCH_ORIGINS_TRUCKS';
export const SET_DISPATCH_ORIGINS_EXCAVATORS = 'SET_DISPATCH_ORIGINS_EXCAVATORS';

export const SET_DISPATCH_DESTINATION_TRUCKS = 'SET_DISPATCH_DESTINATION_TRUCKS';
export const SET_DISPATCH_DESTINATION_ELEMENTS = 'SET_DISPATCH_DESTINATION_ELEMENTS';

export const SET_SELECTED_TRUCK_ORIGIN = 'SET_SELECTED_TRUCK_ORIGIN';
export const SET_SELECTED_LOAD_ORIGIN = 'SET_SELECTED_LOAD_ORIGIN';
export const SET_SELECTED_TRUCK_DESTINATION = 'SET_SELECTED_TRUCK_DESTINATION';
export const SET_SELECTED_ELEMENT_DESTINATION = 'SET_SELECTED_ELEMENT_DESTINATION';

export const SET_AUTO_ALLOCATION_LOGS = 'SET_AUTO_ALLOCATION_LOGS';
export const ADD_DISPATCH_OPERATIONAL_CONTROL = 'ADD_DISPATCH_OPERATIONAL_CONTROL';
export const SET_DISPATCH_OPERATION_CONTROL_LIST = 'SET_DISPATCH_OPERATION_CONTROL_LIST';
export const SET_CURRENT_OPERATIONAL_CONTROL_FILTER = 'SET_CURRENT_OPERATIONAL_CONTROL_FILTER';
export const CLEAR_CURRENT_OPERATIONAL_CONTROL_FILTER = 'CLEAR_CURRENT_OPERATIONAL_CONTROL_FILTER';
export const RESTORE_DISPATCH_OPERATIONAL_CONTROL = 'RESTORE_DISPATCH_OPERATIONAL_CONTROL';
export const SET_OPERATIONAL_CONTROL_VALUES = 'SET_OPERATIONAL_CONTROL_VALUES';

export const SET_SYNC_STATUS_LIST = 'SET_SYNC_STATUS_LIST';

export const SET_DISPATCH_TRUCK_INDICATORS_LIST = 'SET_DISPATCH_TRUCK_INDICATORS_LIST';
export const SET_DISPATCH_LOADER_INDICATORS_LIST = 'SET_DISPATCH_LOADER_INDICATORS_LIST';

export const SET_DISPATCH_SOCKET_CONNECTION = 'SET_DISPATCH_SOCKET_CONNECTION';

export const SET_ALERTS_LIST = 'SET_ALERTS_LIST';

export const SET_EQUIPMENT_SCHEDULE_SUBELEMENTS_LIST = 'SET_EQUIPMENT_SCHEDULE_SUBELEMENTS_LIST';
export const SET_PERFORATOR_EQUIPMENT_SCHEDULE_LIST = 'SET_PERFORATOR_EQUIPMENT_SCHEDULE_LIST';
export const SET_SELECTED_PERFORATOR_ALLOCATION = 'SET_SELECTED_PERFORATOR_ALLOCATION';
