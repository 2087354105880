import React from 'react';
import { MTableEditField } from 'material-table';
import PropTypes from 'prop-types';

// removing bulking edit to avoid erro on console
const DefaultCell = ({ bulkEdit, ...p }) => (
  <MTableEditField
    {...p}
    onChange={e => bulkEdit(e, p)}
  />
);

DefaultCell.propTypes = {
  bulkEdit: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
};

DefaultCell.defaultProps = {
  bulkEdit: () => { },
};

export default DefaultCell;
