import React, { useState, useEffect, useMemo } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchStatus, DispatchDragNDropTypes } from '~/utils';
import { useTranslation } from 'react-i18next';
import { HelpOutline } from '@material-ui/icons';
import styles from '../styles';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import PF2MEquipmentPool from '~/components/PF2MEquipmentPool';
import PF2MTooltip from '~/components/PF2MTooltip';
import { TruckModal, ExcavatorModal } from '~/components/EquipmentStatusModals';
import {
  TruckAllocationModal,
  TruckAllocationModeModal,
  LoadAllocationModal,
} from '../../LoadSchedules/AllocationsTab/AllocationModal';
import OriginRow from './OriginRow';
import {
  getTabletConfiguration,
  getCodeTypes,
  getEquipmentTypes,
  getEquipmentsGroups,
  getElements,
  getSubElements,
} from '~/store/manager/actions';
import {
  getDispatchOriginsTrucks,
  getDispatchOriginsExcavators,
  getLoadAllocations,
  updateTruckAllocations,
  setPendingTruckAllocationUpdate,
  setSelectedTruckAllocation,
  setSelectedLoadAllocation,
  getSyncStatus,
  getEquipmentsSchedules,
  getEquipmentScheduleStatuses,
} from '~/store/dispatch/actions';
import DispatchLabels from '../DispatchLabels';
import TruckOperators from '../TruckOperatorStatus';
import ContextMenu, { useContextMenu, PF2MMenuItem } from '~/components/PF2MMenuContext';
import CollapseEquipmentList from '../CollapseEquipmentList';
import PF2MEquipmentScheduleModal from '~/components/PF2MEquipmentScheduleModal';
import { newMomentDate } from '~/utils/moment';
import { withModal } from '~/components/PF2MModal';
import PF2MWarnFeatureFlag from '~/PF2MWarnFeatureFlag';
import usePlatformConfiguration from '~/services/platformConfiguration';

// const TRUCK = 1;
const LOAD = 2;

// No meaning to have validation for one line temporary toggle feature
// eslint-disable-next-line react/destructuring-assignment, react/prop-types
const OffComp = props => <div>{props.children}</div>;

const GENERIC_LOAD_EQUIP = {
  id: null,
  equip_type_id: 1,
  equip_group_id: 0,
  equip_id: 0,
  equip_name: '',
  priority: null,
  origin_element_id: null,
  origin_element_point_id: null,
  status: 'OP',
};

const EquipmentScheduleModal = withModal(PF2MEquipmentScheduleModal);

const OriginsTab = ({ classes }) => {
  const dispatch = useDispatch();
  const [currentTruckModal, setCurrentTruckModal] = useState(null);
  const [currentExcavatorModal, setCurrentExcavatorModal] = useState(null);
  const { t: translate } = useTranslation();
  const [truckContextMenuData, openTruckContextMenu, closeTruckContextMenu] = useContextMenu();
  const [loadContextMenuData, openLoadContextMenu, closeLoadContextMenu] = useContextMenu();
  const [truckAllocationModalData, setTruckAllocationModalData] = useState();
  const [loadAllocationModalData, setLoadAllocationModalData] = useState();
  const equipmentsSchedules = useSelector(state => state.dispatch.equipmentsSchedules);
  const [equipmentScheduleModal, setEquipmentScheduleModal] = useState(false);
  const processDisptachIndicatorsTime = (
    usePlatformConfiguration('process_dispatch_indicators_time')?.value || '0'
  );

  const openTruckModal = (data) => {
    dispatch(getSyncStatus({ group_id: data.equip_group_id },
      res => setCurrentTruckModal({
        ...data,
        ...res.find(item => item.equip_id === data.equip_id),
      })));
  };

  const openExcavatorModal = (data) => {
    dispatch(getSyncStatus({ group_id: data.equip_group_id },
      res => setCurrentExcavatorModal({
        ...data,
        ...res.find(item => item.equip_id === data.equip_id),
      })));
  };

  const pendingTruckAllocationUpdate = useSelector(
    state => state.dispatch.pendingTruckAllocationUpdate,
  );
  const loadAllocations = useSelector(state => state.dispatch.loadAllocations);
  const tabletConfiguration = useSelector(state => state.manager.tabletConfiguration);
  const excavatorRows = useSelector(
    state => [
      ...state.dispatch.excavatorOrigins
        .filter(r => r.priority !== null),
      ...state.dispatch.excavatorOrigins
        .filter(r => r.priority === null && r.status === DispatchStatus.OP),
    ],
  );

  const availablePoolEquips = useSelector(
    state => [
      ...state.dispatch.truckOrigins
        .map(TruckOperators)
        .filter(st => st.isHealthy && !st.isOnCicle && !st.isOnQuickPause)
        .map(({ to }) => to),
      ...state.dispatch.excavatorOrigins
        .filter(r => !r.priority
          && (r.status === DispatchStatus.PO || r.status === DispatchStatus.IN)),
    ],
  );
  const maintenancePoolEquips = useSelector(
    state => [
      ...state.dispatch.truckOrigins
        .map(TruckOperators)
        .filter(st => !st.isHealthy)
        .map(({ to }) => to),
      ...state.dispatch.excavatorOrigins
        .filter(r => !r.priority && (r.status === DispatchStatus.MA)),
    ],
  );
  const rightLaneTrucks = useSelector(state => state.dispatch.truckOrigins
    .map(TruckOperators)
    .filter(st => st.isHealthy)
    .filter(st => st.isOnCicle || st.isOnQuickPause)
    .filter(st => st.isLoading)
    .map(({ to }) => to));

  const leftLaneTrucks = useSelector(state => state.dispatch.truckOrigins
    .map(TruckOperators)
    .filter(st => st.isHealthy)
    .filter(st => st.isOnCicle || st.isOnQuickPause)
    .filter(st => !st.isLoading)
    .map(({ to }) => to));

  const time = tabletConfiguration.find(k => k.key === 'dispatch_update_time')?.value;

  useEffect(() => {
    dispatch(getTabletConfiguration());
    dispatch(getCodeTypes());
    dispatch(getEquipmentTypes());
    dispatch(getEquipmentsGroups());
    dispatch(getElements());
    dispatch(getSubElements());
  }, [dispatch]);

  useEffect(() => {
    function fetchData() {
      dispatch(getDispatchOriginsTrucks());
      dispatch(getDispatchOriginsExcavators());
      dispatch(getLoadAllocations());
      dispatch(getEquipmentsSchedules());
      dispatch(getEquipmentScheduleStatuses());
    }
    if (time) {
      fetchData();
      const interval = setInterval(fetchData, time * 1000);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [dispatch, time]);

  const equipScheduleModalData = useMemo(() => {
    if (!equipmentsSchedules || !equipmentScheduleModal) {
      return {};
    }
    const currentEquip = equipmentsSchedules
      .find(e => (e.group_equipament_links_id === equipmentScheduleModal.equip_id));
    return currentEquip || {
      ...equipmentScheduleModal,
      equipaments_id: equipmentScheduleModal.equip_type_id,
      equipament_groups_id: equipmentScheduleModal.equip_group_id,
      group_equipament_links_id: equipmentScheduleModal.equip_id,
    };
  }, [equipmentScheduleModal, equipmentsSchedules]);

  const allocateTruck = (_e, item) => {
    dispatch(setSelectedTruckAllocation(item));
    setTruckAllocationModalData(true);
  };

  const editTruckSchedule = (_e, item) => {
    setEquipmentScheduleModal(item);
  };

  const allocateLoader = (_e, item) => {
    dispatch(setSelectedLoadAllocation(item));
    setLoadAllocationModalData(LOAD);
  };

  const editLoadSchedule = (_e, item) => {
    setEquipmentScheduleModal(item);
  };

  const renderTableHeader = () => (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: 50,
      }}
    >
      <div style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <span className={classes.labelTypeSelector}>{translate('common:Element')}</span>
      </div>
      <div style={{
        width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      />
      <div style={{
        marginLeft: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <PF2MTooltip
          title={(<DispatchLabels />)}
        >
          <HelpOutline fontSize="small" style={{ marginRight: '5px' }} />
        </PF2MTooltip>
        <span className={classes.labelTypeSelector}>{translate('common:LoadEquipment')}</span>
      </div>
    </div>
  );

  const renderGenericRow = () => {
    const workingExcavators = excavatorRows.map(r => r.equip_id);
    const leftLane = leftLaneTrucks
      .filter(r => !workingExcavators.includes(r.current_excavator_id));
    const rightLane = rightLaneTrucks
      .filter(r => !workingExcavators.includes(r.current_excavator_id));

    if (!leftLane.length && !rightLane.length) return null;
    return (
      <OriginRow
        origin={GENERIC_LOAD_EQUIP}
        leftLane={leftLane}
        rightLane={rightLane}
        isGenericRow
        loadAllocations={loadAllocations}
        openTruckModal={openTruckModal}
        openExcavatorModal={openExcavatorModal}
        onTruckContextMenu={openTruckContextMenu}
        onLoadContextMenu={openLoadContextMenu}
      />
    );
  };

  const renderRows = () => (
    <div style={{ width: '100%', maxHeight: 500 }}>
      {renderTableHeader()}
      <PF2MScrollbar style={{
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        maxHeight: 500,
        height: 'auto',
      }}
      >
        <>
          {excavatorRows
            .map(r => (
              <OriginRow
                key={r.equip_id}
                origin={r}
                leftLane={leftLaneTrucks.filter(x => x.current_excavator_id === r.equip_id)}
                rightLane={rightLaneTrucks.filter(x => x.current_excavator_id === r.equip_id)}
                loadAllocations={loadAllocations}
                onTruckDrop={(item, allocationId) => {
                  if (!item.load_allocation_id) {
                    dispatch(updateTruckAllocations(item, 'load_allocation_id', allocationId, true));
                    return;
                  }

                  const newTruckAllocation = {
                    ...item,
                    load_allocation_id: allocationId,
                  };
                  dispatch(setPendingTruckAllocationUpdate(newTruckAllocation));
                }}
                openTruckModal={openTruckModal}
                openExcavatorModal={openExcavatorModal}
                onTruckContextMenu={openTruckContextMenu}
                onLoadContextMenu={openLoadContextMenu}
              />
            ))}
          {renderGenericRow()}
        </>
      </PF2MScrollbar>
    </div>
  );

  const renderPools = () => (
    <div style={{ display: 'flex', width: '100%' }}>
      <div style={{
        display: 'flex',
        marginTop: 10,
        width: '100%',
      }}
      >
        <PF2MEquipmentPool
          equips={availablePoolEquips}
          loadAllocations={loadAllocations}
          onEquipClick={() => null}
          onItemDropped={() => null}
          headerText={translate('common:AvailableEquipments').toUpperCase()}
          truckItemType={DispatchDragNDropTypes.POOL_TRUCK}
          loadItemType={DispatchDragNDropTypes.POOL_LOAD}
          acceptDrop={false}
          onContextMenu={{
            truck: openTruckContextMenu,
            loader: openLoadContextMenu,
          }}
        />
        <PF2MEquipmentPool
          equips={maintenancePoolEquips}
          loadAllocations={loadAllocations}
          onEquipClick={() => null}
          onItemDropped={() => null}
          headerText={translate('common:MaintenanceEquipments').toUpperCase()}
          customStyles={{ marginLeft: 10 }}
          truckItemType={DispatchDragNDropTypes.POOL_TRUCK}
          loadItemType={DispatchDragNDropTypes.POOL_LOAD}
          acceptDrop={false}
          onContextMenu={{
            truck: openTruckContextMenu,
            loader: openLoadContextMenu,
          }}
        />
      </div>
    </div>
  );

  const children = (
    <div className={classes.tabContainer}>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '82vh',
        }}
      >
        {renderRows()}
        {renderPools()}
      </div>
      <TruckModal
        modalData={currentTruckModal}
        closeModal={() => setCurrentTruckModal(null)}
        classes={classes}
      />
      <ExcavatorModal
        modalData={currentExcavatorModal}
        closeModal={() => setCurrentExcavatorModal(null)}
        classes={classes}
      />
      <TruckAllocationModeModal
        isOpen={!!pendingTruckAllocationUpdate}
        refreshOrigins
        close={() => dispatch(setPendingTruckAllocationUpdate(null))}
      />
      <ContextMenu {...truckContextMenuData} close={closeTruckContextMenu}>
        <PF2MMenuItem onClick={allocateTruck}>
          {translate('common:AddEditAllocation')}
        </PF2MMenuItem>
        <PF2MMenuItem onClick={editTruckSchedule}>
          {translate('common:EditEquipmentScheduling')}
        </PF2MMenuItem>
      </ContextMenu>
      <ContextMenu {...loadContextMenuData} close={closeLoadContextMenu}>
        <PF2MMenuItem onClick={allocateLoader}>
          {translate('common:AddEditAllocation')}
        </PF2MMenuItem>
        <PF2MMenuItem onClick={editLoadSchedule}>
          {translate('common:EditEquipmentScheduling')}
        </PF2MMenuItem>
      </ContextMenu>
      <TruckAllocationModal
        modalData={truckAllocationModalData}
        closeModal={() => setTruckAllocationModalData(null)}
      />
      <LoadAllocationModal
        isOpen={loadAllocationModalData}
        close={() => setLoadAllocationModalData(null)}
      />
      {equipScheduleModalData && (
        <EquipmentScheduleModal
          modalData={equipmentScheduleModal}
          closeModal={() => setEquipmentScheduleModal(false)}
          settings={{
            skipBulkUpdate: true,
          }}
          fixed={{
            equipmentType: equipScheduleModalData.equipaments_id || 0,
            equipmentGroup: equipScheduleModalData.equipament_groups_id || 0,
            equipment: equipScheduleModalData.group_equipament_links_id || 0,
            startTime: newMomentDate(),
            duration: '00:30:00',
            element: equipScheduleModalData.flowchart_element_id || 0,
            subElement: equipScheduleModalData.flowchart_element_point_id || 0,
          }}
        />
      )}
    </div>
  );
  return (
    <PF2MWarnFeatureFlag
      flag={processDisptachIndicatorsTime >= 1}
      featureName="DispatchIndicators (process_dispatch_indicators_time >= 1)"
      OffComponent={OffComp}
      OnComponent={CollapseEquipmentList}
      props={{
        classes: { container: classes.tabContainer },
        children,
      }}
    />
  );
};

OriginsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

OriginsTab.defaultProps = {};

export default withStyles(styles)(OriginsTab);
