/* eslint-disable prefer-destructuring */
import React from 'react';
import {
  TableCell,
  TableRow,
  Fab,
  withStyles,
  TextField,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { KeyboardDatePicker } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import PF2MSearchSelect from '~/components/PF2MSearchSelect';
import PF2MMaskTextField from '~/components/PF2MMaskTextField';
import styles from '../styles';
import { formatDateToServerFormat, parseDateToServerFormat } from '~/utils/moment';

const ListRow = ({
  data,
  classes,
  onEdit,
  onDelete,
  onRestore,
  turns,
  teams,
  equipGroups,
  equipments,
  elements,
  subElements,
  filters,
  materials,
  operatorGroups,
  operators,
  exceptionTypes,
  operationTypes,
}) => {
  const { t: translate } = useTranslation();

  const renderNumberCell = (name, value = 0, item, editable = true, convertnumber = 1) => {
    const convertednumber = value / convertnumber;
    if (item.id < 1 || editable) {
      return (
        <NumberFormat
          customInput={TextField}
          value={convertednumber}
          InputProps={{ classes: { input: classes.input } }}
          onChange={e => onEdit(item, name, e.target.value * convertnumber)}
          disabled={item.update_status === 'D'}
        />
      );
    }
    return convertednumber > 0 ? Number(convertednumber).toFixed(2) : convertednumber;
  };

  // handle clearing outside if value can be changed outside of the component
  const handleMask = value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []);

  const renderDatePicker = (d, key, isRequired = true) => (
    <KeyboardDatePicker
      clearable
      mask={handleMask}
      format={translate('date:DateFormat')}
      disableFuture
      autoOk
      onChange={e => onEdit(d, key, e ? formatDateToServerFormat(e) : null)}
      value={d[key] ? parseDateToServerFormat(d[key]) : ''}
      invalidDateMessage={isRequired ? translate('validation:InvalidDate') : ''}
    />
  );

  const renderTime = (d, attr) => (
    <PF2MMaskTextField
      mask="99:99:99"
      value={d[attr]}
      onChange={e => onEdit(d, attr, e.target.value)}
    />
  );

  const renderExceptionType = (idExceptionType) => {
    const { name = '' } = exceptionTypes.find(e => e.id === idExceptionType) || {};
    return name;
  };

  const renderShaftTableRow = () => (
    <TableRow>
      <TableCell className={classes.tableCell}>
        {renderDatePicker(data, 'start_date')}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderDatePicker(data, 'end_date')}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderTime(data, 'start_time')}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderTime(data, 'end_time')}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('duration', data.duration, data, false)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.turn_id}
          onChange={e => onEdit(data, 'turn_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {turns
            .map(e => ({ value: e.id, label: `${e.turn_group_name} - ${e.name}` }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.team_id}
          onChange={e => onEdit(data, 'team_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {teams
            .map(e => ({ value: e.id, label: `${e.team_group_name} - ${e.name}` }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.id_operation_type}
          onChange={e => onEdit(data, 'id_operation_type', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {operationTypes
            .filter(r => r.id_equipment_type === filters.equipmentType)
            .map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.operator_group_id}
          onChange={e => onEdit(data, 'operator_group_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {operatorGroups
            .map(e => ({ label: e.name, value: e.id }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.operator_id}
          onChange={e => onEdit(data, 'operator_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {operators
            .filter(o => data.operator_group_id === 0
              || o.id_group === data.operator_group_id)
            .map(e => ({ value: e.id_operator, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.material_id}
          onChange={e => onEdit(data, 'material_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {materials
            .map(e => ({ label: e.name, value: e.id }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.equipament_groups_id}
          onChange={e => onEdit(data, 'equipament_groups_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {equipGroups
            .map(e => ({ label: e.name, value: e.id }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.group_equipament_links_id}
          onChange={e => onEdit(data, 'group_equipament_links_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {equipments
            .filter(d => data.equipament_groups_id === 0
              || d.id_group === data.equipament_groups_id)
            .map(e => ({ label: e.name, value: e.id }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.id_mine}
          onChange={e => onEdit(data, 'id_mine', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {elements
            .sort((i, k) => (i.name < k.name ? 1 : -1)) // alphanumeric sorting
            .sort(i => i.active * -1) // move inactive items to the end of the array
            .map(e => ({
              value: e.id,
              label: `${e.name} ${!e.active ? `- ${translate('common:Inactive')}` : ''}`,
            }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.id_mine_origin}
          onChange={e => onEdit(data, 'id_mine_origin', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {subElements
            .filter(d => data.id_mine === 0 || d.id_element === data.id_mine)
            .sort((i, k) => (i.name < k.name ? 1 : -1)) // alphanumeric sorting
            .sort(i => i.active * -1) // move inactive items to the end of the array
            .map(e => ({
              value: e.id,
              label: `${e.name} ${!e.active ? `- ${translate('common:Inactive')}` : ''}`,
            }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('load_manager', data.load_manager, data)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('load_time', data.load_time, data, true, 60)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('idle_time', data.idle_time, data, true, 60)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('code_time', data.code_time, data, true, 60)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderExceptionType(data.exception_type)}
      </TableCell>
      <TableCell style={{
        position: 'sticky',
        right: 0,
        height: 48,
      }}
      >
        {data.isDirty ? (
          <Fab
            size="small"
            color="primary"
            aria-label="Restore"
            onClick={() => onRestore(data)}
            disabled={!onRestore}
          >
            <RestoreIcon />
          </Fab>
        ) : (
          <Fab
            size="small"
            color="primary"
            aria-label="Delete"
            onClick={() => onDelete(data)}
            disabled={!onDelete}
          >
            <DeleteIcon />
          </Fab>
        )}
      </TableCell>
    </TableRow>
  );

  return renderShaftTableRow();
};

ListRow.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
  filters: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onRestore: PropTypes.func,
  turns: PropTypes.array,
  teams: PropTypes.array,
  equipGroups: PropTypes.array,
  equipments: PropTypes.array,
  elements: PropTypes.array,
  subElements: PropTypes.array,
  materials: PropTypes.array,
  operatorGroups: PropTypes.array,
  operators: PropTypes.array,
  exceptionTypes: PropTypes.array,
  operationTypes: PropTypes.array,
};

ListRow.defaultProps = {
  data: {},
  classes: {},
  filters: {},
  onEdit: null,
  onDelete: null,
  onRestore: null,
  turns: [],
  teams: [],
  operatorGroups: [],
  operators: [],
  equipGroups: [],
  equipments: [],
  elements: [],
  subElements: [],
  materials: [],
  exceptionTypes: [],
  operationTypes: [],
};

export default withStyles(styles)(ListRow);
