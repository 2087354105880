/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
import * as types from './types';
import * as errorTypes from '../errors/types';
import api from '~/services/api';
import { logoutUser } from '../auth/actions';
import {
  formatTime,
  newMomentDate,
  formatDateToServerFormat,
  formatDate,
} from '~/utils/moment';
import { downloadAxios } from '~/utils';
import i18n from '~/i18n';
import { getLoads, getOriginTypes } from '../manager/actions';

const endpoints = {
  transport: {
    url: 'get_transport_list',
    responseObject: 'transport_reports',
    typeAction: types.SET_TRANSPORT_LIST,
  },
  hourmeter: {
    url: 'get_hourmeter_list',
    responseObject: 'hourmeter_reports',
    typeAction: types.SET_HOURMETER_LIST,
  },
  code: {
    url: 'get_code_list',
    responseObject: 'code_reports',
    typeAction: types.SET_CODE_LIST,
  },
  hour_machine: {
    url: 'get_hour_machine_list',
    responseObject: 'hour_machine',
    typeAction: types.SET_HOUR_MACHINE_LIST,
  },
  load: {
    url: 'get_load_list',
    responseObject: 'load_reports',
    typeAction: types.SET_LOAD_LIST,
  },
  railroad: {
    url: 'get_railroad_list',
    responseObject: 'railroad_reports',
    typeAction: types.SET_RAILROAD_LIST,
  },
  perforator: {
    url: 'get_perforator_list',
    responseObject: 'perforator_reports',
    typeAction: types.SET_PERFORATOR_LIST,
  },
  refueler: {
    url: 'get_refueler_list',
    responseObject: 'refueler_reports',
    typeAction: types.SET_REFUELER_LIST,
  },
  tractor: {
    url: 'get_tractor_list',
    responseObject: 'tractor_reports',
    typeAction: types.SET_TRACTOR_LIST,
  },
  infrastructure: {
    url: 'get_infrastructure_list',
    responseObject: 'infrastructure_reports',
    typeAction: types.SET_INFRASTRUCTURE_LIST,
  },
  shaft: {
    url: 'get_shaft_list',
    responseObject: 'shaft_reports',
    typeAction: types.SET_SHAFT_LIST,
  },
  fillin: {
    url: 'get_fillin_list',
    responseObject: 'fillin_reports',
    typeAction: types.SET_FILLIN_LIST,
  },
  scissor_lift: {
    url: 'get_scissor_lift_list',
    responseObject: 'scissor_lift_reports',
    typeAction: types.SET_SCISSOR_LIFT_LIST,
  },
  codes_raw: {
    url: 'get_codes_raw_list',
    responseObject: 'codes_raw_reports',
    typeAction: types.SET_CODES_RAW_LIST,
  },
  activity: {
    url: 'get_activity_list',
    responseObject: 'activity_reports',
    typeAction: types.SET_ACTIVITY_LIST,
  },
  crusher: {
    url: 'get_crusher_list',
    responseObject: 'crusher_reports',
    typeAction: types.SET_CRUSHER_LIST,
  },
};

const cleanEmptyValues = (parameters) => {
  Object.keys(parameters).forEach((propName) => {
    if (parameters[propName] === null
      || parameters[propName] === undefined
      || parameters[propName].length <= 0) {
      delete parameters[propName];
    }
  });
};

const cleanZeroValues = (parameters) => {
  Object.keys(parameters).forEach((propName) => {
    if (parameters[propName] === 0
      || parameters[propName] === '0') {
      delete parameters[propName];
    }
  });
};

export const setIsLoadingList = loading => ({
  type: types.SET_IS_LOADING_LIST,
  payload: loading,
});

const handleErrors = response => async (dispatch) => {
  if (response && response.status === 401) {
    dispatch(logoutUser());
  } else if (response && response.data) {
    dispatch({
      type: errorTypes.GET_ERRORS,
      payload: response.data.errors,
    });
  } else {
    dispatch({
      type: errorTypes.GET_GENERIC_ERROR,
      payload: i18n.t('common:ServerError'),
    });
  }
};

const handleParameters = (parameters) => {
  cleanEmptyValues(parameters);
  return {
    start_date: parameters.startDate.format('DD/MM/YYYY'),
    end_date: parameters.endDate.format('DD/MM/YYYY'),
    turn: parameters.turn || null,
    id_material_truck: parameters.material || null,
    id_destination_point: parameters.endOrigin || null,
    id_mine_origin: parameters.startOrigin || null,
    id_sub_origin: parameters.startSubOrigin || null,
    id_sub_destination: parameters.endSubOrigin || null,
    id_operator_truck_group: parameters.operatorGroup || null,
    id_operator_truck: parameters.operator || null,
    id_equipment: parameters.equipmentType || null,
    id_equipment_group: parameters.equipmentGroup || null,
    id_truck: parameters.equipment || null,
  };
};

const handleCodeParameters = (parameters) => {
  cleanEmptyValues(parameters);
  const {
    equipmentGroup,
    equipmentType,
    equipment,
    operatorGroup,
    operator,
    codeType,
    codeGroup,
    code,
  } = parameters;
  const newCodeType = typeof codeType === 'string' ? parseInt(codeType, 10) : null;
  const newCodeGroup = typeof codeGroup === 'string' ? parseInt(codeGroup, 10) : null;
  return {
    start_date: parameters.startDate.format('DD/MM/YYYY'),
    end_date: parameters.endDate.format('DD/MM/YYYY'),
    turn: parameters.turn || null,
    id_material_truck: parameters.material || null,
    id_destination_point: parameters.endOrigin || null,
    id_sub_origin: parameters.startSubOrigin || null,
    id_sub_destination: parameters.endSubOrigin || null,
    element_id: parameters.startOrigin || null,
    id_operator_group: operatorGroup || null,
    id_operator: operator || null,
    id_equipment_group: equipmentGroup || null,
    equip_type: equipmentType || null,
    id_equipment: equipment || null,
    code_type: newCodeType || null,
    code_group: newCodeGroup || null,
    code_id: code || null,
  };
};

const handleHourmeterParameters = (parameters) => {
  cleanEmptyValues(parameters);
  return {
    start_date: parameters.startDate.format('DD/MM/YYYY'),
    end_date: parameters.endDate.format('DD/MM/YYYY'),
    turn: parameters.turn || null,
    id_material_truck: parameters.material || null,
    id_destination_point: parameters.endOrigin || null,
    id_mine_origin: parameters.startOrigin || null,
    id_sub_origin: parameters.startSubOrigin || null,
    id_sub_destination: parameters.endSubOrigin || null,
    operator_id: parameters.operator || null,
    operator_group: parameters.operatorGroup || null,
    equip_group: parameters.equipmentGroup || null,
    equip_type: parameters.equipmentType || null,
    equip_id: parameters.equipment || null,
  };
};

const handleHourMachineParameters = (parameters) => {
  cleanEmptyValues(parameters);
  return {
    start_date: parameters.startDate.format('DD/MM/YYYY'),
    end_date: parameters.endDate.format('DD/MM/YYYY'),
    turn: parameters.turn || null,
    id_sub_origin: parameters.startSubOrigin || null,
    id_sub_destination: parameters.endSubOrigin || null,
    operator_id: parameters.operator || null,
    operator_group: parameters.operatorGroup || null,
    equip_group: parameters.equipmentGroup || null,
    equip_type: parameters.equipmentType || null,
    equip_id: parameters.equipment || null,
    code_id: parameters.code || null,
  };
};


const handleLoadParameters = (parameters) => {
  cleanEmptyValues(parameters);
  const {
    equipmentGroup,
    equipment,
    operatorGroup,
    operator,
    equipmentType,
  } = parameters;

  return {
    start_date: parameters.startDate.format('DD/MM/YYYY'),
    end_date: parameters.endDate.format('DD/MM/YYYY'),
    turn: parameters.turn || null,
    id_material_truck: parameters.material || null,
    id_destination_point: parameters.endOrigin || null,
    id_mine_origin: parameters.startOrigin || null,
    id_sub_origin: parameters.startSubOrigin || null,
    id_sub_destination: parameters.endSubOrigin || null,
    id_equipment_group: equipmentGroup || null, // grupo de equipamento
    equip_type: equipmentType || null,
    id_excavator: equipment || null, // equipamento
    id_group_excavator_operator: operatorGroup || null, // grupo de operador
    id_excavator_operator: operator || null, // operador
  };
};

const handleRailroadParameters = (parameters) => {
  cleanEmptyValues(parameters);
  cleanZeroValues(parameters);
  const {
    startDate,
    endDate,
    turn: id_turn,
    equipmentType: equip_type,
    equipmentGroup: id_equipment_group,
    equipment: id_equip,
    material: id_material,
    startOrigin: id_origin,
    startSubOrigin: id_origin_point,
    endOrigin: id_destination,
    endSubOrigin: id_destination_point,
    operatorGroup: id_operator_group,
    operator: id_operator,
  } = parameters;
  const start_date = startDate.format('DD/MM/YYYY');
  const end_date = endDate.format('DD/MM/YYYY');

  return {
    start_date,
    end_date,
    id_turn,
    equip_type,
    id_equipment_group,
    id_equip,
    id_material,
    id_origin,
    id_origin_point,
    id_destination,
    id_destination_point,
    id_operator_group,
    id_operator,
  };
};

const handlePerforatorParameters = (parameters) => {
  cleanEmptyValues(parameters);
  return {
    start_date: parameters.startDate.format('DD/MM/YYYY'),
    end_date: parameters.endDate.format('DD/MM/YYYY'),
    turn: parameters.turn || null,
    id_material_truck: parameters.material || null,
    id_destination_point: parameters.endOrigin || null,
    id_mine_origin: parameters.startOrigin || null,
    id_sub_origin: parameters.startSubOrigin || null,
    id_sub_destination: parameters.endSubOrigin || null,
    equip_type_id: parameters.equipmentType || null,
    equip_group_id: parameters.equipmentGroup || null,
    equip_id: parameters.equipment || null,
    operator_group_id: parameters.operatorGroup || null,
    operator_id: parameters.operator || null,
  };
};

const handleRefuelerParameters = (parameters) => {
  cleanEmptyValues(parameters);
  return {
    start_date: parameters.startDate.format('DD/MM/YYYY'),
    end_date: parameters.endDate.format('DD/MM/YYYY'),
    turn_end: parameters.turn || null,
    operator_group_id: parameters.operatorGroup || null,
    operator_id: parameters.operator || null,
    equip_group_id: parameters.equipmentGroup || null,
    equip_type_fueled: parameters.equipmentType || null,
    equip_id: parameters.equipment || null,
    id_sub_origin: parameters.startSubOrigin || null,
    id_sub_destination: parameters.endSubOrigin || null,
    element_id_start: null,
    element_point_id_start: null,
    element_id_end: null,
    element_point_id_end: null,
  };
};

const handleMaintenanceParameters = (parameters) => {
  cleanEmptyValues(parameters);
  return {
    start_date: parameters.startDate.format('DD/MM/YYYY'),
    end_date: parameters.endDate.format('DD/MM/YYYY'),
    start_time: null,
    end_time: null,
    turn_group_id: parameters.turnGroup ? parameters.turnGroup.id : null,
    turn_id: parameters.turn ? parameters.turn.id : null,
    equipaments_id: parameters.equipmentType ? parameters.equipmentType.id : null,
    equipament_groups_id: parameters.equipmentGroup ? parameters.equipmentGroup.id : null,
    group_equipament_links_id: parameters.equipment ? parameters.equipment.id : null,
    operators_groups_id: parameters.operatorGroup ? parameters.operatorGroup.id : null,
    operator_id: parameters.operator ? Number(parameters.operator.id_operator) : null,
    code_type_maintenance_id: parameters.codeType ? parameters.codeType.id : null,
    code_group_maintenance_id: parameters.codeGroup ? parameters.codeGroup.id : null,
    code_maintenance_id: parameters.code ? parameters.code.id : null,
    hide_interrupted_or_deleted: parameters.hideInterruptedOrDeleted,
  };
};

const handleInfrastructureParameters = (parameters) => {
  cleanEmptyValues(parameters);
  return {
    start_date: parameters.startDate.format('DD/MM/YYYY'),
    end_date: parameters.endDate.format('DD/MM/YYYY'),
    turn: parameters.turn || null,
    material_id: parameters.material || null,
    id_mine_origin: parameters.startOrigin || null,
    id_destination_point: parameters.endOrigin || null,
    id_sub_origin: parameters.startSubOrigin || null,
    id_sub_destination: parameters.endSubOrigin || null,
    equip_type_id: parameters.equipmentType || null,
    equip_group_id: parameters.equipmentGroup || null,
    equip_id: parameters.equipment || null,
    operator_group_id: parameters.operatorGroup || null,
    operator_id: parameters.operator || null,
  };
};

const handleShaftParameters = (parameters) => {
  cleanEmptyValues(parameters);
  return {
    start_date: parameters.startDate.format('DD/MM/YYYY'),
    end_date: parameters.endDate.format('DD/MM/YYYY'),
    turn: parameters.turn || null,
    material_id: parameters.material || null,
    id_mine_origin: parameters.startOrigin || null,
    id_destination_point: parameters.endOrigin || null,
    id_sub_origin: parameters.startSubOrigin || null,
    id_sub_destination: parameters.endSubOrigin || null,
    equip_type_id: parameters.equipmentType || null,
    equip_group_id: parameters.equipmentGroup || null,
    equip_id: parameters.equipment || null,
    operator_group_id: parameters.operatorGroup || null,
    operator_id: parameters.operator || null,
  };
};
const handleFillinParameters = (parameters) => {
  cleanEmptyValues(parameters);
  return {
    start_date: parameters.startDate.format('DD/MM/YYYY'),
    end_date: parameters.endDate.format('DD/MM/YYYY'),
    turn_id: parameters.turn_id || null,
    material_id: parameters.material || null,
    id_mine_origin: parameters.startOrigin || null,
    id_destination_point: parameters.endOrigin || null,
    id_sub_origin: parameters.startSubOrigin || null,
    id_sub_destination: parameters.endSubOrigin || null,
    equip_type_id: parameters.equipmentType || null,
    equip_group_id: parameters.equipmentGroup || null,
    equip_id: parameters.equipment || null,
    operator_group_id: parameters.operatorGroup || null,
    operator_id: parameters.operator || null,
  };
};

const handleScissorLiftParameters = (parameters) => {
  cleanEmptyValues(parameters);
  return {
    start_date: parameters.startDate.format('DD/MM/YYYY'),
    end_date: parameters.endDate.format('DD/MM/YYYY'),
    turn: parameters.turn || null,
    equip_group_id: parameters.equipmentGroup || null,
    equip_id: parameters.equipment || null,
    operator_group_id: parameters.operatorGroup || null,
    operator_id: parameters.operator || null,
    operation_type_id: parameters.operationType || null,
    operation_activity_id: parameters.operationActivity || null,
    id_sub_origin: parameters.startSubOrigin || null,
    id_sub_destination: parameters.endSubOrigin || null,
  };
};

const handleActivityListParameters = (parameters) => {
  cleanEmptyValues(parameters);
  return {
    start_date: parameters.startDate.format('DD/MM/YYYY'),
    end_date: parameters.endDate.format('DD/MM/YYYY'),
    equip_type_id: parameters.equipmentType || null,
    equip_group_id: parameters.equipmentGroup || null,
    equip_id: parameters.equipment || null,
    operator_group_id: parameters.operatorGroup || null,
    operator_id: parameters.operator || null,
    area_id: parameters.startSubOrigin || null,
    subarea_id: parameters.endSubOrigin || null,
    code_type: parameters.codeType || null,
    code_group: parameters.codeGroup || null,
    code_id: parameters.code || null,
  };
};

const searchList = (parameters, page, limit, orderBy, reorder, type) => async (dispatch) => {
  dispatch(setIsLoadingList(true));
  try {
    const typeEndpoint = endpoints[type];
    let searchParameters = {};

    const dailyTypes = {
      hourmeter: p => handleHourmeterParameters(p),
      hour_machine: p => handleHourMachineParameters(p),
      code: p => handleCodeParameters(p),
      codes_raw: p => handleCodeParameters(p),
      load: p => handleLoadParameters(p),
      railroad: p => handleRailroadParameters(p),
      perforator: p => handlePerforatorParameters(p),
      refueler: p => handleRefuelerParameters(p),
      infrastructure: p => handleInfrastructureParameters(p),
      shaft: p => handleShaftParameters(p),
      fillin: p => handleFillinParameters(p),
      scissor_lift: p => handleScissorLiftParameters(p),
      activity: p => handleActivityListParameters(p),
    };

    searchParameters = (Object.hasOwn(dailyTypes, type)
      ? dailyTypes[type](parameters)
      : handleParameters(parameters));

    searchParameters.hide_interrupted_or_deleted = parameters.hideInterruptedOrDeleted;

    const body = {
      ...searchParameters,
      page,
      results_per_page: limit,
      order_by: orderBy,
    };
    const {
      data: {
        result: {
          [typeEndpoint.responseObject]: data = [],
          total_records: totalRecords,
        },
      },
    } = await api.post(`/partes_diarias/${typeEndpoint.url}`, body);
    const keySortBy = `${type}SortBy`;
    dispatch({
      type: typeEndpoint.typeAction,
      payload: {
        page,
        limit,
        reorder,
        totalRecords,
        data,
        [keySortBy]: orderBy,
      },
    });
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
  dispatch(setIsLoadingList(false));
};

const setValues = (item, key, value, type) => {
  const setTypes = {
    hourmeter: types.SET_HOURMETER_VALUES,
    code: types.SET_CODE_VALUES,
    codes_raw: types.SET_CODES_RAW_VALUES,
    hour_machine: types.SET_HOUR_MACHINE_VALUES,
    load: types.SET_LOAD_VALUES,
    railroad: types.SET_RAILROAD_VALUES,
    perforator: types.SET_PERFORATOR_VALUES,
    refueler: types.SET_REFUELER_VALUES,
    underground: types.SET_UNDERGROUND_VALUES,
    tractor: types.SET_TRACTOR_VALUES,
    maintenance: types.SET_MAINTENANCE_VALUES,
    infrastructure: types.SET_INFRASTRUCTURE_VALUES,
    shaft: types.SET_SHAFT_VALUES,
    fillin: types.SET_FILLIN_VALUES,
    scissor_lift: types.SET_SCISSOR_LIFT_VALUES,
    activity: types.SET_ACTIVITY_VALUES,
    crusher: types.SET_CRUSHER_VALUES,
  };

  const setType = (Object.hasOwn(setTypes, type)
    ? setTypes[type]
    : types.SET_TRANSPORT_VALUES);

  const updateStatus = item.update_status === 'I' ? 'I' : 'U';

  return {
    type: setType,
    payload: {
      ...item,
      [key]: value,
      update_status: updateStatus,
    },
  };
};

const setAllValues = (newItem, type) => {
  const setTypes = {
    hourmeter: types.SET_HOURMETER_VALUES,
    code: types.SET_CODE_VALUES,
    codes_raw: types.SET_CODES_RAW_VALUES,
    hour_machine: types.SET_HOUR_MACHINE_VALUES,
    load: types.SET_LOAD_VALUES,
    railroad: types.SET_RAILROAD_VALUES,
    perforator: types.SET_PERFORATOR_VALUES,
    refueler: types.SET_REFUELER_VALUES,
    underground: types.SET_UNDERGROUND_VALUES,
    tractor: types.SET_TRACTOR_VALUES,
    maintenance: types.SET_MAINTENANCE_VALUES,
    infrastructure: types.SET_INFRASTRUCTURE_VALUES,
    shaft: types.SET_SHAFT_VALUES,
    fillin: types.SET_FILLIN_VALUES,
    scissor_lift: types.SET_SCISSOR_LIFT_VALUES,
  };

  const setType = (Object.hasOwn(setTypes, type)
    ? setTypes[type]
    : types.SET_TRANSPORT_VALUES);

  const updateStatus = newItem.update_status === 'I' ? 'I' : 'U';

  return {
    type: setType,
    payload: {
      ...newItem,
      update_status: updateStatus,
    },
  };
};

const deleteItem = (item, type) => {
  let typeAction = '';
  let itemKey = 'id';
  switch (type) {
    case 'hourmeter':
      typeAction = types.DELETE_HOURMETER_ITEM;
      itemKey = 'id_line';
      break;
    case 'code':
      typeAction = types.DELETE_CODE_ITEM;
      break;
    case 'codes_raw':
      typeAction = types.DELETE_CODES_RAW_ITEM;
      break;
    case 'hour_machine':
      typeAction = types.DELETE_HOUR_MACHINE_ITEM;
      break;
    case 'load':
      typeAction = types.DELETE_LOAD_ITEM;
      break;
    case 'railroad':
      typeAction = types.DELETE_RAILROAD_ITEM;
      break;
    case 'perforator':
      typeAction = types.DELETE_PERFORATOR_ITEM;
      break;
    case 'refueler':
      typeAction = types.DELETE_REFUELER_ITEM;
      break;
    case 'underground':
      typeAction = types.DELETE_UNDERGROUND_ITEM;
      break;
    case 'tractor':
      typeAction = types.DELETE_TRACTOR_ITEM;
      break;
    case 'maintenance':
      typeAction = types.DELETE_MAINTENANCE_ITEM;
      break;
    case 'infrastructure':
      typeAction = types.DELETE_INFRASTRUCTURE_ITEM;
      break;
    case 'shaft':
      typeAction = types.DELETE_SHAFT_ITEM;
      break;
    case 'fillin':
      typeAction = types.DELETE_FILLIN_ITEM;
      break;
    case 'scissor_lift':
      typeAction = types.DELETE_SCISSOR_LIFT_ITEM;
      break;
    case 'activity':
      typeAction = types.DELETE_ACTIVITY_ITEM;
      break;
    case 'crusher':
      typeAction = types.DELETE_CRUSHER_ITEM;
      break;
    default:
      typeAction = types.DELETE_TRANSPORT_ITEM;
      break;
  }

  if (item[itemKey] < 1) {
    return {
      type: typeAction,
      payload: {
        ...item,
      },
    };
  }

  return {
    type: typeAction,
    payload: {
      ...item,
      isDirty: true,
      update_status: 'D',
    },
  };
};

export const searchCrusherList = (parameters, page = 1, limit = 30, orderBy = { field: 'start_timestamp', order: 'asc' }, reorder = true) => async (dispatch) => {
  dispatch(searchList(parameters, page, limit, orderBy, reorder, 'crusher'));
};

export const searchTransportList = (parameters, page = 1, limit = 30, orderBy = { field: 'start_timestamp', order: 'asc' }, reorder = true) => async (dispatch) => {
  dispatch(searchList(parameters, page, limit, orderBy, reorder, 'transport'));
};

export const searchHourmeterList = (parameters, page = 1, limit = 30, orderBy = { field: 'timestamp_op', order: 'asc' }, reorder = true) => async (dispatch) => {
  dispatch(searchList(parameters, page, limit, orderBy, reorder, 'hourmeter'));
};

export const searchCodeList = (parameters, page = 1, limit = 30, orderBy = { field: 'start_timestamp', order: 'asc' }, reorder = true) => async (dispatch) => {
  dispatch(searchList(parameters, page, limit, orderBy, reorder, 'code'));
};

export const searchCodeRawList = (parameters, page = 1, limit = 30, orderBy = { field: 'start_timestamp', order: 'asc' }, reorder = true) => async (dispatch) => {
  dispatch(searchList(parameters, page, limit, orderBy, reorder, 'codes_raw'));
};
export const searchActivityList = (parameters, page = 1, limit = 30, orderBy = { field: 'timestamp_op', order: 'asc' }, reorder = true) => async (dispatch) => {
  dispatch(searchList(parameters, page, limit, orderBy, reorder, 'activity'));
};

export const searchHourMachineList = (parameters, page = 1, limit = 30, orderBy = { field: 'timestamp_op', order: 'asc' }, reorder = true) => async (dispatch) => {
  dispatch(searchList(parameters, page, limit, orderBy, reorder, 'hour_machine'));
};

export const searchLoadList = (parameters, page = 1, limit = 30, orderBy = { field: 'start_timestamp', order: 'asc' }, reorder = true) => async (dispatch) => {
  dispatch(searchList(parameters, page, limit, orderBy, reorder, 'load'));
};

export const searchRailroadList = (parameters, page = 0, limit = 30, orderBy = { field: 'start_timestamp', order: 'asc' }, reorder = true) => async (dispatch) => {
  await dispatch(searchList(parameters, page, limit, orderBy, reorder, 'railroad'));
};

export const searchPerforatorList = (parameters, page = 1, limit = 30, orderBy = { field: 'start_time', order: 'asc' }, reorder = true) => async (dispatch) => {
  dispatch(searchList(parameters, page, limit, orderBy, reorder, 'perforator'));
};

export const searchRefuelerList = (parameters, page = 1, limit = 30, orderBy = { field: 'start_time', order: 'asc' }, reorder = true) => async (dispatch) => {
  dispatch(searchList(parameters, page, limit, orderBy, reorder, 'refueler'));
};

export const searchTractorList = (parameters, page = 1, limit = 30, orderBy = { field: 'start_timestamp', order: 'asc' }, reorder = true) => async (dispatch) => {
  dispatch(searchList(parameters, page, limit, orderBy, reorder, 'tractor'));
};

export const searchInfrastructureList = (parameters, page = 1, limit = 30, orderBy = { field: 'start_timestamp', order: 'asc' }, reorder = true) => async (dispatch) => {
  dispatch(searchList(parameters, page, limit, orderBy, reorder, 'infrastructure'));
};

export const searchShaftList = (parameters, page = 1, limit = 30, orderBy = { field: 'start_timestamp', order: 'asc' }, reorder = true) => async (dispatch) => {
  dispatch(searchList(parameters, page, limit, orderBy, reorder, 'shaft'));
};

export const searchFillinList = (parameters, page = 1, limit = 30, orderBy = { field: 'start_timestamp', order: 'asc' }, reorder = true) => async (dispatch) => {
  dispatch(searchList(parameters, page, limit, orderBy, reorder, 'fillin'));
};

export const searchScissorLiftList = (parameters, page = 1, limit = 30, orderBy = { field: 'start_timestamp', order: 'asc' }, reorder = true) => async (dispatch) => {
  dispatch(searchList(parameters, page, limit, orderBy, reorder, 'scissor_lift'));
};
export const setTransportValues = (item, key, value) => setValues(item, key, value, 'transport');
export const setHourmeterValues = (item, key, value) => setValues(item, key, value, 'hourmeter');
export const setCodeValues = (item, key, value) => setValues(item, key, value, 'code');
export const getActivityListPictureURL = pictureName => (
  `${api.defaults.baseURL}/partes_diarias/activity_list_picture/${pictureName}`
);
export const setActivityValues = (item, key, value) => setValues(item, key, value, 'activity');
const activityUploadLoad = payload => ({ type: types.SET_ACTIVITY_UPLOAD_LOADING, payload });
export const uploadActivityPicture = ({ picture: file, ...activity }, onUploadProgress) => (
  async (dispatch) => {
    dispatch(activityUploadLoad(true));
    const formData = new FormData();
    formData.append('file', file);
    try {
      const { data } = await api.post(
        '/partes_diarias/activity_list_picture',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress },
      );
      dispatch({
        type: errorTypes.SET_UPLOAD_SUCCESS,
        payload: `${i18n.t('validation:SavedSuccess')}`,
      });
      dispatch(setActivityValues({ ...activity, picture_name: data.result.picture_name }));
    } catch (err) {
      dispatch({
        type: errorTypes.GET_GENERIC_ERROR,
        payload: i18n.t('common:ServerError'),
      });
    } finally {
      dispatch(activityUploadLoad(false));
    }
  }
);
export const setCodesRawValues = (item, key, value) => setValues(item, key, value, 'codes_raw');
export const setHourMachineValues = (item, key, value) => setValues(item, key, value, 'hour_machine');
export const setLoadValues = (item, key, value) => setValues(item, key, value, 'load');
export const setRailroadValues = (item, key, value) => setValues(item, key, value, 'railroad');
export const setPerforatorValues = (item, key, value, filters) => {
  const newItem = {
    ...item,
    [key]: value,
  };

  const FANDRILL_EQUIPS = [16, 17, 18];
  const JUMBO = 11;
  if (['rod_depth', 'n_drillholes', 'rod_number'].includes(key)) {
    if (FANDRILL_EQUIPS.includes(filters.equipmentType)) {
      newItem.hole_depth = (newItem.rod_depth * newItem.rod_number) || 0;
    } else if (filters.equipmentType === JUMBO) {
      newItem.hole_depth = (newItem.rod_depth * newItem.n_drillholes) || 0;
    }
  }

  return setValues(newItem, key, value, 'perforator');
};
export const setCrusherValues = (item, key, value) => setValues(item, key, value, 'crusher');
export const setRefuelerValues = (item, key, value) => setValues(item, key, value, 'refueler');
export const setUndergroundValues = (item, key, value) => setValues(item, key, value, 'underground');
export const setTractorValues = (item, key, value) => setValues(item, key, value, 'tractor');
export const setInfrastructureValues = (item, key, value) => setValues(item, key, value, 'infrastructure');
export const setShaftValues = (item, key, value) => setValues(item, key, value, 'shaft');
export const setFillinValues = (item, key, value) => setValues(item, key, value, 'fillin');
export const setScissorLiftValues = (item, key, value) => setValues(item, key, value, 'scissor_lift');
export const setMaintenanceValues = (item, key, value) => {
  const newItem = {
    ...item,
    [key]: value,
  };
  if (key === 'equipaments_id') {
    newItem.equipament_groups_id = 0;
    newItem.group_equipament_links_id = 0;
  } else if (key === 'equipament_groups_id') {
    newItem.group_equipament_links_id = 0;
  } else if (key === 'code_group_id') {
    newItem.code_id = 0;
  } else if (key === 'turn_group_id') {
    newItem.turn_id = 0;
    newItem.team_group_id = 0;
    newItem.team_id = 0;
  } else if (key === 'team_group_id') {
    newItem.team_id = 0;
  } else if (key === 'operators_groups_id') {
    newItem.operator_id = 0;
  } else if (key === 'code_type_maintenance_id') {
    newItem.code_group_maintenance_id = 0;
    newItem.code_maintenance_id = 0;
  } else if (key === 'code_group_maintenance_id') {
    newItem.code_maintenance_id = 0;
  }

  return setValues(newItem, key, value, 'maintenance');
};


export const createTransportItem = newData => ({
  type: types.CREATE_TRANSPORT_ITEM,
  payload: {
    ...newData,
    update_status: 'I',
    exception_type: 13,
  },
});

export const createHourmeterItem = newData => ({
  type: types.CREATE_HOURMETER_ITEM,
  payload: {
    ...newData,
    update_status: 'I',
    exception_type: 13,
  },
});

export const createHourMachineItem = (filters) => {
  const { startDate = newMomentDate(), equipmentType = 0 } = filters;
  return {
    type: types.CREATE_HOUR_MACHINE_ITEM,
    payload: {
      update_status: 'I',
      hour_machine_date: startDate,
      hour_machine_time: formatTime(startDate, 'HH:mm:ss'),
      team_id: '',
      turn: '',
      operator_id: '',
      operator_group: '',
      equip_type: equipmentType || '',
      equip_group: '',
      equip_id: '',
      exception_type: 14,
      hourmeter_1: '',
      hourmeter_2: '',
      hourmeter_3: '',
      hourmeter_4: '',
      hourmeter_5: '',
      hourmeter_6: '',
      hourmeter_7: '',
      hourmeter_8: '',
      hourmeter_9: '',
      hourmeter_10: '',
    },
  };
};

export const newCodeItem = newData => ({
  type: types.CREATE_CODE_ITEM,
  payload: {
    ...newData,
    update_status: 'I',
    exception_type: 13,
  },
});
export const newCodesRawItem = newData => ({
  type: types.CREATE_CODES_RAW_ITEM,
  payload: {
    ...newData,
    update_status: 'I',
    has_logged_out: true,
    exception_type: 13,
  },
});

export const createCodeItem = (newData) => {
  const { startDate = newMomentDate(), endDate = newMomentDate(), equipmentType = 0 } = newData;
  return {
    type: types.CREATE_CODE_ITEM,
    payload: {
      update_status: 'I',
      start_date: startDate,
      end_date: endDate,
      start_time: formatTime(startDate, 'HH:mm:ss'),
      end_time: formatTime(endDate, 'HH:mm:ss'),
      duration: 0,
      id_turn: '',
      id_operator: '',
      id_operator_group: '',
      equip_type: equipmentType || '',
      id_equip_group: '',
      id_equip: '',
      code_type: '',
      id_code: '',
      id_code_group: '',
      actual_start_timestamp: '',
      exception_type: 14,
      element_id: '',
      point_id: '',
      latitude: '',
      longitude: '',
      altitude: '',
    },
  };
};

export const newLoadItem = newData => ({
  type: types.CREATE_LOAD_ITEM,
  payload: {
    ...newData,
    update_status: 'I',
    exception_type: 14,
  },
});

export const newRailroadItem = newData => ({
  type: types.CREATE_RAILROAD_ITEM,
  payload: {
    ...newData,
    update_status: 'I',
    exception_type: 14,
  },
});


export const createLoadItem = (filters) => {
  const { startDate = newMomentDate(), endDate = newMomentDate() } = filters;
  const newItem = {
    update_status: 'I',
    start_date: formatDate(startDate, 'DD-MM-YYYY'),
    end_date: formatDate(endDate, 'DD-MM-YYYY'),
    start_time: formatTime(startDate, 'HH:mm:ss'),
    end_time: formatTime(endDate, 'HH:mm:ss'),
    duration: 0,
    turn: 0,
    exception_type: 14,
    code_time: 0,
    id_excavator: 0,
    id_material_esc: 0,
    id_mine: 0,
    id_mine_origin: 0,
    id_excavator_operator: 0,
    id_group_excavator_operator: 0,
    load_alt: 0,
    load_lat: 0,
    load_lon: 0,
    load_manager: 0,
    load_time: 0,
    stop_time: 0,
    is_special: 0,
    id_operation_type: 0,
    id_destination_esc: null,
    id_destination_truck: null,
    id_destination_point_truck: null,
    id_destination_point_esc: null,
    id_group_truck_operator: null,
    id_material_truck: null,
    id_mine_origin_truck: null,
    id_mine_truck: null,
    id_truck: null,
    id_truck_operator: null,
    load_balance: null,
    load_utm_x: null,
    load_utm_y: null,
    load_utm_zone_id: null,
    tablet_id: null,
    timestamp_usn: null,
    uid: null,
    unload_alt: null,
    unload_lat: null,
    unload_lon: null,
    unload_utm_x: null,
    unload_utm_y: null,
    unload_utm_zone_id: null,
  };

  return {
    type: types.CREATE_LOAD_ITEM,
    payload: {
      ...newItem,
    },
  };
};

export const createCrusherItem = (filters) => {
  const { startDate = newMomentDate(), endDate = newMomentDate() } = filters;
  const newItem = {
    update_status: 'I',
    exception_type: 13,
    start_timestamp: startDate.unix(),
    end_timestamp: endDate.unix(),
  };
  return {
    type: types.CREATE_CRUSHER_ITEM,
    payload: {
      ...newItem,
    },
  };
};

export const createPerforatorItem = newData => ({
  type: types.CREATE_PERFORATOR_ITEM,
  payload: {
    ...newData,
    update_status: 'I',
    exception_type: 13,
  },
});

export const createRefuelerItem = (filters) => {
  const { startDate = newMomentDate(), endDate = newMomentDate() } = filters;
  const newItem = {
    update_status: 'I',
    exception_type: 14,
    start_date: startDate,
    end_date: endDate,
    start_time: formatTime(startDate, 'HH:mm:ss'),
    end_time: formatTime(endDate, 'HH:mm:ss'),
    turn_end: 0,
    operator_group_id: 0,
    operator_id: 0,
    equip_group_id: 0,
    equip_id: 0,
    equip_type_fueled: 0,
    equip_group_id_fueled: 0,
    equip_id_fueled: 0,
    operator_group_id_fueled: 0,
    operator_id_fueled: 0,
    latitude_start: 0,
    longitude_start: 0,
    element_id_start: 0,
    element_point_id_start: 0,
    element_id_end: 0,
    latitude_end: 0,
    longitude_end: 0,
    element_point_id_end: 0,
    hourmeter_fueled: 0,
    kilometer_fueled: 0,
    liters_fueled: 0,
    code_time: 0,
    refuel_time: 0,
    waiting_time: 0,
    refuel_distance: 0,
  };

  return {
    type: types.CREATE_REFUELER_ITEM,
    payload: {
      ...newItem,
    },
  };
};

export const createUndergroundItem = filters => async (dispatch) => {
  dispatch({ type: types.CREATE_UNDERGROUND_ITEM, payload: filters });
};

export const createMaintenanceItem = filters => async (dispatch) => {
  dispatch({ type: types.CREATE_MAINTENANCE_ITEM, payload: filters });
};

export const createTractorItem = (filters) => {
  const { startDate = newMomentDate(), endDate = newMomentDate() } = filters;

  return {
    type: types.CREATE_TRACTOR_ITEM,
    payload: {
      startDate,
      endDate,
    },
  };
};

export const createInfrastructureItem = (filters) => {
  const { startDate = newMomentDate(), endDate = newMomentDate() } = filters;
  const newItem = {
    update_status: 'I',
    exception_type: 14,
    start_date: startDate,
    end_date: endDate,
    start_time: formatTime(startDate, 'HH:mm:ss'),
    end_time: formatTime(endDate, 'HH:mm:ss'),
    duration: 0,
    turn_id: 0,
    team_id: 0,
    operator_group_id: 0,
    operator_id: 0,
    material_id: 0,
    equipament_groups_id: 0,
    group_equipament_links_id: 0,
    load_manager: 0,
    load_time: 0,
    idle_time: 0,
    code_time: 0,
    load_lat: 0,
    load_lon: 0,
    load_alt: 0,
    load_utm_x: null,
    load_utm_y: null,
    load_utm_zone_id: null,
  };

  return {
    type: types.CREATE_INFRASTRUCTURE_ITEM,
    payload: {
      ...newItem,
    },
  };
};

export const createShaftItem = (filters) => {
  const { startDate = newMomentDate(), endDate = newMomentDate() } = filters;
  const newItem = {
    update_status: 'I',
    exception_type: 14,
    start_date: startDate,
    end_date: endDate,
    start_time: formatTime(startDate, 'HH:mm:ss'),
    end_time: formatTime(endDate, 'HH:mm:ss'),
    duration: 0,
    turn_id: 0,
    team_id: 0,
    operator_group_id: 0,
    operator_id: 0,
    material_id: 0,
    equipament_groups_id: 0,
    group_equipament_links_id: 0,
    load_manager: 0,
    load_time: 0,
    idle_time: 0,
    code_time: 0,
    id_operation_type: 0,
    id_mine: 0,
    id_mine_origin: 0,
  };

  return {
    type: types.CREATE_SHAFT_ITEM,
    payload: {
      ...newItem,
    },
  };
};

export const createFillinItem = (filters) => {
  const { startDate = newMomentDate(), endDate = newMomentDate() } = filters;
  const newItem = {
    update_status: 'I',
    duration: 0,
    turn_id: 0,
    team_id: 0,
    equip_group_id: 19,
    equip_id: 29,
    group_equipament_links_id: 0,
    truck_id: 0,
    batch_number: 0,
    load_manager: 0,
    stop_time: 0,
    load_time: 0,
    code_time: 0,
    operator_id: 0,
    timestamp_usn: 0,
    uid: '',
    tablet_id: '',
    start_date: startDate,
    end_date: endDate,
    start_time: formatTime(startDate, 'HH:mm:ss'),
    end_time: formatTime(endDate, 'HH:mm:ss'),
  };
  return {
    type: types.CREATE_FILLIN_ITEM,
    payload: {
      ...newItem,
    },
  };
};

export const createScissorLiftItem = (filters) => {
  const { startDate = newMomentDate(), endDate = newMomentDate() } = filters;
  const newItem = {
    update_status: 'I',
    exception_type: 14,
    start_date: startDate,
    end_date: endDate,
    start_time: formatTime(startDate, 'HH:mm:ss'),
    end_time: formatTime(endDate, 'HH:mm:ss'),
    duration: 0,
    team_id: 0,
    turn_id: 0,
    equip_group_id: 0,
    equip_id: 0,
    operator_group_id: 0,
    operator_id: 0,
    operation_type_id: 0,
    operation_activity_id: 0,
    total_dimension: 0,
    idle_time: 0,
    operation_time: 0,
    code_time: 0,
  };

  return {
    type: types.CREATE_SCISSOR_LIFT_ITEM,
    payload: {
      ...newItem,
    },
  };
};

export const deleteTransportItem = item => deleteItem(item, 'transport');
export const deleteHourmeterItem = item => deleteItem(item, 'hourmeter');
export const deleteCodeItem = item => deleteItem(item, 'code');
export const deleteActivityItem = item => deleteItem(item, 'activity');
export const deleteCodesRawItem = item => deleteItem(item, 'codes_raw');
export const deleteHourMachineItem = item => deleteItem(item, 'hour_machine');
export const deleteLoadItem = item => deleteItem(item, 'load');
export const deleteRailroadItem = item => deleteItem(item, 'railroad');
export const deletePerforatorItem = item => deleteItem(item, 'perforator');
export const deleteCrusherItem = item => deleteItem(item, 'crusher');
export const deleteRefuelerItem = item => deleteItem(item, 'refueler');
export const deleteUndergroundItem = item => deleteItem(item, 'underground');
export const deleteTractorItem = item => deleteItem(item, 'tractor');
export const deleteMaintenanceItem = item => deleteItem(item, 'maintenance');
export const deleteInfrastructureItem = item => deleteItem(item, 'infrastructure');
export const deleteShaftItem = item => deleteItem(item, 'shaft');
export const deleteFillinItem = item => deleteItem(item, 'fillin');
export const deleteScissoLiftItem = item => deleteItem(item, 'scissor_lift');

export const restoreTransportItem = item => ({
  type: types.RESTORE_TRANSPORT_ITEM,
  payload: {
    ...item,
  },
});

export const restoreHourmeterItem = item => ({
  type: types.RESTORE_HOURMETER_ITEM,
  payload: {
    ...item,
  },
});

export const restoreCodeItem = item => ({
  type: types.RESTORE_CODE_ITEM,
  payload: {
    ...item,
  },
});

export const restoreHourMachineItem = item => ({
  type: types.RESTORE_HOUR_MACHINE_ITEM,
  payload: {
    ...item,
  },
});

export const restoreLoadItem = item => ({
  type: types.RESTORE_LOAD_ITEM,
  payload: {
    ...item,
  },
});

export const restorePerforatorItem = item => ({
  type: types.RESTORE_PERFORATOR_ITEM,
  payload: {
    ...item,
  },
});

export const restoreRefuelerItem = item => ({
  type: types.RESTORE_REFUELER_ITEM,
  payload: {
    ...item,
  },
});

export const restoreUndergroundItem = item => ({
  type: types.RESTORE_UNDERGROUND_ITEM,
  payload: item,
});

export const restoreMaintenanceItem = item => ({
  type: types.RESTORE_MAINTENANCE_ITEM,
  payload: item,
});

export const restoreTractorItem = item => ({
  type: types.RESTORE_TRACTOR_ITEM,
  payload: {
    ...item,
  },
});

export const restoreInfrastructureItem = item => ({
  type: types.RESTORE_INFRASTRUCTURE_ITEM,
  payload: {
    ...item,
  },
});

export const restoreShaftItem = item => ({
  type: types.RESTORE_SHAFT_ITEM,
  payload: {
    ...item,
  },
});

export const restoreFillinItem = item => ({
  type: types.RESTORE_SHAFT_ITEM,
  payload: {
    ...item,
  },
});

export const restoreScissorLiftItem = item => ({
  type: types.RESTORE_SCISSOR_LIFT_ITEM,
  payload: {
    ...item,
  },
});

export const restoreOriginalTransport = () => ({
  type: types.RESTORE_ORIGINAL_TRANSPORT,
});

export const restoreOriginalHourmeter = () => ({
  type: types.RESTORE_ORIGINAL_HOURMETER,
});

export const restoreOriginalCode = () => ({
  type: types.RESTORE_ORIGINAL_CODE,
});
export const restoreOriginalActivity = () => ({
  type: types.RESTORE_ORIGINAL_ACTIVITY,
});
export const restoreOriginalCodesRaw = () => ({
  type: types.RESTORE_ORIGINAL_CODES_RAW,
});

export const restoreOriginalHourMachine = () => ({
  type: types.RESTORE_ORIGINAL_HOUR_MACHINE,
});

export const restoreOriginalLoad = () => ({
  type: types.RESTORE_ORIGINAL_LOAD,
});

export const restoreOriginalRailroad = () => ({
  type: types.RESTORE_ORIGINAL_RAILROAD,
});

export const restoreOriginalPerforator = () => ({
  type: types.RESTORE_ORIGINAL_PERFORATOR,
});

export const restoreOriginalCrusher = () => ({
  type: types.RESTORE_ORIGINAL_CRUSHER,
});

export const restoreOriginalRefueler = () => ({
  type: types.RESTORE_ORIGINAL_REFUELER,
});

export const restoreOriginalUnderground = (clean = false) => async (dispatch) => {
  if (clean) {
    dispatch({ type: types.CLEAN_UNDERGROUND_LIST });
  } else {
    dispatch({ type: types.RESTORE_ORIGINAL_UNDERGROUND });
  }
};

export const restoreOriginalMaintenance = (clean = false) => async (dispatch) => {
  if (clean) {
    dispatch({ type: types.CLEAN_MAINTENANCE_LIST });
  } else {
    dispatch({ type: types.RESTORE_ORIGINAL_MAINTENANCE });
  }
};

export const restoreOriginalTractor = () => ({
  type: types.RESTORE_ORIGINAL_TRACTOR,
});

export const restoreOriginalInfrastructure = () => ({
  type: types.RESTORE_ORIGINAL_INFRASTRUCTURE,
});

export const restoreOriginalShaft = () => ({
  type: types.RESTORE_ORIGINAL_SHAFT,
});
export const restoreOriginalFillin = () => ({
  type: types.RESTORE_ORIGINAL_FILLIN,
});

export const restoreOriginalScissorLift = () => ({
  type: types.RESTORE_ORIGINAL_SCISSOR_LIFT,
});

export const setFilters = (parameters, page = 1, limit = 10000) => async (dispatch) => {
  dispatch({ type: types.SET_FILTERS, payload: parameters });


  const { equipmentType } = parameters;
  const isLoadEquip = [1, 15].includes(equipmentType);
  const isRailroadEquip = equipmentType === 31;
  const isPerforatorEquip = [4, 11, 16, 17, 18, 19].includes(equipmentType);
  const isRefuelerEquip = equipmentType === 14;
  const isTractorEquip = equipmentType === 13;
  const isInfrastructureEquip = [21, 22, 23].includes(equipmentType);
  const isShaftEquip = equipmentType === 20;
  const isFillinEquip = [29].includes(equipmentType);
  const isScissorLiftEquip = equipmentType === 24;
  const isCrusherEquip = equipmentType === 25;

  if (isLoadEquip) {
    dispatch(searchLoadList(parameters, page, 10000));
  } else if (isRailroadEquip) {
    dispatch(searchRailroadList(parameters, 0, 10000));
    dispatch(getLoads(32));
  } else if (isPerforatorEquip) {
    dispatch(searchPerforatorList(parameters, page, limit));
  } else if (isRefuelerEquip) {
    dispatch(searchRefuelerList(parameters, page, limit));
  } else if (isTractorEquip) {
    dispatch(searchTractorList(parameters, page, limit));
  } else if (isInfrastructureEquip) {
    dispatch(searchInfrastructureList(parameters, page, limit));
  } else if (isShaftEquip) {
    dispatch(searchShaftList(parameters, page, limit));
  } else if (isFillinEquip) {
    dispatch(searchFillinList(parameters, page, limit));
  } else if (isScissorLiftEquip) {
    dispatch(searchScissorLiftList(parameters, page, limit));
  } else if (isCrusherEquip) {
    dispatch(searchCrusherList(parameters, page, limit));
    dispatch(getOriginTypes());
  } else {
    dispatch(searchTransportList(parameters, page, 10000));
  }

  dispatch(searchActivityList(parameters, page, limit));
  dispatch(searchHourMachineList(parameters, page, limit));
  dispatch(searchHourmeterList(parameters, page, limit));
  dispatch(searchCodeList(parameters, page, 10000));
  dispatch(searchCodeRawList(parameters, page, 10000));
};

const updateList = (data, parameters, type) => async (dispatch) => {
  try {
    dispatch({
      type: types.SET_IS_SUBMITTING_DATA,
      payload: true,
    });
    const key = type === 'hourmeter' ? 'id_line' : 'id';
    const url = `update_${type}_list`;
    const objectRequest = `${type}_reports`;


    const dataItemsMapper = ({
      start_date: startDate,
      end_date: endDate,
      start_time: startTime,
      end_time: endTime,
      hourmeter_date: hourmeterDate,
      hourmeter_time: hourmeterTime,
      hour_machine_date: hourMachineDate,
      hour_machine_time: hourMachineTime,
      drilling_start_date: drillingStartDate,
      drilling_start_time: drillingStartTime,
      ...rest
    }) => {
      const formatToDatetime = (date, time) => `${formatDateToServerFormat(date)}${formatTime(time)}`;

      const drillingStartDatetimeSettings = () => {
        if (!drillingStartDate) return null;
        const t = drillingStartTime?.length ? drillingStartTime : newMomentDate();
        return formatToDatetime(drillingStartDate, t);
      };

      const defaultValues = {
        start_datetime: formatToDatetime(startDate, startTime),
        end_datetime: formatToDatetime(endDate, endTime),
      };

      const itemsTypeMapper = {
        refueler: { ...defaultValues },
        hourmeter: {
          hourmeter_datetime: formatToDatetime(hourmeterDate, hourmeterTime),
        },
        hour_machine: {
          hour_machine_datetime: formatToDatetime(hourMachineDate, hourMachineTime),
        },
        codes_raw: {
          timestamp_op: rest.timestamp_op / 1000,
          end_timestamp: rest.end_timestamp / 1000,
          duration: undefined,
        },
        perforator: {
          drilling_start_datetime: drillingStartDatetimeSettings(),
          hole_depth: Number(rest.hole_depth),
          ...defaultValues,
        },
        tractor: {
          start_date: formatDateToServerFormat(startDate),
          start_time: formatTime(startTime),
          end_date: formatDateToServerFormat(endDate),
          end_time: formatTime(endTime),
          start_datetime: undefined,
          end_datetime: undefined,
          active: undefined,
          ...defaultValues,
        },
        railroad: {
          start_date: formatDateToServerFormat(startDate),
          start_time: formatTime(startTime),
          end_date: formatDateToServerFormat(endDate),
          end_time: formatTime(endTime),
          start_datetime: undefined,
          end_datetime: undefined,
          active: undefined,
          ...defaultValues,
        },
        infrastructure: {
          id_mine: rest.id_mine || null,
          id_mine_origin: rest.id_mine_origin || null,
          id_destination: rest.id_destination || null,
          id_destination_point: rest.id_destination_point || null,
          ...defaultValues,
        },
      };

      const returnValues = itemsTypeMapper[type] ? itemsTypeMapper[type] : defaultValues;

      return { ...rest, ...returnValues };
    };

    const items = data.filter(d => d.isDirty || d[key] < 1).map((d) => {
      delete d.isDirty;

      if (d.update_status === 'I') {
        delete d[key];
      }

      return dataItemsMapper(d);
    });

    const body = {
      [objectRequest]: items,
    };
    const { status } = await api.post(`/partes_diarias/${url}`, body);
    if (status === 200 && parameters) {
      dispatch(setFilters(parameters));
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
  dispatch({
    type: types.SET_IS_SUBMITTING_DATA,
    payload: false,
  });
};

const exportReport = (parameters, type) => async (dispatch) => {
  try {
    let searchParameters = {};
    const urlToGenerate = `generate_${type}_report`;

    switch (type) {
      case 'hourmeter':
        searchParameters = handleHourmeterParameters(parameters);
        break;
      case 'hour_machine':
        searchParameters = handleHourMachineParameters(parameters);
        break;
      case 'code':
        searchParameters = handleCodeParameters(parameters);
        break;
      case 'load':
        searchParameters = handleLoadParameters(parameters);
        break;
      case 'railroad':
        searchParameters = handleRailroadParameters(parameters);
        break;
      case 'perforator':
        searchParameters = handlePerforatorParameters(parameters);
        break;
      case 'refueler':
        searchParameters = handleRefuelerParameters(parameters);
        break;
      case 'maintenance_operation':
        searchParameters = handleMaintenanceParameters(parameters);
        break;
      case 'infrastructure':
        searchParameters = handleInfrastructureParameters(parameters);
        break;
      case 'shaft':
        searchParameters = handleShaftParameters(parameters);
        break;
      case 'fillin':
        searchParameters = handleFillinParameters(parameters);
        break;
      case 'scissor_lift':
        searchParameters = handleScissorLiftParameters(parameters);
        break;
      default:
        searchParameters = handleParameters(parameters);
        break;
    }
    const body = {
      ...searchParameters,
      report_type: 'xlsx',
    };
    const {
      data: {
        result: {
          filename = '',
        },
      },
    } = await api.post(`/partes_diarias/${urlToGenerate}`, body);
    const url = `${process.env.REACT_APP_API_URL}/download_file?file=${filename}`;
    await downloadAxios(url, filename);
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const updateTransportList = (data, parameters) => async dispatch => dispatch(updateList(data, parameters, 'transport'));
export const updateHourmeterList = (data, parameters) => async dispatch => dispatch(updateList(data, parameters, 'hourmeter'));
export const updateCodeList = (data, parameters) => async dispatch => dispatch(updateList(data, parameters, 'code'));
export const updateActivityList = (data, parameters) => async dispatch => dispatch(updateList(data, parameters, 'activity'));
export const updateCodesRawList = (data, parameters) => async dispatch => dispatch(updateList(data, parameters, 'codes_raw'));
export const updateHourMachineList = (data, parameters) => async dispatch => dispatch(updateList(data, parameters, 'hour_machine'));
export const updateLoadList = (data, parameters) => async dispatch => dispatch(updateList(data, parameters, 'load'));
export const updateRailroadList = (data, parameters) => async dispatch => dispatch(updateList(data, parameters, 'railroad'));
export const updatePerforatorList = (data, parameters) => async dispatch => dispatch(updateList(data, parameters, 'perforator'));
export const updateCrusherList = (data, parameters) => async dispatch => dispatch(updateList(data, parameters, 'crusher'));
export const updateRefuelerList = (data, parameters) => async dispatch => dispatch(updateList(data, parameters, 'refueler'));
export const updateTractorList = (data, parameters) => async dispatch => dispatch(updateList(data, parameters, 'tractor'));
export const updateInfrastructureList = (data, parameters) => async dispatch => dispatch(updateList(data, parameters, 'infrastructure'));
export const updateShaftList = (data, parameters) => async dispatch => dispatch(updateList(data, parameters, 'shaft'));
export const updateFillinList = (data, parameters) => async dispatch => dispatch(updateList(data, parameters, 'fillin'));
export const updateScissorLiftList = (data, parameters) => async dispatch => dispatch(updateList(data, parameters, 'scissor_lift'));

export const exportTransportReport = parameters => async dispatch => dispatch(exportReport(parameters, 'transport'));
export const exportHourmeterReport = parameters => async dispatch => dispatch(exportReport(parameters, 'hourmeter'));
export const exportCodeReport = parameters => async dispatch => dispatch(exportReport(parameters, 'code'));
export const exportHourMachine = parameters => async dispatch => dispatch(exportReport(parameters, 'hour_machine'));
export const exportLoadReport = parameters => async dispatch => dispatch(exportReport(parameters, 'load'));
export const exportPerforatorReport = parameters => async dispatch => dispatch(exportReport(parameters, 'perforator'));
export const exportRefuelerReport = parameters => async dispatch => dispatch(exportReport(parameters, 'refueler'));
export const exportTractorReport = parameters => async dispatch => dispatch(exportReport(parameters, 'tractor'));
export const exportMaintenanceReport = parameters => async dispatch => dispatch(exportReport(parameters, 'maintenance_operation'));
export const exportInfrastructureReport = parameters => async dispatch => dispatch(exportReport(parameters, 'infrastructure'));
export const exportShaftReport = parameters => async dispatch => dispatch(exportReport(parameters, 'shaft'));
export const exportFillinReport = parameters => async dispatch => dispatch(exportReport(parameters, 'fillin'));
export const exportScissorLiftReport = parameters => async dispatch => dispatch(exportReport(parameters, 'scissor_lift'));

export const searchUnderground = ({
  parameters,
  page = 1,
  limit = 50,
  orderBy = { field: 'id_project_group', order: 'asc' },
  reorder = true,
  url,
  keySortBy,
  responseObject,
  type,
}) => async (dispatch) => {
  const searchParameters = {
    start_date: formatDate(parameters.startDate, true),
    end_date: formatDate(parameters.endDate, true),
    id_project_group: parameters.projectGroup ? parameters.projectGroup.id : null,
    id_element: parameters.element ? parameters.element.id : null,
    id_element_point: parameters.subElement ? parameters.subElement.id : null,
    id_unitary_operation_group: null,
    id_unitary_operation: null,
    id_code_type_ug: null,
    id_code_group_ug: null,
    id_code_ug: null,
    hide_interrupted_or_deleted: Boolean(parameters.hideInterruptedOrDeleted),
  };
  try {
    const body = {
      ...searchParameters,
      page,
      results_per_page: limit,
      order_by: orderBy,
    };
    const {
      data: {
        result: { [responseObject]: data = [] },
      },
    } = await api.post(url, body);

    dispatch({
      type,
      payload: {
        page,
        limit,
        reorder,
        data,
        [keySortBy]: orderBy,
      },
    });
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const searchUndergroundList = (parameters,
  page = 1,
  limit = 50,
  orderBy = { field: 'timestamp', order: 'asc' },
  reorder = true) => async (dispatch) => {
  const filters = {
    parameters,
    page,
    limit,
    orderBy,
    reorder,
    url: '/undergroundoperationreport/get_by_filter',
    keySortBy: 'undergroundSortBy',
    responseObject: 'underground_operation_report',
    type: types.SET_UNDERGROUND_LIST,
  };
  dispatch(searchUnderground(filters));
};

export const setUndergroundFilters = (parameters, page = 1, limit = 30) => async (dispatch) => {
  dispatch({ type: types.SET_FILTERS, payload: parameters });
  dispatch(searchUndergroundList(parameters, page, limit));
};

export const updateUnderground = (data,
  parameters,
  url = '/undergroundoperationreport',
  responseObject = 'underground_operation_report') => async (dispatch) => {
  try {
    const items = data.filter(d => d.isDirty || d.id < 1).map((d) => {
      delete d.isDirty;

      if (d.update_status === 'I') {
        delete d.id;
      }
      return { ...d, start_date: formatDateToServerFormat(d.start_date) };
    });

    const body = { [responseObject]: items };
    const { status } = await api.post(url, body);
    if (status === 200) {
      dispatch(setUndergroundFilters(parameters));
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const updateUndergroundList = (data, parameters) => async (dispatch) => {
  dispatch(updateUnderground(data, parameters));
};

export const getEquipmentTypesTabs = (all = false) => async (dispatch) => {
  try {
    const endpoint = `tabs_equipment${all ? '?all' : ''}`;
    const {
      data: {
        result: { tabs_equipments: tabs = [] },
      },
    } = await api.get(`/partes_diarias/${endpoint}`);
    dispatch({
      type: types.SET_EQUIPMENT_TYPES_TABS,
      payload: tabs,
    });
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const searchMaintenance = ({
  parameters,
  page = 1,
  limit = 50,
  orderBy = { field: 'start_timestamp', order: 'asc' },
  reorder = true,
  url,
  keySortBy,
  responseObject,
  type,
}) => async (dispatch) => {
  const searchParameters = handleMaintenanceParameters(parameters);
  try {
    const body = {
      ...searchParameters,
      page,
      results_per_page: limit,
      order_by: orderBy,
    };
    const {
      data: {
        result: { [responseObject]: data = [] },
      },
    } = await api.post(url, body);

    dispatch({
      type,
      payload: {
        page,
        limit,
        reorder,
        data,
        [keySortBy]: orderBy,
      },
    });
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const searchMaintenanceList = (parameters,
  page = 1,
  limit = 50,
  orderBy = { field: 'start_timestamp', order: 'asc' },
  reorder = true) => async (dispatch) => {
  const filters = {
    parameters,
    page,
    limit,
    orderBy,
    reorder,
    url: '/partes_diarias/get_maintenance_operation_list',
    keySortBy: 'maintenanceSortBy',
    responseObject: 'maintenance_operation_report_raw',
    type: types.SET_MAINTENANCE_LIST,
  };
  dispatch(searchMaintenance(filters));
};

export const setMaintenanceFilters = (parameters, page = 1, limit = 30) => async (dispatch) => {
  dispatch({ type: types.SET_FILTERS, payload: parameters });
  dispatch(searchMaintenanceList(parameters, page, limit));
};

export const updateMaintenance = (data,
  parameters,
  url = '/partes_diarias/update_maintenance_operation_list',
  responseObject = 'maintenance_operation_report_raw') => async (dispatch) => {
  try {
    const items = data.filter(d => d.isDirty || d.id < 1).map((d) => {
      delete d.isDirty;

      if (d.update_status === 'I') {
        delete d.id;
      }

      return { ...d };
    });

    const body = { [responseObject]: items };
    const { status } = await api.post(url, body);
    if (status === 200) {
      dispatch(setMaintenanceFilters(parameters));
    }
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const updateMaintenanceList = (data, parameters) => async (dispatch) => {
  dispatch(updateMaintenance(data, parameters));
};

export const massSetTransportList = (itemList, changes) => async (dispatch) => {
  itemList.forEach((item) => {
    dispatch(setAllValues({ ...item, ...changes }, 'transport'));
  });
};
export const massSetCodeList = (itemList, changes) => async (dispatch) => {
  itemList.forEach((item) => {
    dispatch(setAllValues({ ...item, ...changes }, 'code'));
  });
};
export const massSetHourmeterList = (itemList, changes) => async (dispatch) => {
  itemList.forEach((item) => {
    dispatch(setAllValues({ ...item, ...changes }, 'hourmeter'));
  });
};

export const newActivityList = newData => ({
  type: types.CREATE_ACTIVITY_ITEM,
  payload: {
    ...newData,
    update_status: 'I',
    exception_type: 13,
  },
});

export const setSelectedCodeForMigrations = code => ({
  type: types.SET_SELECTED_CODE_FOR_MIGRATIONS,
  payload: code,
});
