import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';
import { useTranslation } from 'react-i18next';
import { MuiThemeProvider } from '@material-ui/core';
// Component to avoid a problem on this version,
// PAY ATTENTION ON UPDATE material-table
import { useSelector } from 'react-redux';
import EditableRow from './EditableRow';
import icons from './icons';
import { config, localization, theme } from './config';
import preCell from './Cells/preCell';
import DefaultCell from './Cells';
import FilterLookupCell from './Cells/FilterLookupCell';
import TimeCell from './Cells/TimeCell';
import DateCell from './Cells/DateCell';
import ActiveCell from './Cells/ActiveCell';
import i18n from '~/i18n';
import { getColumnOrder, setColumnOrder } from '~/services/cache';

const getOriginal = item => item?.tableData?.original || item;
const changeField = ({
  custom, row, value, field,
}) => ({
  [field]: value,
  ...(custom ? custom({ row, field, value }) : {}),
});

const PF2MDataTable = ({
  data,
  columns,
  onAdd,
  onChange,
  onDiscard,
  onApply,
  onDelete,
  onInactivateAll,
  options,
  extraActions,
  defaultEditable,
  onExport,
  onImport,
  isEditable = () => true,
  ...propRest
}) => {
  const { t: translate } = useTranslation();
  // Used to set and get the internal state of the table, consult material-table docs
  const materialTableRef = React.createRef();
  const user = useSelector(state => state.auth.user);

  // replace null values with empty string
  const parsedData = dataList => (dataList.filter(i => i.update_status !== 'D').map(item => ({
    ...item,
    ...Object.keys(item)
      .reduce((acc, key) => ({ ...acc, [key]: item[key] === null ? '' : item[key] }), {}),
  })));

  // get the value in table's inner state
  const val = (cell) => {
    if (!cell.rowData.tableData) return cell.value;
    const { id } = cell.rowData.tableData;
    const innerRow = materialTableRef.current.state.renderData.find(i => i.tableData.id === id);
    return innerRow ? innerRow[cell.columnDef.field] : cell.value;
  };

  const bulkEdit = (tableRef, value, p) => {
    const renderState = tableRef.state.renderData;
    const newRenderData = [
      ...renderState.map(item => (item.tableData.editing === 'update'
        ? {
          ...item,
          tableData: { ...item.tableData, editing: 'update', original: { ...getOriginal(item) } },
          ...changeField({
            custom: p.columnDef.customOnChange, row: item, field: p.columnDef.field, value,
          }),
        } : item)),
    ];
    // set the table's inner state
    tableRef.dataManager.setData(newRenderData);
    tableRef.setState({
      ...tableRef.dataManager.getRenderState(),
    });
  };

  const getCustomComponent = (col) => {
    const format = i18n.t('date:DateFormat');

    if (col.editComponent) return col.editComponent;

    const directEventBulkEdit = (...rest) => bulkEdit(materialTableRef.current, ...rest);
    const defaultProps = {
      ...col,
      bulkEdit: (e, ...rest) => bulkEdit(materialTableRef.current, e.target.value, ...rest),
      field: col.field,
      val,
      options,
      editing: 'always',
    };

    const directEventBulkEditProps = {
      ...defaultProps,
      bulkEdit: directEventBulkEdit,
    };

    const directEventBulkEditPropsDateRailroad = {
      ...defaultProps,
      dateWithoutKeyboard: true,
      bulkEdit: directEventBulkEdit,
    };

    const dateByTimestampProps = {
      ...defaultProps,
      bulkEdit: directEventBulkEdit,
      format: `${format}`,
      isDateTime: false,
      // views: ['hours', 'minutes', 'seconds'],
      noParseDate: true,
    };

    const dateTimeByTimestampProps = {
      ...defaultProps,
      bulkEdit: directEventBulkEdit,
      format: `${format} HH:mm:ss`,
      isDateTime: true,
      views: ['hours', 'minutes', 'seconds'],
      noParseDate: true,
    };
    // It maps the custom components,
    // to use it on column define column.type: 'customType' and customType: <type>
    const customComponents = {
      default: { editComponent: preCell(DefaultCell, directEventBulkEditProps) },
      time: { editComponent: preCell(TimeCell, defaultProps) },
      filterLookup: { editComponent: preCell(FilterLookupCell, defaultProps) },
      date: { editComponent: preCell(DateCell, directEventBulkEditProps) },
      dateRailroad: { editComponent: preCell(DateCell, directEventBulkEditPropsDateRailroad) },
      dateTimeByTimestamp: { editComponent: preCell(DateCell, dateTimeByTimestampProps) },
      dateByTimestamp: { editComponent: preCell(DateCell, dateByTimestampProps) },
      boolean: {
        editComponent: preCell(ActiveCell, directEventBulkEditProps),
        render: preCell(ActiveCell, { ...directEventBulkEditProps, editing: false }),
      },
    };

    return customComponents[col.customType]
      ? customComponents[col.customType]
      : customComponents.default;
  };

  const parseDataForLookup = (lookup, key) => {
    if (lookup) {
      const parsedLookup = (lookup
        .reduce((res, i) => { res[i[key]] = i.name; return res; }, {}));
      return { 0: translate('common:None'), ...parsedLookup };
    }
    return undefined;
  };

  const reorderColumns = (cols) => {
    if (!options.cacheKey) return cols;
    const indexList = getColumnOrder(options.cacheKey, user.id);
    if (!indexList) return cols;
    if (indexList.length !== cols.length) {
      setColumnOrder(options.cacheKey, user.id, cols.map((_, i) => i));
      return cols;
    }
    const newCols = [...cols];
    const reorderedCols = indexList.map(i => newCols[i]);
    return reorderedCols;
  };

  // Adapt the this component to material-table api
  const parsedCols = reorderColumns(columns).map((col) => {
    const lookupData = col.lookup;
    const lookupKey = col.lookupKey || 'id';

    const lookupItems = parseDataForLookup(lookupData, lookupKey);

    let { render } = col;

    if (!render && col.customType === 'dateTimeByTimestamp') {
      render = (row) => {
        const ts = row[col.field];

        if (!ts) return '';

        return (new Date(ts)).toLocaleString();
      };
    }

    if (!render && col.customType === 'dateByTimestamp') {
      render = (row) => {
        const ts = row[col.field];

        if (!ts) return '';

        return (new Date(ts)).toLocaleDateString();
      };
    }

    return {
      ...col,
      initialEditValue: col.initialEditValue || '',
      lookupData,
      lookup: lookupItems,
      lookupKey,
      render,
      ...getCustomComponent(col),
    };
  });

  const onColumnDragged = (sourceIndex, destinationIndex) => {
    const indexList = getColumnOrder(options.cacheKey, user.id)
    || parsedCols.map((i, index) => index);
    const newColumnOrder = [...indexList];
    newColumnOrder.splice(sourceIndex, 1);
    newColumnOrder.splice(destinationIndex, 0, indexList[sourceIndex]);
    setColumnOrder(options.cacheKey, newColumnOrder, user.id);
  };

  const edit = (_event, rowData) => {
    const tableRef = materialTableRef.current;
    const { renderData } = tableRef.state;
    const rowList = rowData.length ? rowData : [rowData];
    const newRenderData = [
      ...renderData.map(item => (rowList.find(row => row.id === item.id)
        ? {
          ...item,
          tableData: { ...item.tableData, editing: 'update', original: { ...getOriginal(item) } },
        }
        : item)),
    ];
    tableRef.dataManager.setData(newRenderData);
    tableRef.setState({
      ...tableRef.dataManager.getRenderState(),
    });
  };

  const editable = defaultEditable ? {
    onRowUpdate: async (newData) => {
      try {
        const tableRef = materialTableRef.current;
        const rowList = tableRef.state.renderData;
        const selectedItems = rowList.filter(item => item.tableData.editing === 'update');
        if (selectedItems.length <= 1) {
          onChange([newData]);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    },
    // this feature don't support for mass editing, options.selection = true;
    isEditable: rowData => isEditable(rowData),
    isEditHidden: rowData => options.editableRowValidation?.(rowData),
  } : {};

  const commitChangesIcon = (
    options.disableSaveButton ? icons.DisabledCheckCircle : icons.CheckCircle
  );

  const commitChangesTooltip = (
    options.disableSaveButton
      ? translate('validation:FormWasNotFilledCorrectly')
      : translate('common:Commit Changes')
  );

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <MaterialTable
          icons={icons}
          columns={parsedCols}
          data={parsedData(data)}
          tableRef={materialTableRef}
          components={{
            EditRow: props => EditableRow(props, materialTableRef.current),
          }}
          onColumnDragged={options.cacheKey ? onColumnDragged : undefined}
          actions={[
            {
              hidden: options.hideCommit,
              icon: commitChangesIcon,
              isFreeAction: true,
              tooltip: commitChangesTooltip,
              onClick: event => onApply(event),
              disabled: options.disableSaveButton,
            },
            {
              hidden: options.hideReload,
              icon: icons.Reload,
              isFreeAction: true,
              tooltip: translate('common:Discard Changes'),
              onClick: event => onDiscard(event),
            },
            {
              icon: icons.Clear,
              hidden: !onInactivateAll,
              tooltip: translate('common:InactivateAllSelectedItems'),
              onClick: (_, items) => onInactivateAll(items),
            },
            {
              icon: icons.Add,
              isFreeAction: true,
              tooltip: translate('common:Add'),
              hidden: options.hideAdd,
              onClick: () => {
                const tableRef = materialTableRef.current;
                const newDataParsed = {
                  ...columns.reduce((acc, key) => ({ ...acc, [key.field]: '' }), {}),
                  ...propRest.initialFormData || {},
                };
                tableRef.dataManager.changeCurrentPage(0);
                onAdd(newDataParsed);
              },
            },
            rowData => ({
              hidden: !options.selection,
              disabled: options.editableRowValidation?.(rowData),
              icon: icons.Check,
              tooltip: translate('common:Save'),
              onClick: () => {
                const tableRef = materialTableRef.current;
                const rowList = tableRef.state.renderData
                  .filter(item => item.tableData.editing === 'update')
                  .map(item => ({ ...item, tableData: undefined }));

                onChange(rowList);
              },
            }
            ),
            rowData => ({
              hidden: !options.selection,
              disabled: options.editableRowValidation?.(rowData),
              icon: icons.Edit,
              tooltip: translate('common:Edit'),
              onClick: edit,
            }),
            {
              icon: icons.Clear,
              hidden: !options.selection,
              tooltip: translate('common:ClearAllSelectedItems'),
              onClick: () => {
                const tableRef = materialTableRef.current;
                const rowList = tableRef.state.renderData;
                const newRenderData = rowList.map((item) => {
                  const tableData = {
                    ...item.tableData,
                    editing: null,
                    checked: false,
                  };
                  return item.tableData.original
                    ? { ...item, ...item.tableData.original, tableData }
                    : { ...item, tableData };
                });
                tableRef.dataManager.setData(newRenderData);
                tableRef.setState({
                  ...tableRef.dataManager.getRenderState(),
                });
              },
            },
            {
              icon: icons.Delete,
              hidden: !options.selection,
              tooltip: translate('common:RemoveAllSelectedItems'),
              onClick: (_, items) => onDelete(items),
            },
            {
              hidden: !onExport,
              icon: icons.FileDownload,
              isFreeAction: true,
              tooltip: translate('common:Export'),
              onClick: event => onExport(event),
            },
            {
              hidden: !onImport,
              icon: icons.FileUpload,
              isFreeAction: true,
              tooltip: translate('common:Import'),
              onClick: event => onImport(event),
            },
            ...extraActions,
          ]}
          cellEditable={
            options.cellEditable
              ? {
                onCellEditApproved: (newData, rowData, columnDef) => new Promise((resolve) => {
                  onChange({ ...rowData, [columnDef.field]: newData });
                  resolve();
                }),
              }
              : null
          }
          editable={editable}
          options={{
            pageSizeOptions: [10, 20, 50, options.pageSize ? options.pageSize : 100],
            searchFieldAlignment: 'left',
            selectionProps: rowData => ({
              disabled: options.editableRowValidation?.(rowData),
            }),
            ...config,
            ...options,
          }}
          localization={localization(translate)}
          {...propRest}
        />
      </MuiThemeProvider>
    </div>
  );
};

PF2MDataTable.whyDidYouRender = true;

const ActionPropType = PropTypes.shape({
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.elementType,
  ]),
  iconProps: PropTypes.object,
  isFreeAction: PropTypes.bool,
  onClick: PropTypes.func,
  tooltip: PropTypes.string,
});

PF2MDataTable.propTypes = {
  data: PropTypes.array,
  onAdd: PropTypes.func,
  onChange: PropTypes.func,
  onDiscard: PropTypes.func,
  onApply: PropTypes.func,
  onDelete: PropTypes.func,
  onInactivateAll: PropTypes.func,
  columns: PropTypes.array,
  options: PropTypes.object,
  extraActions: PropTypes.arrayOf(ActionPropType),
  defaultEditable: PropTypes.bool,
  onExport: PropTypes.func,
  onImport: PropTypes.func,
  isEditable: PropTypes.func,
};

PF2MDataTable.defaultProps = {
  data: [],
  columns: [],
  onAdd: () => { },
  onChange: () => { },
  onDiscard: () => { },
  onApply: () => { },
  onDelete: () => { },
  onInactivateAll: null,
  options: {},
  onExport: undefined,
  onImport: undefined,
  extraActions: [],
  defaultEditable: true,
  isEditable: () => true,
};

export default PF2MDataTable;
export {
  icons,
};
