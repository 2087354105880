import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PF2MTable from '~/components/PF2MTable';
import PF2MSearchSelectOutlined from '~/components/PF2MSearchSelectOutlined';
import Footer from '~/pages/Manager/Footer';
import Header from '~/pages/Manager/Header';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import {
  getEquipments,
  getEquipmentsTemplate,
  exportEquipments,
  importEquipments,
} from '~/store/manager/actions';

const EquipmentsTab = ({
  classes,
  equipments,
  getEquipments: getAll,
  getEquipmentsTemplate: getTemplateAction,
  exportEquipments: exportAction,
  importEquipments: importAction,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [equipmentType, setEquipmentType] = useState(0);
  const [equipmentTypes, setEquipmentTypes] = useState([]);
  const { t: translate } = useTranslation();
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 15;

  useEffect(() => {
    async function fetchData() {
      await getAll();
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, getAll]);

  useEffect(() => {
    const types = [...new Set(equipments.filter(e => e.type_name).map(e => e.type_name))];
    setEquipmentTypes(types);
  }, [equipments]);

  const columns = [
    { field: 'type_name', title: translate('common:Group') },
    { field: 'group_name', title: translate('common:Type') },
    { field: 'name', title: translate('common:Name') },
    { field: 'description', title: translate('common:Description') },
    { field: 'password', title: translate('common:Password') },
    { field: 'weighing_tare', title: translate('common:WeighingTare') },
    { field: 'license_plate', title: translate('common:Plate') },
    { field: 'active_name', title: 'STATUS' },
  ];

  const handleEquipmentTypeChange = (e) => {
    setEquipmentType(e.target.value);
  };

  const renderEquipmentTypes = () => [{ value: '0', label: translate('common:Select') }]
    .concat(...equipmentTypes.map(e => ({ value: e, label: e })));

  return (
    <div className={classes.tabContainer}>
      <div>
        <p className={classes.labelTypeSelector}>
          {translate('common:EquipmentType')}
        </p>
        <PF2MSearchSelectOutlined
          value={(equipmentType || 0).toString()}
          onChange={handleEquipmentTypeChange}
          className={classes.typeSelector}
        >
          {renderEquipmentTypes()}
        </PF2MSearchSelectOutlined>
      </div>
      <Header
        getTemplateAction={getTemplateAction}
        importAction={importAction}
        exportAction={exportAction}
      />
      <PF2MTable
        data={equipments.filter(e => equipmentType === 0 || e.type_name === equipmentType)}
        columns={columns}
        className={classes.grid}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
      />
      <Footer
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        size={equipments.filter(e => equipmentType === 0 || e.type_name === equipmentType).length}
      />
    </div>
  );
};

EquipmentsTab.propTypes = {
  classes: PropTypes.object.isRequired,
  equipments: PropTypes.array,
  getEquipments: PropTypes.func,
  getEquipmentsTemplate: PropTypes.func,
  exportEquipments: PropTypes.func,
  importEquipments: PropTypes.func,
};

EquipmentsTab.defaultProps = {
  equipments: [],
  getEquipments: () => { },
  getEquipmentsTemplate: () => { },
  exportEquipments: () => { },
  importEquipments: () => { },
};

const mapStateToProps = state => ({
  equipments: state.manager.equipments,
});

const mapDispatchToProps = {
  getEquipments,
  getEquipmentsTemplate,
  exportEquipments,
  importEquipments,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EquipmentsTab));
