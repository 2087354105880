/* eslint-disable prefer-destructuring */
import React from 'react';
import {
  TableCell,
  TableRow,
  Fab,
  withStyles,
  TextField,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { KeyboardDatePicker } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import PF2MSearchSelect from '~/components/PF2MSearchSelect';
import PF2MMaskTextField from '~/components/PF2MMaskTextField';
import styles from '../styles';
import {
  formatDateToServerFormat,
  parseDateToServerFormat,
} from '~/utils/moment';

const ListRow = ({
  data,
  classes,
  onEdit,
  onDelete,
  onRestore,
  refuelerGroups,
  refuelers,
  operatorGroups,
  operators,
  equipmentTypes,
  equipmentsGroups,
  equipments,
  exceptionTypes,
}) => {
  const { t: translate } = useTranslation();
  const renderNumberCell = (name, value = 0, item, editable = true) => {
    if (item.id < 1 || editable) {
      return (
        <NumberFormat
          customInput={TextField}
          value={value}
          InputProps={{ classes: { input: classes.input } }}
          onChange={e => onEdit(item, name, e.target.value)}
          disabled={item.update_status === 'D'}
        />
      );
    }
    return value > 0 ? Number(value).toFixed(2) : value;
  };

  // handle clearing outside if value can be changed outside of the component
  const handleMask = value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []);

  const renderDatePicker = (d, key) => (
    <KeyboardDatePicker
      keyboard
      clearable
      mask={handleMask}
      format={translate('date:DateFormat')}
      disableFuture
      autoOk
      onChange={e => onEdit(d, key, formatDateToServerFormat(e))}
      value={d[key] ? parseDateToServerFormat(d[key]) : ''}
      invalidDateMessage={translate('validation:InvalidDate')}
    />
  );

  const renderTime = (d, attr) => (
    <PF2MMaskTextField
      mask="99:99:99"
      value={d[attr]}
      onChange={e => onEdit(d, attr, e.target.value)}
    />
  );

  const renderExceptionType = (idExceptionType) => {
    const { name = '' } = exceptionTypes.find(e => e.id === idExceptionType) || {};
    return name;
  };

  return (
    <TableRow>
      <TableCell className={classes.tableCell}>
        {renderDatePicker(data, 'start_date')}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderTime(data, 'start_time')}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderDatePicker(data, 'end_date')}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderTime(data, 'end_time')}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.operator_group_id}
          onChange={e => onEdit(data, 'operator_group_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {operatorGroups
            .map(e => ({ label: e.name, value: e.id }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.operator_id}
          onChange={e => onEdit(data, 'operator_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {operators
            .filter(o => data.operator_group_id === 0 || o.id_group === data.operator_group_id)
            .map(e => ({ value: e.id_operator, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.equip_group_id}
          onChange={e => onEdit(data, 'equip_group_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {refuelerGroups
            .map(e => ({ label: e.name, value: e.id }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.equip_id}
          onChange={e => onEdit(data, 'equip_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {refuelers
            .filter(o => data.equip_group_id === 0 || o.id_group === data.equip_group_id)
            .map(e => ({ label: e.name, value: e.id }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.equip_type_fueled}
          onChange={e => onEdit(data, 'equip_type_fueled', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {equipmentTypes
            .map(e => ({ label: e.name, value: e.id }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.equip_group_id_fueled}
          onChange={e => onEdit(data, 'equip_group_id_fueled', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {equipmentsGroups
            .filter(d => data.equip_type_fueled === 0 || d.id_equipament === data.equip_type_fueled)
            .map(e => ({ label: e.name, value: e.id }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.equip_id_fueled}
          onChange={e => onEdit(data, 'equip_id_fueled', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {equipments
            .filter(d => data.equip_group_id_fueled === 0
              || d.id_group === data.equip_group_id_fueled)
            .map(e => ({ label: e.name, value: e.id }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.operator_group_id_fueled}
          onChange={e => onEdit(data, 'operator_group_id_fueled', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {operatorGroups
            .map(e => ({ label: e.name, value: e.id }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.operator_id_fueled}
          onChange={e => onEdit(data, 'operator_id_fueled', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {operators
            .filter(o => data.operator_group_id_fueled === 0
              || o.id_group === data.operator_group_id_fueled)
            .map(e => ({ label: e.name, value: e.id_operator }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('latitude_start', data.latitude_start, data)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('longitude_start', data.longitude_start, data)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('element_id_start', data.element_id_start, data)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('element_point_id_start', data.element_point_id_start, data)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('element_id_end', data.element_id_end, data)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('latitude_end', data.latitude_end, data)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('longitude_end', data.longitude_end, data)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('element_point_id_end', data.element_point_id_end, data)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('hourmeter_fueled', data.hourmeter_fueled, data)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('kilometer_fueled', data.kilometer_fueled, data)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('liters_fueled', data.liters_fueled, data)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('code_time', data.code_time, data)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('refuel_time', data.refuel_time, data)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('waiting_time', data.waiting_time, data)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('refuel_distance', data.refuel_distance, data)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderExceptionType(data.exception_type)}
      </TableCell>
      <TableCell style={{
        position: 'sticky',
        right: 0,
        height: 48,
      }}
      >
        {data.isDirty ? (
          <Fab
            size="small"
            color="primary"
            aria-label="Restore"
            onClick={() => onRestore(data)}
            disabled={!onRestore}
          >
            <RestoreIcon />
          </Fab>
        ) : (
          <Fab
            size="small"
            color="primary"
            aria-label="Delete"
            onClick={() => onDelete(data)}
            disabled={!onDelete}
          >
            <DeleteIcon />
          </Fab>
        )}
      </TableCell>
    </TableRow>
  );
};

ListRow.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onRestore: PropTypes.func,
  refuelerGroups: PropTypes.array,
  refuelers: PropTypes.array,
  operatorGroups: PropTypes.array,
  operators: PropTypes.array,
  equipmentTypes: PropTypes.array,
  equipmentsGroups: PropTypes.array,
  equipments: PropTypes.array,
  exceptionTypes: PropTypes.array,
};

ListRow.defaultProps = {
  data: {},
  classes: {},
  onEdit: null,
  onDelete: null,
  onRestore: null,
  refuelerGroups: [],
  refuelers: [],
  operatorGroups: [],
  operators: [],
  equipmentTypes: [],
  equipmentsGroups: [],
  equipments: [],
  exceptionTypes: [],
};

export default withStyles(styles)(ListRow);
