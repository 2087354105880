import React, { useState, useEffect, useMemo } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchStatus, DispatchDragNDropTypes } from '~/utils';
import { useTranslation } from 'react-i18next';
import { HelpOutline } from '@material-ui/icons';
import styles from '../styles';
import PF2MScrollbar from '~/components/PF2MScrollbar';
import PF2MEquipmentPool from '~/components/PF2MEquipmentPool';
import DestinationRow from './DestinationRow';
import {
  getTabletConfiguration,
  getEquipmentTypes,
  getEquipmentsGroups,
  getElements,
  getSubElements,
} from '~/store/manager/actions';
import {
  getDispatchDestinationsTrucks,
  getDispatchDestinationsElements,
  updateTruckAllocations,
  setSelectedTruckAllocation,
  setSelectedLoadAllocation,
  getEquipmentsSchedules,
  getEquipmentScheduleStatuses,
} from '~/store/dispatch/actions';
import DestinationTruckModal, { DestinationElementModal } from './DestinationModal';
import PF2MTooltip from '~/components/PF2MTooltip';
import DispatchLabels from '../DispatchLabels';
import TruckOperators from '../TruckOperatorStatus';
import ContextMenu, { PF2MMenuItem, useContextMenu } from '~/components/PF2MMenuContext';
import CollapseEquipmentList from '../CollapseEquipmentList';
import { TruckAllocationModal, LoadAllocationModal } from '../../LoadSchedules/AllocationsTab/AllocationModal';
import { newMomentDate } from '~/utils/moment';
import PF2MEquipmentScheduleModal from '~/components/PF2MEquipmentScheduleModal';
import { withModal } from '~/components/PF2MModal';
import PF2MWarnFeatureFlag from '~/PF2MWarnFeatureFlag';
import usePlatformConfiguration from '~/services/platformConfiguration';

const GENERIC_DESTINATION = {
  id: null,
  name: '',
  id_element: 0,
  active: 1,
};

// No meaning to have validation for one line temporary toggle feature
// eslint-disable-next-line react/destructuring-assignment, react/prop-types
const OffComp = props => <div>{props.children}</div>;

const TRUCK_TYPE_ID = 2;

const EquipmentScheduleModal = withModal(PF2MEquipmentScheduleModal);

const DestinationsTab = ({ classes }) => {
  const dispatch = useDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);
  const { t: translate } = useTranslation();

  const destinationRows = useSelector(state => state.dispatch.elementDestinations);
  const tabletConfiguration = useSelector(state => state.manager.tabletConfiguration);
  const equipmentsSchedules = useSelector(state => state.dispatch.equipmentsSchedules);

  const [isDestinationTruckModalOpen, setIsDestinationTruckModalOpen] = useState(false);
  const [isDestinationElementModalOpen, setIsDestinationElementModalOpen] = useState(false);
  const [truckContextMenuData, openTruckContextMenu, closeTruckContextMenu] = useContextMenu();
  const [truckAllocationModalData, setTruckAllocationModalData] = useState();
  const [loadContextMenuData, openLoadContextMenu, closeLoadContextMenu] = useContextMenu();
  const [loadAllocationModalData, setLoadAllocationModalData] = useState();
  const [equipmentScheduleModal, setEquipmentScheduleModal] = useState(false);
  const processDisptachIndicatorsTime = (
    usePlatformConfiguration('process_dispatch_indicators_time')?.value || '0'
  );

  const availablePoolEquips = useSelector(
    state => [
      ...state.dispatch.truckOrigins
        .map(TruckOperators)
        .filter(st => st.isHealthy && !st.isOnCicle && !st.isOnQuickPause)
        .map(({ to }) => to),
      ...state.dispatch.excavatorOrigins
        .filter(r => !r.priority
          && (r.status === DispatchStatus.PO || r.status === DispatchStatus.IN)),
    ].filter(r => r.equip_type_id === TRUCK_TYPE_ID),
  );
  const maintenancePoolEquips = useSelector(
    state => [
      ...state.dispatch.truckOrigins
        .map(TruckOperators)
        .filter(st => !st.isHealthy)
        .map(({ to }) => to),
      ...state.dispatch.excavatorOrigins
        .filter(r => !r.priority && (r.status === DispatchStatus.MA)),
    ].filter(r => r.equip_type_id === TRUCK_TYPE_ID),
  );
  const leftLaneTrucks = useSelector(state => state.dispatch.truckOrigins
    .map(TruckOperators)
    .filter(st => st.isHealthy)
    .filter(st => st.isOnCicle || st.isOnQuickPause)
    .filter(st => st.isLoading)
    .map(({ to }) => to));

  const rightLaneTrucks = useSelector(state => state.dispatch.truckOrigins
    .map(TruckOperators)
    .filter(st => st.isHealthy)
    .filter(st => st.isOnCicle || st.isOnQuickPause)
    .filter(st => !st.isLoading)
    .map(({ to }) => to));

  useEffect(() => {
    async function fetchData() {
      dispatch(getDispatchDestinationsTrucks());
      dispatch(getDispatchDestinationsElements());
      dispatch(getTabletConfiguration());
      dispatch(getEquipmentTypes());
      dispatch(getEquipmentsGroups());
      dispatch(getElements());
      dispatch(getSubElements());
      dispatch(getEquipmentsSchedules());
      dispatch(getEquipmentScheduleStatuses());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch]);

  const equipScheduleModalData = useMemo(() => {
    if (!equipmentsSchedules || !equipmentScheduleModal) {
      return {};
    }
    return equipmentsSchedules
      .find(e => (e.group_equipament_links_id === equipmentScheduleModal.equip_id)) || {
      ...equipmentScheduleModal,
      equipaments_id: equipmentScheduleModal.equip_type_id,
      equipament_groups_id: equipmentScheduleModal.equip_group_id,
      group_equipament_links_id: equipmentScheduleModal.equip_id,
    };
  }, [equipmentScheduleModal, equipmentsSchedules]);

  const allocateTruck = (_e, item) => {
    dispatch(setSelectedTruckAllocation(item));
    setTruckAllocationModalData(true);
  };
  const allocateLoader = (_e, item) => {
    dispatch(setSelectedLoadAllocation(item));
    setLoadAllocationModalData(true);
  };
  const editTruckSchedule = (_e, item) => {
    setEquipmentScheduleModal(item);
  };
  const editLoadSchedule = (_e, item) => {
    setEquipmentScheduleModal(item);
  };

  useEffect(() => {
    if (tabletConfiguration.find(k => k.key === 'dispatch_update_time')) {
      const time = tabletConfiguration.find(k => k.key === 'dispatch_update_time').value * 1000;
      const interval = setInterval(() => {
        setDataLoaded(false);
      }, time);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [tabletConfiguration]);

  const renderTableHeader = () => (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: 50,
      }}
    >
      <div style={{
        display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <span className={classes.labelTypeSelector}>{translate('common:LoadEquipment')}</span>
      </div>
      <div style={{
        width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      />
      <div style={{
        marginLeft: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center',
      }}
      >
        <PF2MTooltip
          title={(<DispatchLabels />)}
        >
          <HelpOutline fontSize="small" style={{ marginRight: '5px' }} />
        </PF2MTooltip>
        <span className={classes.labelTypeSelector}>{translate('common:Element')}</span>
      </div>
    </div>
  );

  const renderGenericRow = () => {
    const workingDestinations = destinationRows.map(r => r.id);
    const leftLane = leftLaneTrucks
      .filter(r => !workingDestinations.includes(r.current_destination_point_id));
    const rightLane = rightLaneTrucks
      .filter(r => !workingDestinations.includes(r.current_destination_point_id));

    if (!leftLane.length && !rightLane.length) return null;
    return (
      <DestinationRow
        destination={GENERIC_DESTINATION}
        leftLane={leftLane}
        rightLane={rightLane}
        isGenericRow
        destinations={destinationRows}
        openTruckModal={setIsDestinationTruckModalOpen}
        openElementModal={setIsDestinationElementModalOpen}
        onTruckContextMenu={openTruckContextMenu}
      />
    );
  };

  const shouldShowRow = (destinationRow, allLaneTrucks) => {
    if (destinationRow.is_programmed) return true;
    return allLaneTrucks.some(r => r.current_destination_point_id === destinationRow.id);
  };

  const renderRows = () => (
    <div style={{ width: '100%', maxHeight: 500 }}>
      {renderTableHeader()}
      <PF2MScrollbar style={{
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        maxHeight: 500,
        height: 'auto',
      }}
      >
        <>
          {destinationRows
            .filter(r => shouldShowRow(r, [...leftLaneTrucks, ...rightLaneTrucks]))
            .map(r => (
              <DestinationRow
                key={r.id}
                destination={r}
                leftLane={leftLaneTrucks.filter(x => x.current_destination_point_id === r.id)}
                rightLane={rightLaneTrucks.filter(x => x.current_destination_point_id === r.id)}
                destinations={destinationRows}
                onTruckDrop={(item, destinationId) => {
                  dispatch(updateTruckAllocations(item, 'destination_point_id', destinationId, false, true));
                }}
                openTruckModal={setIsDestinationTruckModalOpen}
                openElementModal={setIsDestinationElementModalOpen}
                onTruckContextMenu={openTruckContextMenu}
              />
            ))}
          {renderGenericRow()}
        </>
      </PF2MScrollbar>
    </div>
  );

  const renderPools = () => (
    <div style={{ display: 'flex', width: '100%' }}>
      <div style={{
        display: 'flex',
        marginTop: 10,
        width: '100%',
      }}
      >
        <PF2MEquipmentPool
          equips={availablePoolEquips}
          destinations={destinationRows}
          onEquipClick={() => null}
          onItemDropped={() => null}
          headerText={translate('common:AvailableEquipments').toUpperCase()}
          truckItemType={DispatchDragNDropTypes.POOL_TRUCK}
          acceptDrop={false}
          extraInfoKey={'destination_point_name'}
          onContextMenu={{
            truck: openTruckContextMenu,
            loader: openLoadContextMenu,
          }}
        />
        <PF2MEquipmentPool
          equips={maintenancePoolEquips}
          destinations={destinationRows}
          onEquipClick={() => null}
          onItemDropped={() => null}
          headerText={translate('common:MaintenanceEquipments').toUpperCase()}
          customStyles={{ marginLeft: 10 }}
          truckItemType={DispatchDragNDropTypes.POOL_TRUCK}
          acceptDrop={false}
          extraInfoKey={'destination_point_name'}
          onContextMenu={{
            truck: openTruckContextMenu,
            loader: openLoadContextMenu,
          }}
        />
      </div>
    </div>
  );

  const children = (
    <div className={classes.tabContainer}>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '82vh',
        }}
      >
        {renderRows()}
        {renderPools()}
      </div>
      <DestinationTruckModal
        isOpen={isDestinationTruckModalOpen}
        close={() => setIsDestinationTruckModalOpen(false)}
      />
      <DestinationElementModal
        i
        sOpen={isDestinationElementModalOpen}
        close={() => setIsDestinationElementModalOpen(false)}
      />
      <ContextMenu {...truckContextMenuData} close={closeTruckContextMenu}>
        <PF2MMenuItem onClick={allocateTruck}>
          {translate('common:AddEditAllocation')}
        </PF2MMenuItem>
        <PF2MMenuItem onClick={editTruckSchedule}>
          {translate('common:EditEquipmentScheduling')}
        </PF2MMenuItem>
      </ContextMenu>
      <LoadAllocationModal
        isOpen={loadAllocationModalData}
        close={() => setLoadAllocationModalData(null)}
      />
      <ContextMenu {...loadContextMenuData} close={closeLoadContextMenu}>
        <PF2MMenuItem onClick={allocateLoader}>
          {translate('common:AddEditAllocation')}
        </PF2MMenuItem>
        <PF2MMenuItem onClick={editLoadSchedule}>
          {translate('common:EditEquipmentScheduling')}
        </PF2MMenuItem>
      </ContextMenu>
      <TruckAllocationModal
        modalData={truckAllocationModalData}
        closeModal={() => setTruckAllocationModalData(null)}
      />
      {equipScheduleModalData && (
        <EquipmentScheduleModal
          modalData={equipmentScheduleModal}
          closeModal={() => setEquipmentScheduleModal(false)}
          settings={{
            skipBulkUpdate: true,
          }}
          fixed={{
            equipmentType: equipScheduleModalData.equipaments_id || 0,
            equipmentGroup: equipScheduleModalData.equipament_groups_id || 0,
            equipment: equipScheduleModalData.group_equipament_links_id || 0,
            startTime: newMomentDate(),
            duration: '00:30:00',
            element: equipScheduleModalData.flowchart_element_id || 0,
            subElement: equipScheduleModalData.flowchart_element_point_id || 0,
          }}
        />
      )}
    </div>
  );

  return (
    <PF2MWarnFeatureFlag
      flag={processDisptachIndicatorsTime >= 1}
      featureName="DispatchIndicators (process_dispatch_indicators_time >= 1)"
      OffComponent={OffComp}
      OnComponent={CollapseEquipmentList}
      props={{
        classes: { container: classes.tabContainer },
        children,
      }}
    />
  );
};

DestinationsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

DestinationsTab.defaultProps = {};

export default withStyles(styles)(DestinationsTab);
