import React, { useState, useEffect } from 'react';
import { withStyles, Modal } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  setEquipmentsScheduleValue,
  updateEquipmentsSchedules,
  getEquipmentsSchedules,
  getPreventiveTypes,
  getEquipmentScheduleStatuses,
  getEquipmentsSchedulesTemplate,
  importEquipmentsSchedules,
  exportEquipmentsSchedules,
} from '~/store/dispatch/actions';
import {
  getEquipmentTypes,
  getEquipmentsGroups,
  getEquipments,
  getElements,
  getSubElements,
  getCodeGroups,
  getCodeTypes,
  getCodes,
  getPlatformConfiguration,
} from '~/store/manager/actions';
import { Add, SearchOutlined, Waves } from '@material-ui/icons';
import styles from '../styles';
import PF2MAlertDialog from '~/components/PF2MAlertDialog';
import PF2MButton from '~/components/PF2MButton';
import PF2MKanban from '~/components/PF2MKanban/PF2MKanban';
import Header from '~/pages/Dispatch/Header';
import EquipmentProgramsFilterModal from '../EquipmentProgramsFilterModal';
import EquipmentProgramsKanbanModal from '../EquipmentProgramsKanbanModal';
import PF2MEquipmentScheduleModal from '~/components/PF2MEquipmentScheduleModal';
import RainModeModal from '~/components/RainModeModal';

const EquipmentProgramsTab = ({ classes }) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const platformConfiguration = useSelector(state => state.manager.platformConfiguration);
  const [modalOpen, setModalOpen] = useState(0);
  const equipmentsSchedules = useSelector(state => state.dispatch.equipmentsSchedules);
  const currentFilter = useSelector(state => state.dispatch.currentEquipmentSchedulesFilter);
  const [kanbanModalInfo, setKanbanModalInfo] = useState([]);

  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    async function fetchData() {
      dispatch(getCodeTypes());
      dispatch(getCodeGroups());
      dispatch(getCodes());
      dispatch(getEquipmentTypes());
      dispatch(getEquipmentsGroups());
      dispatch(getEquipments());
      dispatch(getElements(false, { active: 1 }));
      dispatch(getSubElements(false, { active: 1 }));
      dispatch(getPreventiveTypes());
      dispatch(getEquipmentsSchedules());
      dispatch(getEquipmentScheduleStatuses());
      dispatch(getPlatformConfiguration());
    }
    if (!dataLoaded) {
      fetchData();
      setDataLoaded(true);
    }
  }, [dataLoaded, dispatch, currentFilter]);

  useEffect(() => {
    if (platformConfiguration.find(k => k.key === 'equipment_scheduling_update_time')) {
      const time = platformConfiguration.find(k => k.key === 'equipment_scheduling_update_time').value * 1000;
      const interval = setInterval(() => {
        setDataLoaded(false);
      }, time);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [platformConfiguration]);

  const handleKanbanCardModal = (c) => {
    setKanbanModalInfo(c);
    setModalOpen(3);
  };

  const stages = [
    { title: translate('common:NotStarted'), id: 1 },
    { title: translate('common:Disapproved'), id: 5, style: 'danger' },
    { title: translate('common:Paused'), id: 6 },
    { title: translate('common:InProgress'), id: 2, style: 'highlight' },
    { title: translate('common:OnApproval'), id: 3 },
    { title: translate('common:Finalized'), id: 4, style: 'disabled' },
  ];

  const content = {
    title: 'group_equipament_links_name',
    startDate: 'start_date',
    startTime: 'start_time',
    codeGroup: 'code_groups_name',
    codeType: 'code_type_name',
    equipType: 'equipament_type_name',
    equipGroup: 'equipament_groups_name',
  };

  const renderHeader = () => (
    <div style={{ display: 'flex', justifyContent: 'flex-start', paddingBottom: 20 }}>
      <PF2MButton
        type="button"
        size="medium"
        onClick={() => setModalOpen(2)}
      >
        <SearchOutlined className={classes.icon} />
        {translate('common:Filter')}
      </PF2MButton>
      <PF2MButton
        type="button"
        size="medium"
        onClick={() => setModalOpen(1)}
      >
        <Add className={classes.icon} />
        {translate('common:NewSchedule')}
      </PF2MButton>
      <Header
        getTemplateAction={() => dispatch(getEquipmentsSchedulesTemplate())}
        importAction={file => dispatch(importEquipmentsSchedules(file, currentFilter))}
        exportAction={() => dispatch(exportEquipmentsSchedules())}
        messageStyle={{ paddingTop: 10, paddingLeft: 10 }}
        actionButton={{
          label: translate('common:RainMode'),
          icon: <Waves className={classes.icon} />,
          onClick: () => setModalOpen(4),

        }}
      />
    </div>
  );

  return (
    <div className={classes.tabContainer}>
      {renderHeader()}
      <PF2MKanban
        data={equipmentsSchedules}
        stageColumn={'status_id'}
        stages={stages}
        content={content}
        onClickItem={handleKanbanCardModal}
      />
      <Modal
        open={modalOpen === 4}
        onClose={(_, reason) => reason === 'backdropClick' && setModalOpen(0)}
        className={classes.modal}
      >
        <div>
          <RainModeModal setModalOpen={setModalOpen} />
        </div>
      </Modal>
      <Modal
        open={modalOpen === 3}
        onClose={(_, reason) => reason === 'backdropClick' && setModalOpen(0)}
        className={classes.modal}
      >
        <div>
          <EquipmentProgramsKanbanModal
            data={kanbanModalInfo}
            setModalOpen={setModalOpen}
            onRemove={(data) => { dispatch(setEquipmentsScheduleValue({ ...data, active: 0 })); }}
            onChange={(data, value, field) => {
              dispatch(setEquipmentsScheduleValue({ ...data, [field]: value }));
            }}
          />
        </div>
      </Modal>
      <Modal
        open={modalOpen === 2}
        onClose={(_, reason) => reason === 'backdropClick' && setModalOpen(0)}
        className={classes.modal}
      >
        <div>
          <EquipmentProgramsFilterModal setModalOpen={setModalOpen} />
        </div>
      </Modal>
      <Modal
        open={modalOpen === 1}
        onClose={(_, reason) => reason === 'backdropClick' && setModalOpen(0)}
        className={classes.modal}
      >
        <div>
          <PF2MEquipmentScheduleModal setModalOpen={setModalOpen} />
        </div>
      </Modal>
      <PF2MAlertDialog
        confirmText={translate('common:Yes')}
        description={translate('validation:WarningScheduleChange')}
        open={showAlert}
        onClose={() => setShowAlert(false)}
        onConfirm={() => {
          setShowAlert(false);
          dispatch(updateEquipmentsSchedules(equipmentsSchedules, currentFilter));
        }}
      />
    </div>
  );
};

EquipmentProgramsTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

EquipmentProgramsTab.defaultProps = {};

export default withStyles(styles)(EquipmentProgramsTab);
