/* eslint-disable prefer-destructuring */
import React from 'react';
import {
  TableCell,
  TableRow,
  Fab,
  withStyles,
  TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import RestoreIcon from '@material-ui/icons/Restore';
import PropTypes from 'prop-types';
import { KeyboardDatePicker } from '@material-ui/pickers';
import NumberFormat from 'react-number-format';
import PF2MSearchSelect from '~/components/PF2MSearchSelect';
import PF2MMaskTextField from '~/components/PF2MMaskTextField';
import {
  formatDateToServerFormat,
  parseDateToServerFormat,
} from '~/utils/moment';
import styles from '../styles';

const ListRow = ({
  data,
  classes,
  onEdit,
  onDelete,
  onRestore,
  turns,
  teams,
  equipmentsGroups,
  equipments,
  operatorsGroups,
  operators,
  exceptionTypes,
}) => {
  const { t: translate } = useTranslation();

  const renderNumberCell = (name, value = 0, item, editable = true, convertnumber = 1) => {
    const convertednumber = value / convertnumber;
    if (item.id < 1 || editable) {
      return (
        <NumberFormat
          customInput={TextField}
          value={convertednumber}
          InputProps={{ classes: { input: classes.input } }}
          onChange={e => onEdit(item, name, e.target.value * convertnumber)}
          disabled={item.update_status === 'D'}
        />
      );
    }
    return convertednumber > 0 ? Number(convertednumber).toFixed(2) : convertednumber;
  };

  // handle clearing outside if value can be changed outside of the component
  const handleMask = value => (value ? [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] : []);

  const renderDatePicker = (d, key) => (
    <KeyboardDatePicker
      clearable
      mask={handleMask}
      format={translate('date:DateFormat')}
      disableFuture
      autoOk
      onChange={e => onEdit(d, key, formatDateToServerFormat(e))}
      value={d[key] ? parseDateToServerFormat(d[key]) : ''}
      invalidDateMessage={translate('validation:InvalidDate')}
    />
  );

  const renderTime = (d, attr) => (
    <PF2MMaskTextField
      mask="99:99:99"
      value={d[attr]}
      onChange={e => onEdit(d, attr, e.target.value)}
    />
  );

  const renderExceptionType = (idExceptionType) => {
    const { name = '' } = exceptionTypes.find(e => e.id === idExceptionType) || {};
    return name;
  };
  return (
    <TableRow>
      <TableCell className={classes.tableCell}>
        {renderDatePicker(data, 'start_date')}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderDatePicker(data, 'end_date')}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderTime(data, 'start_time')}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderTime(data, 'end_time')}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('duration', data.duration, data, true, 60)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.team_id}
          onChange={e => onEdit(data, 'team_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {teams
            .map(e => ({ value: e.id, label: `${e.team_group_name} - ${e.name}` }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.turn_id}
          onChange={e => onEdit(data, 'turn_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {turns
            .map(e => ({ value: e.id, label: `${e.turn_group_name} - ${e.name}` }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.equip_group_id}
          onChange={e => onEdit(data, 'equip_group_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {equipmentsGroups
            .filter(e => e.id === data.equip_group_id)
            .map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.group_equipament_links_id}
          onChange={e => onEdit(data, 'group_equipament_links_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {equipments
            .filter(e => e.id_group === data.equip_group_id)
            .map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.operator_group_id}
          onChange={e => onEdit(data, 'operator_group_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {operatorsGroups
            .map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.operator_id}
          onChange={e => onEdit(data, 'operator_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {operators
            .filter(e => e.id_group === data.operator_group_id)
            .map(e => ({ value: e.id_operator, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PF2MSearchSelect
          value={data.truck_id}
          onChange={e => onEdit(data, 'truck_id', e.target.value)}
          disabled={data.update_status === 'D'}
        >
          {equipments
            .map(e => ({ value: e.id, label: e.name }))}
        </PF2MSearchSelect>
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('batch_number', data.batch_number, data, true)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('load_manager', data.load_manager, data, true)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('stop_time', data.stop_time, data, true, 60)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('load_time', data.load_time, data, true, 60)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderNumberCell('code_time', data.code_time, data, true, 60)}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {renderExceptionType(data.exception_type)}
      </TableCell>
      <TableCell style={{
        position: 'sticky',
        right: 0,
        height: 48,
      }}
      >
        {data.isDirty ? (
          <Fab
            size="small"
            color="primary"
            aria-label="Restore"
            onClick={() => onRestore(data)}
            disabled={!onRestore}
          >
            <RestoreIcon />
          </Fab>
        ) : (
          <Fab
            size="small"
            color="primary"
            aria-label="Delete"
            onClick={() => onDelete(data)}
            disabled={!onDelete}
          >
            <DeleteIcon />
          </Fab>
        )}
      </TableCell>
    </TableRow>
  );
};

ListRow.propTypes = {
  data: PropTypes.object,
  classes: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onRestore: PropTypes.func,
  turns: PropTypes.array,
  teams: PropTypes.array,
  equipmentsGroups: PropTypes.array,
  equipments: PropTypes.array,
  operatorsGroups: PropTypes.array,
  operators: PropTypes.array,
  exceptionTypes: PropTypes.array,
};

ListRow.defaultProps = {
  data: {},
  classes: {},
  onEdit: null,
  onDelete: null,
  onRestore: null,
  turns: [],
  teams: [],
  operatorsGroups: [],
  operators: [],
  equipmentsGroups: [],
  equipments: [],
  exceptionTypes: [],
};

export default withStyles(styles)(ListRow);
