import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import loadingMiddleware from 'redux-loading-middleware';
import loadingReducer from 'redux-loading-middleware/loadingReducer';
import auth from './auth/reducers';
import dailyParts from './dailyparts/reducers';
import domains from './domains/reducers';
import manager from './manager/reducers';
import fuel from './fuel/reducers';
import contracts from './contracts/reducers';
import underground from './underground/reducers';
import maintenance from './maintenance/reducers';
import dispatch from './dispatch/reducers';
import telemetry from './telemetry/reducers';
import quality from './quality/reducers';
import errors from './errors/reducers';
import lineup from './lineup/reducers';
import terrain from './terrain/reducers';
import admin from './admin/reducers';
import skipLoading from './skipLoading';
import goals from './goals/reducers';
import chat from './chat/reducers';

export const rootReducer = combineReducers({
  auth,
  dailyParts,
  errors,
  domains,
  manager,
  fuel,
  contracts,
  underground,
  maintenance,
  dispatch,
  quality,
  lineup,
  terrain,
  telemetry,
  loadingReducer,
  skipLoading,
  goals,
  admin,
  chat,
});

export const middlewares = composeWithDevTools(applyMiddleware(loadingMiddleware, thunk));

const store = createStore(
  rootReducer,
  middlewares,
);

export default store;
