import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from './styles';
import Main from '../Main';
import SearchForm from '~/pages/DailyParts/SearchForm';
import { ZendeskAPI } from 'react-zendesk';

const Home = ({ history }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      ZendeskAPI('webWidget', 'hide');
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Main style={{ backgroundColor: '#E6E7E8' }} hasLoading={false}>
      <div
        className="container col-md-8 col-sm-12"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SearchForm history={history} />
      </div>
    </Main>
  );
};

Home.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withStyles(styles)(Home);
