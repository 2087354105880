import React, { useState } from 'react';
import {
  withStyles, Grid, Typography, Modal,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import styles from './styles';
import PF2MSearchButton from '~/components/PF2MSearchButton';
import SearchForm from '../SearchForm';

function SearchHeader({ classes }) {
  const { t: translate } = useTranslation();
  const filters = useSelector(state => state.dailyParts.filters);
  const turn = useSelector(state => state.manager.turns.find(r => r.id === filters.turn));
  const equipmentType = useSelector(
    state => state.manager.equipmentTypes.find(r => r.id === filters.equipmentType),
  );
  const equipmentGroup = useSelector(
    state => state.manager.equipmentsGroups.find(r => r.id === filters.equipmentGroup),
  );
  const equipment = useSelector(
    state => state.manager.equipments.find(r => r.id === filters.equipment),
  );
  const operatorGroup = useSelector(
    state => state.manager.operatorsGroups.find(r => r.id === filters.operatorGroup),
  );
  const operator = useSelector(
    state => state.manager.operators.find(r => r.id_operator === filters.operator),
  );
  const material = useSelector(
    state => state.manager.materials.find(r => r.id === filters.material),
  );
  const startOrigin = useSelector(
    state => state.manager.elements.find(r => r.id === filters.startOrigin),
  );
  const endOrigin = useSelector(
    state => state.manager.elements.find(r => r.id === filters.endOrigin),
  );
  const [modalOpen, setModalOpen] = useState(false);

  const {
    startDate = moment(new Date()),
    endDate = moment(new Date()),
  } = filters;

  return (
    <div className="container">
      <Grid container className={classes.container}>
        <Grid item container xs className={classes.headerData}>
          <Grid item>
            <Typography variant="caption">
              {`${translate('common:ResultsFor')}:`}
            </Typography>
          </Grid>
          <Grid item container>
            <Typography className={classes.text}>
              <span style={styles.dataValue}>{`${startDate.format(translate('date:DateFormat'))} - ${endDate.format(translate('date:DateFormat'))}`}</span>
              <span>{turn ? `, ${translate('common:Shift')}` : null}</span>
              <span style={styles.dataValue}>{turn ? turn.name : null}</span>
              <span>{equipmentType ? `, ${translate('common:Equipment')} ${translate('common:Type')}` : null}</span>
              <span style={styles.dataValue}>
                {equipmentType ? `${equipmentType.name}` : null}
                {equipmentGroup ? `, ${equipmentGroup.name}` : null}
                {equipment ? `, ${equipment.name}` : null}
              </span>
              <span>{operatorGroup ? `, ${translate('common:Group')}` : null}</span>
              <span style={styles.dataValue}>{operatorGroup ? operatorGroup.name : null}</span>
              <span>{operator ? `, ${translate('common:Operator')}` : null}</span>
              <span style={styles.dataValue}>{operator ? `${operator.id} - ${operator.name}` : null}</span>
              <span>{material ? `, ${translate('common:Material')}` : null}</span>
              <span style={styles.dataValue}>{material ? material.name : null}</span>
              <span>{startOrigin ? `, ${translate('common:Origin')}` : null}</span>
              <span style={styles.dataValue}>{startOrigin ? startOrigin.name : null}</span>
              <span style={styles.dataValue}>{endOrigin ? endOrigin.name : null}</span>
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <PF2MSearchButton size="medium" onClick={() => setModalOpen(true)} />
        </Grid>
      </Grid>
      <Modal
        open={modalOpen}
        onClose={(_, reason) => {
          if (reason === 'backdropClick') {
            setModalOpen(false);
          }
        }}
      >
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        >
          <SearchForm
            isModal
            onSearch={() => setModalOpen(false)}
            onClose={() => setModalOpen(false)}
          />
        </div>
      </Modal>
    </div>
  );
}

SearchHeader.propTypes = {
  classes: PropTypes.object,
};

SearchHeader.defaultProps = {
  classes: {},
};

export default withStyles(styles)(SearchHeader);
