import { downloadAxios, convertToUrlParameters } from '../utils';
import {
  GET_ERRORS,
  GET_GENERIC_ERROR,
  SET_UPLOAD_ERROR,
  SET_UPLOAD_SUCCESS,
  SET_UPLOAD_SUCCESS_WARNING,
} from './errors/types';
import { logoutUser } from './auth/actions';

import api from '~/services/api';
import i18n from '~/i18n';

export const handleErrors = response => async (dispatch) => {
  if (response && response.status === 401) {
    // If any route returns 401, assume token is expired
    // and send user back to /login if he's not already there
    if (window.location.pathname !== '/login') {
      dispatch(logoutUser(true, window.location.pathname));
    }
  } else if (response?.data instanceof Blob && response?.data?.type === 'application/json') {
    const responseData = await response.data.text();
    const responseJson = (typeof responseData === 'string') ? JSON.parse(responseData) : responseData;
    dispatch({
      type: GET_ERRORS,
      payload: responseJson.errors,
    });
  } else {
    dispatch({
      type: GET_GENERIC_ERROR,
      payload: i18n.t('common:ServerError'),
    });
  }
};

export const getTemplate = domain => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/${domain}/files`;
    await downloadAxios(url, `template_${domain}.xlsx`);
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const exportData = (domain, filters = {}, argument = 'all') => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/${domain}/files/${argument}`;

    const { active, ...noActive } = filters;
    const newFilters = active === -1 ? noActive : filters;
    const queryString = convertToUrlParameters(newFilters);

    const endpointUrl = `${url}?${queryString}`;

    await downloadAxios(endpointUrl, `${domain}.xlsx`);
  } catch ({ response }) {
    dispatch(handleErrors(response));
  }
};

export const importData = (
  domain,
  file,
  getAll = null,
  filters = {},
) => async (dispatch) => {
  let clearUploadError = false;
  try {
    dispatch({ type: SET_UPLOAD_ERROR, payload: '' });
    const result = await api.put(`/${domain}/files`, file);
    const { data, status } = result;
    if (status === 200 && data.errors) {
      dispatch({
        type: SET_UPLOAD_SUCCESS_WARNING,
        payload: {
          success: `${i18n.t('validation:SheetImportSuccess')}\n${data.errors[0].message}`,
          error: data.errors[0].message,
        },
      });
    } else if (status === 200) {
      dispatch({ type: SET_UPLOAD_SUCCESS, payload: i18n.t('validation:SheetImportSuccess') });
    }
    clearUploadError = true;
  } catch (error) {
    const { response } = error;
    if (response) {
      dispatch({
        type: SET_UPLOAD_ERROR,
        payload: response.data.errors[0].message,
      });
    } else {
      dispatch({
        type: GET_GENERIC_ERROR,
        payload: i18n.t('common:ServerError'),
      });
    }
  } finally {
    if (getAll) {
      dispatch(getAll({ domain, clearUploadError, filters }));
    }
  }
};
