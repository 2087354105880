import React, { useMemo } from 'react';
import {
  withStyles,
} from '@material-ui/core';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
  createCrusherItem,
  restoreOriginalCrusher,
  updateCrusherList,
  setCrusherValues,
  deleteCrusherItem,
} from '~/store/dailyparts/actions';
import PF2MDataTable from '~/components/PF2MDataTable';
import { useTranslation } from 'react-i18next';
import styles from '../styles';
import { defaultItem } from './utils';

const CrusherList = () => {
  const { t: translate } = useTranslation();

  const formatCrusherList = row => ({
    ...row,
    start_timestamp: row.start_timestamp * 1000,
    end_timestamp: row.end_timestamp * 1000,
  });

  const unFormatCrusherList = row => ({
    ...row,
    start_timestamp: row.start_timestamp / 1000,
    end_timestamp: row.end_timestamp / 1000,
    exception_type: 15,
  });

  const crusherListState = useSelector(state => state.dailyParts.crusherList);

  const crusherList = useMemo(() => crusherListState.map(formatCrusherList), [crusherListState]);

  const filters = useSelector(state => state.dailyParts.filters);
  const turns = useSelector(state => state.manager.turns);
  const teams = useSelector(state => state.manager.teams);
  const crusherGroups = useSelector(state => state.manager.equipmentsGroups
    .filter(eg => eg.id_equipament === filters.equipmentType),
  shallowEqual);
  const crushers = useSelector(state => state.manager.equipments
    .filter(equip => equip.id_equip === filters.equipmentType),
  shallowEqual);
  const elements = useSelector(state => state.manager.elements);
  const subElements = useSelector(state => state.manager.subElements);
  const operatorsGroups = useSelector(state => state.manager.operatorsGroups.filter(o => o.name),
    shallowEqual);
  const operators = useSelector(state => state.manager.operators.filter(o => o.name), shallowEqual);
  const exceptionTypes = useSelector(state => state.manager.exceptionTypes);
  const originTypes = useSelector(state => state.manager.originTypes);

  const dispatch = useDispatch();

  const validateDates = () => (row) => {
    if (row.end_timestamp && row.start_timestamp > row.end_timestamp) {
      return { error: true, helperText: '' };
    }

    return true;
  };

  const validateEmptyRow = (row) => {
    if (row.start_timestamp !== undefined
      && row.end_timestamp !== undefined
      && row.turn_id !== undefined
      && row.team_id !== undefined
      && row.group_equipament_links_id !== undefined) {
      return true;
    }
    return { error: true, helperText: '' };
  };

  const columns = [
    {
      editable: 'always',
      title: translate('common:DatetimeStart'),
      field: 'start_timestamp',
      validate: validateDates(),
      customType: 'dateTimeByTimestamp',
    },
    {
      editable: 'always',
      title: translate('common:DatetimeEnd'),
      field: 'end_timestamp',
      validate: validateDates(),
      customType: 'dateTimeByTimestamp',
    },
    {
      editable: 'always',
      title: translate('common:Shift'),
      field: 'turn_id',
      validate: validateEmptyRow,
      lookup: turns.map(e => ({ id: e.id, name: `${e.turn_group_name} - ${e.name}`, active: e.active })),
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:Team'),
      field: 'team_id',
      validate: validateEmptyRow,
      lookup: teams,
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:EquipmentGroup'),
      field: 'equipment_groups_id',
      lookup: crusherGroups,
      customType: 'filterLookup',
      render: (rowData) => {
        const group = crusherGroups.find(
          e => e.id === crushers.find(c => c.id === rowData.group_equipament_links_id)?.id_group,
        );
        return group ? group.name : '';
      },
    },
    {
      editable: 'always',
      title: translate('common:Equipment'),
      field: 'group_equipament_links_id',
      lookup: crushers,
      customType: 'filterLookup',
      validate: validateEmptyRow,
      lookupFilter: (option, row) => String(option.id_group) === String(row.equipment_groups_id)
        || String(row.equipment_groups_id) === '0',
    },
    {
      editable: 'always',
      title: translate('common:Source'),
      field: 'origin_id',
      lookup: originTypes,
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:MassT'),
      field: 'production',
    },
    {
      editable: 'always',
      title: translate('common:Origin'),
      field: 'element_id',
      lookup: elements,
      customType: 'filterLookup',
    },
    {
      editable: 'always',
      title: translate('common:OriginPoint'),
      field: 'point_id',
      lookup: subElements,
      customType: 'filterLookup',
      lookupFilter: (option, row) => String(option.id_element) === String(row.element_id),
    },
    {
      editable: 'always',
      title: translate('common:CrusherTime'),
      field: 'crushing_time',
    },
    {
      editable: 'always',
      title: translate('common:CrusherStopTime'),
      field: 'stop_time',
    },
    {
      editable: 'always',
      title: translate('common:CodeTime'),
      field: 'code_time',
    },
    {
      editable: 'always',
      title: translate('common:OperatorGroup'),
      field: 'operator_groups_id',
      lookup: operatorsGroups,
      customType: 'filterLookup',
      render: (rowData) => {
        const group = operatorsGroups.find(
          e => e.id === operators.find(c => c.id === String(rowData.operator_id))?.id_group,
        );
        return group ? group.name : '';
      },
    },
    {
      editable: 'always',
      title: translate('common:Operator'),
      field: 'operator_id',
      lookup: operators,
      customType: 'filterLookup',
      lookupFilter: (e, f) => String(e.id_group) === String(f.operator_groups_id)
        || String(f.operator_groups_id) === '0',
      lookupKey: 'id_operator',
    },
    {
      editable: 'never',
      title: translate('common:EntryType'),
      field: 'exception_type',
      lookup: exceptionTypes,
      customType: 'filterLookup',
    },
  ];

  return (
    <PF2MDataTable
      title={translate('common:Cycles')}
      options={{
        showTitle: true,
        search: true,
        selection: true,
      }}
      onAdd={(newData) => {
        dispatch(createCrusherItem(newData));
      }}
      onDiscard={() => {
        dispatch(restoreOriginalCrusher());
      }
      }
      onApply={() => {
        dispatch(updateCrusherList(crusherList.map(unFormatCrusherList), filters));
      }
      }
      onChange={(newData) => {
        newData.forEach(({ ...row }) => {
          dispatch(setCrusherValues(
            unFormatCrusherList(row),
          ));
        });
      }}
      onDelete={(newData) => {
        newData.forEach((e) => {
          dispatch(deleteCrusherItem(e));
        });
      }}
      initialFormData={
        {
          ...columns.reduce((acc, e) => ({ ...acc, [e.field]: '0' }), {}),
          ...defaultItem,
        }
      }
      columns={columns}
      data={crusherList.length ? crusherList : []}
    />
  );
};

export default withStyles(styles)(CrusherList);
